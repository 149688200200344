<template>
  <div class="wrapper">
    <div class="page-header page-header-small">
      <div
        v-show="ktery_parallax === 0"
        class="animate__animated animate__fadeIn"
      >
        <parallax
          class="page-header-image"
          style="background-image: url('img/p_01_e.jpg')"
        >
        </parallax>
        <div>
          <h1 class="text_white_1_b animate__animated animate__pulse">
            Dejte Vašim datům smysl
          </h1>
          <h2 class="text_white_2">prostřednictvím našich</h2>
          <h1 class="text_white_2">APLIKACÍ</h1>
        </div>
      </div>

      <div
        v-show="ktery_parallax === 1"
        class="animate__animated animate__fadeIn"
      >
        <parallax
          class="page-header-image"
          style="background-image: url('img/p_05_e.jpg')"
        >
        </parallax>
        <div>
          <h1 class="text_white_1 animate__animated animate__pulse">
            Genovesa
          </h1>
          <h2 class="text_white_2">Laboratorní NGS informační systém</h2>
        </div>
      </div>
      <div v-show="ktery_parallax === 1" class="">
        <div class="content-center">
          <div class="col-md-12 text-center text_white_3_b">
            <a href="https://www.bioxsys.cz/#/genovesa" target="_blank">
              <div class="btn or">
                <b>Více si přečtěte zde </b
                ><i class="fa fa-angle-double-right"></i>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div
        v-show="ktery_parallax === 2"
        class="animate__animated animate__fadeIn"
      >
        <parallax
          class="page-header-image"
          style="background-image: url('img/IMG_4461a.jpg')"
        >
        </parallax>
        <div>
          <h1 class="text_gray_1 animate__animated animate__pulse">Kurzy</h1>
          <h2 class="text_white_2">
            Specializované bioinformatické kurzy podporované dotací MPSV
          </h2>
        </div>
      </div>
      <div v-show="ktery_parallax === 2" class="">
        <div class="content-center text_white_3_b">
          <div class="col-md-12">
            <a href="https://www.bioxsys.cz/#/kurzy" target="_blank">
              <div class="btn or">
                <b>Více si přečtěte zde </b
                ><i class="fa fa-angle-double-right"></i>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="projects-2 better_bg_color_main">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <div class="section-space"></div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
            <card plain>
              <router-link tag="a" slot="image" to="/genovesa">
                <img
                  class="img-raised rounded imgbg"
                  src="img/main_page_small_genovesa.jpg"
                />
              </router-link>
              <router-link tag="a" slot="image" to="/genovesa">
                <h4 class="card-title">Aplikace</h4>
              </router-link>
              <h6 class="hesla">Bioinformatický software</h6>
              <p class="card-description">
                Software Genovesa pokrývá široké spektrum funkcionalit od
                laboratorního informačního systému až po analýzu sekvenačních
                dat nové generace, včetně klinické interpretace s možností
                sdílení výsledků mezi uživateli.
              </p>
            </card>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
            <card plain>
              <router-link tag="a" slot="image" to="/genovesa">
                <img
                  class="img-raised rounded imgbg"
                  src="img/hp_databaze1.jpg"
                />
              </router-link>
              <router-link tag="a" slot="image" to="/genovesa">
                <h4 class="card-title">Databáze</h4>
              </router-link>
              <h6 class="hesla">Konec Excelovským tabulkám</h6>
              <p class="card-description">
                Programujeme databáze (relační i objektové) speciálně
                optimalizované na velká data v bioinformatice (WES a WGS), které
                umožňují provádět nad daty pokročilé statistické analýzy,
                filtrace a vizualizace.
              </p>
            </card>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
            <card plain>
              <router-link tag="a" slot="image" to="/biosupport">
                <img
                  class="img-raised rounded imgbg"
                  src="img/hp_servery.jpg"
                />
              </router-link>
              <router-link tag="a" slot="image" to="/biosupport">
                <h4 class="card-title">Bioinformatická infrastruktura</h4>
              </router-link>
              <h6 class="hesla">Výpočetní servery a úložiště</h6>
              <p class="card-description">
                Rozumíme Vašim potřebám při budování infrastruktury pro
                bioinformatické aplikace. Poskytujeme kompletní řešení od výběru
                vhodného výpočetního serveru včetně úložiště až po zapojení,
                instalaci a celkové zabezpečení systému.
              </p>
            </card>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
            <card plain>
              <router-link tag="a" slot="image" to="/biosupport">
                <img
                  class="img-raised rounded imgbg"
                  src="img/home_page_services.jpg"
                />
              </router-link>
              <router-link tag="a" slot="image" to="/biosupport">
                <h4 class="card-title">Bioinformatické služby</h4>
              </router-link>
              <h6 class="hesla">Váš partner v bioinformatice</h6>
              <p class="card-description">
                Svěřte nám Váš projekt a my z Vašich sekvenačních dat vytěžíme
                maximum. Od návrhu sekvenačního experimentu až po optimalizaci,
                validaci a sestavení vhodné workflow včetně kompletní
                interpretace výsledků.
              </p>
            </card>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
            <card plain>
              <router-link tag="a" slot="image" to="/kurzy">
                <img
                  class="img-raised rounded imgbg"
                  src="img/main_page_small_training.jpg"
                />
              </router-link>
              <router-link tag="a" slot="image" to="/kurzy">
                <h4 class="card-title">Prezenční pobytové kurzy</h4>
              </router-link>
              <h6 class="hesla">Za týden bioinformatikem</h6>
              <p class="card-description">
                Pořádáme specializované kurzy z Bioinformatiky a
                programování v Pythonu se zaměřením na analýzu sekvenačních dat
                (NGS).
              </p>
            </card>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
            <card plain>
              <router-link tag="a" slot="image" to="/kurzy">
                <img
                  class="img-raised rounded imgbg"
                  src="img/on_line_course.jpg"
                />
              </router-link>
              <router-link tag="a" slot="image" to="/kurzy">
                <h4 class="card-title">On-line kurzy</h4>
              </router-link>
              <h6 class="hesla">Naučte se to efektivně</h6>
              <p class="card-description">
                On-line platforma nám umožňuje realizovat nejen školení, která
                individuálně přizpůsobujeme Vašim potřebám, ale také následnou
                podporu ve formě konzultace, párového řešení úloh a code review.
              </p>
            </card>
          </div>
        </div>
      </div>
    </div>

    <!--         <div class="testimonials-1">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 ml-auto mr-auto text-center">
                        <h2 class="title">Aktuality</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <card type="testimonial kontejner_novinky" no-footer-line>
                            <template slot="header">
                                <h3 class="novinky_nadpis">Přesunutí termínů kurzů</h3>
                                <p class="fas fa-calendar-alt">  20.10.2020</p>
                            </template>
                            <p class="card-description">
                                Vážení zákaznící, z důvodů opatření vlády přesouváme všechny termíny školení na rok 2021</router-link>
                            </p>
                        </card>
                    </div>
                    <div class="col-md-4">
                        <card type="testimonial kontejner_novinky" no-footer-line>
                            <template slot="header">
                                <h3 class="novinky_nadpis">ISO 13485 + ISO 27001</h3>
                                <p class="fas fa-calendar-alt">  15.10.2020</p>
                            </template>
                            <p class="card-description">
                                Zahájili jsme implementaci k certifikaci norem ISO 13485 (mezinárodní norma systémů řízení kvality pro zdravotnické prostředky) a ISO 27001 (mezinárodní norma pro systémy řízení bezpečnosti informací)
                            </p>
                        </card>
                    </div>
                    <div class="col-md-4">
                        <card type="testimonial kontejner_novinky" no-footer-line>
                            <template slot="header">
                                <h3 class="novinky_nadpis">GENOVESA</h3>
                                <p class="fas fa-calendar-alt">  01.10.2020</p>
                            </template>
                            <p class="card-description">
                                Naše společnost uvolnila první verzi GENOVESA - laboratorní informační systém pro NGS data. Genovesa umožňuje editaci pacienta, sekundární analýzu, anotace a interpretace variant, sdílení, reportování... Více v sekci <router-link class="router-link-item nav-link" to="/genovesa"> Aplikace </router-link>
                            </p>
                        </card>                    </div>
                </div>
            </div>
        </div> -->

    <div v-if="false" id="novinky" class="projects-2">
      <div class="container">
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto text-center">
            <h2 class="title">Novinky</h2>
          </div>
        </div>
        <div id="carousel2">
          <el-carousel
            :interval="9000"
            type="card"
            height="600px"
            @change="carouselNewsChange"
          >
            <el-carousel-item
              :class="[carouselNewsIndex === 0 ? 'bg_up' : 'bg_down']"
            >
              <card type="testimonial kontejner_novinky" no-footer-line>
                <h2 class="novinky_nadpis">Nový web Bioinformatik.cz</h2>
                <p class="fas fa-calendar-alt category text-info mar">
                  22.3.2021
                </p>
                <p class="card-description kontejner_novinky_popis">
                  Rádi bychom Vám představili první český komunitní
                  bioinformatický portál. Portál Bioinformatik.cz byl založen
                  jako místo setkávání všech, kteří mají zájem o bioinformatiku
                  a biostatistiku, od začátečníků po profesionály. Více
                  <a class="hesla" href="https://bioinformatik.cz" target="_new"
                    >zde</a
                  >
                </p>
              </card>
            </el-carousel-item>

            <el-carousel-item
              :class="[carouselNewsIndex === 2 ? 'bg_up' : 'bg_down']"
            >
              <card type="testimonial kontejner_novinky" no-footer-line>
                <h2 class="novinky_nadpis">COVID Aplikace</h2>
                <p class="fas fa-calendar-alt category text-info mar">
                  01.02.2021
                </p>
                <p class="card-description kontejner_novinky_popis">
                  Cloudová aplikace pro sekundární a terciání analýzu NGS
                  sekvenačních dat viru SARS-CoV-2. Více v sekci
                  <router-link class="router-link-item hesla" to="/covid">
                    COVID
                  </router-link>
                </p>
              </card>
            </el-carousel-item>

            <el-carousel-item
              :class="[carouselNewsIndex === 0 ? 'bg_up' : 'bg_down']"
            >
              <card type="testimonial kontejner_novinky" no-footer-line>
                <h2 class="novinky_nadpis">
                  České konsorcium pro analýzu SARS-CoV-2
                </h2>
                <p class="fas fa-calendar-alt category text-info mar">
                  20.2.2021
                </p>
                <p class="card-description kontejner_novinky_popis">
                  Naše firma je součástí českého konsorcia, které má za úkol
                  vytvořit robustní a normalizovanou pipeline pro analýzu NGS
                  dat SARS-CoV-2. Více informací
                  <a
                    class="hesla"
                    href="https://virus.img.cas.cz/"
                    target="_new"
                    >zde</a
                  >
                </p>
              </card>
            </el-carousel-item>

            <el-carousel-item
              :class="[carouselNewsIndex === 0 ? 'bg_up' : 'bg_down']"
            >
              <card type="testimonial kontejner_novinky" no-footer-line>
                <h2 class="novinky_nadpis">Nalezení nové fůze ZEB2-PLAG1</h2>
                <p class="fas fa-calendar-alt category text-info mar">
                  01.01.2021
                </p>
                <p class="card-description kontejner_novinky_popis">
                  Podíleli jsme se při vzniku publikace "Novel ZEB2-PLAG1 fusion
                  gene identified by RNA sequencing in a case of lipoblastoma."
                  Pediatr Blood Cancer. 2021 Mar;68(3):e28691. doi:
                  10.1002/pbc.28691. Epub 2020 Sep 12. PMID: 32918527. Kompletní
                  publikaci lze přečíst
                  <a
                    class="hesla"
                    href="https://onlinelibrary.wiley.com/doi/10.1002/pbc.28691"
                    target="_new"
                    >zde</a
                  >
                </p>
              </card>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <div class="projects-2 better_bg_color_main">
      <div class="container">
        <div class="container bor">
          <div class="row">
            <div class="col-md-12 ml-auto mr-auto text-center">
              <h2 class="title">Spolupracujeme</h2>
              <br />
            </div>
          </div>

          <div
            v-waypoint="{ active: true, callback: waypoint_my_flying_image_1 }"
          >
            <transition name="my_flying_image_1" tag="p">
              <div class="row bor" v-if="show_my_flying_image_1">
                <div class="col-md-1"></div>
                <div class="col-md-2 bor">
                  <card type="blog">
                    <img
                      slot="image"
                      class="imgspol"
                      src="img/spoluprace_muni_sci.png"
                    />
                  </card>
                </div>
                <div class="col-md-2 bor">
                  <card type="blog">
                    <img
                      slot="image"
                      class="imgspol"
                      src="img/spoluprace_ghc1.png"
                    />
                  </card>
                </div>
                <div class="col-md-2 bor">
                  <card type="blog">
                    <img
                      slot="image"
                      class="imgspol"
                      src="img/spolupracujeme_motol.svg"
                    />
                  </card>
                </div>
                <div class="col-md-2 bor">
                  <card type="blog">
                    <img
                      slot="image"
                      class="imgspol"
                      src="img/spolupracujeme_FNHK.jpeg"
                    />
                  </card>
                </div>
                <div class="col-md-2 bor">
                  <card type="blog">

                  </card>
                </div>
                <div class="col-md-1"></div>
              </div>
            </transition>
          </div>

          <div
            v-waypoint="{ active: true, callback: waypoint_my_flying_image_2 }"
          >
            <transition name="my_flying_image_2" tag="p">
              <div class="row bor" v-if="show_my_flying_image_2">
                <div class="col-md-1"></div>
                <div class="col-md-2 bor">
                  <card type="blog">
                    <img slot="image" class="imgspol" src="img/BG-VL_rgb.jpg" />
                  </card>
                </div>
                <div class="col-md-2 bor">
                  <card type="blog">
                    <img
                      slot="image"
                      class="imgspol"
                      src="img/spolupracujeme_FNO.jpg"
                    />
                  </card>
                </div>
                <div class="col-md-2 bor">
                  <card type="blog">
                    <img
                      slot="image"
                      class="imgspol"
                      src="img/spoluprace_mbu.png"
                    />
                  </card>
                </div>
                <div class="col-md-2 bor">
                  <card type="blog">
                    <img
                      slot="image"
                      class="imgspol"
                      src="img/spoluprace_hpst.svg"
                    />
                  </card>
                </div>
                <div class="col-md-2 bor">
                  <card type="blog">
                    <img
                      slot="image"
                      class="imgspol"
                      src="img/spoluprace_umg.png"
                    />
                  </card>
                </div>
                <div class="col-md-1"></div>
              </div>
            </transition>
          </div>

          <div
            v-waypoint="{ active: true, callback: waypoint_my_flying_image_1 }"
          >
            <transition name="my_flying_image_1" tag="p">
              <div class="row bor" v-if="show_my_flying_image_1">
                <div class="col-md-1"></div>
                <div class="col-md-2 bor">
                  <card type="blog">
                    <img
                      slot="image"
                      class="imgspol"
                      src="img/spoluprace_liberec.png"
                    />
                  </card>
                </div>
                <div class="col-md-2 bor">
                  <card type="blog">
                    <img
                      slot="image"
                      class="imgspol"
                      src="img/spoluprace_biopticka.png"
                    />
                  </card>
                </div>
                <div class="col-md-2 bor">
                  <card type="blog">
                    <img
                      slot="image"
                      class="imgspol"
                      src="img/spoluprace_fnbrno.jpg"
                    />
                  </card>
                </div>
              </div>
            </transition>
          </div>

          <!-- <div class="row">
                            <div class="col-md-4">
                                <card type="" plain>
                                    <img class="rounded" src="img/test.jpg"/>
                            </card>
                            </div>
                            <div class="col-md-4">
                                <card type="" plain>
                                    <img class="rounded svg-image" src="img/spoluprace_ghc1.png"/>
                                </card>
                            </div>
                            <div class="col-md-4">
                                <card type="" plain>
                                    <img class="rounded svg-image" src="img/spolupracujeme_motol.svg"/>
                                </card>
                            </div>
                            <div class="col-md-4">
                                <card type="" plain>
                                    <img class="rounded svg-image" src="img/spolupracujeme_FNHK.jpeg"/>
                                </card>
                            </div>
                            <div class="col-md-4">
                                <card type="" plain>
                                    <img class="rounded svg-image" src="img/spoluprace_kriminalka.png"/>
                                </card>
                            </div>
                             <div class="col-md-4">
                                <card type="" plain>
                                    <img class="rounded svg-image1" src="img/spoluprace_vienna.png"/>
                                </card>
                            </div>
                             <div class="col-md-4">
                                <card type="" plain>
                                    <img class="rounded svg-image1" src="img/spolupracujeme_FNO.jpg"/>
                                </card>
                            </div>
                             <div class="col-md-4">
                                <card type="" plain>
                                    <img class="rounded svg-image1" src="img/spoluprace_mbu.png"/>
                                </card>
                            </div>
                             <div class="col-md-4">
                                <card type="" plain>
                                    <img class="rounded svg-image1" src="img/spoluprace_hpst.svg"/>
                                </card>
                            </div>
                             <div class="col-md-4">
                                <card type="" plain>
                                    <img class="rounded svg-image1" src="img/spoluprace_umg.png"/>
                                </card>
                            </div>
                        </div> -->
        </div>
      </div>
    </div>

    <div class="projects-2">
      <div class="container">
        <div class="container">
          <div class="row">
            <div class="col-md-12 ml-auto mr-auto text-center">
              <h2 class="title">Partneři</h2>
              <br />
            </div>
          </div>
          <div
            v-waypoint="{ active: true, callback: waypoint_my_flying_div_1 }"
          >
            <transition name="my_flying_div_1">
              <div
                v-if="show_my_flying_div_1"
                class="flying_div_1 col-md-3 bor mr-auto ml-auto"
              >
                <card type="blog">
                  <img
                    slot="image"
                    class="imgspol"
                    src="img/partneri_biovendor.svg"
                  />
                </card>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Vue from "vue"
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

import {
  Card,
  InfoSection,
  Badge,
  Button,
  FormGroupInput,
  Tabs,
  TabPane,
} from "@/components";

import {
  Carousel,
  CarouselItem,
  Image,
  Table,
  TableColumn,
  Select,
  Option,
} from "element-ui";
import axios from "axios";
import VueWaypoint from "vue-waypoint";
Vue.use(VueWaypoint);

export default {
  components: {
    Card,
    Tabs,
    TabPane,
    Badge,
    InfoSection,
    [Button.name]: Button,
    [Image.name]: Image,
    [FormGroupInput.name]: FormGroupInput,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
      carouselNewsIndex: 99,
      ktery_parallax: 0,

      show_my_flying_image_1: false,
      show_my_flying_image_2: false,
      show_my_flying_div_1: false,
    };
  },

    computed: {
      ...mapState({
        token: state => state.token,
        kurzy: state => state.kurzy,

      }),

      ...mapGetters({


      }),
    },



  timers: {
    rotuj: { time: 5000, autostart: true, repeat: true },
  },

  methods: {
    handleScroll() {
      window.scrollTo({ top: 900, left: 0, behavior: "smooth" });
    },

    carouselNewsChange(index) {
      this.carouselNewsIndex = index;
    },

    rotuj() {
      this.ktery_parallax += 1;
      this.ktery_parallax = this.ktery_parallax % 3;
    },

    waypoint_my_flying_image_1({ going, direction }) {
      if (going === this.$waypointMap.GOING_IN) {
        if (this.show_my_flying_image_1) {
        } else {
          this.show_my_flying_image_1 = true;
        }
      }
    },

    waypoint_my_flying_image_2({ going, direction }) {
      if (going === this.$waypointMap.GOING_IN) {
        if (this.show_my_flying_image_2) {
        } else {
          this.show_my_flying_image_2 = true;
        }
      }
    },

    waypoint_my_flying_div_1({ going, direction }) {
      if (going === this.$waypointMap.GOING_IN) {
        if (this.show_my_flying_div_1) {
        } else {
          this.show_my_flying_div_1 = true;
        }
      }
    },
  },
};
</script>

<style>
.card-description {
  color: black !important;
  font-weight: 500 !important;
}

.card-description-white {
  color: white !important;
  font-weight: 600 !important;
}

.card-background:after {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.card-testimonial {
  border-radius: 10% !important;
}

@media only screen and (max-width: 500px) {
  .main_size {
    font-size: 35px !important;
    font-weight: 1000 !important;
  }
}

.main_size {
  font-weight: 600 !important;
  font-size: 45 px !important;
}

.hesla {
  text-align: center !important;
  color: orangered !important;
}

.better_bg_color_main {
  background-color: #e9e3dd !important;
}

.better_bg_color_main_2 {
  background-color: white !important;
}

.novinky_nadpis {
  color: black !important;
  font-weight: 505 !important;
  font-size: 25px !important;
  padding-top: 50px !important;
}

.kontejner_novinky {
  min-height: 400px !important;
  border-radius: 0px !important;
}

.kontejner_novinky_popis {
  padding-top: 30px !important;
}

.svg-image {
  width: 40% !important;
}

.svg-image1 {
  width: 60% !important;
}

.bg_up {
  background-color: #ffffff;
}

.bg_down {
  background-color: #ffffff;
}

@media only screen and (min-device-width: 800px) {
  .text_white_1 {
    font-weight: 530 !important;
    font-size: 78px !important;
    padding-top: 160px !important;
    text-align: center !important;
    color: white !important;
  }
  .text_white_1_b {
    font-weight: 530 !important;
    font-size: 70px !important;
    padding-top: 170px !important;
    text-align: center !important;
    color: white !important;
  }
  .text_white_2 {
    font-weight: 600 !important;
    font-size: 40px !important;
    text-align: center !important;
    color: white !important;
  }
  .text_white_3 {
    font-weight: 600 !important;
    font-size: 20px !important;
    padding: 2px !important;
    text-align: center !important;
    color: white !important;
    padding-top: 180px !important;
  }
  .text_white_3_b {
    font-weight: 600 !important;
    font-size: 20px !important;
    padding: 2px !important;
    text-align: center !important;
    color: white !important;
    padding-top: 260px !important;
  }
  .text_gray_1 {
    font-weight: 530 !important;
    font-size: 78px !important;
    padding-top: 160px !important;
    text-align: center !important;
    color: #222 !important;
  }
}

@media only screen and (max-device-width: 799px) {
  .text_white_1 {
    font-weight: 530 !important;
    font-size: 60px !important;
    padding-top: 100px !important;
    text-align: center !important;
    color: white !important;
  }
  .text_white_1_b {
    font-weight: 530 !important;
    font-size: 44px !important;
    padding-top: 100px !important;
    text-align: center !important;
    color: white !important;
  }
  .text_white_2 {
    font-weight: 600 !important;
    font-size: 25px !important;
    text-align: center !important;
    color: white !important;
  }
  .text_white_3 {
    font-weight: 600 !important;
    font-size: 12px !important;
    padding: 2px !important;
    text-align: center !important;
    color: white !important;
    padding-top: 120px !important;
  }
  .text_white_3_b {
    font-weight: 600 !important;
    font-size: 12px !important;
    padding: 2px !important;
    text-align: center !important;
    color: white !important;
    padding-top: 280px !important;
  }
  .text_gray_1 {
    font-weight: 530 !important;
    font-size: 78px !important;
    padding-top: 160px !important;
    text-align: center !important;
    color: #222 !important;
  }
}

.white {
  color: white !important;
}

.p {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  color: black !important;
  font-weight: 500 !important;
}

.description {
  margin-top: 5px !important;
}

.or {
  background-color: #E08C2D !important;
}

.mode-fade-enter-active,
.mode-fade-leave-active {
  transition: opacity 2.5s ease;
}

.mode-fade-enter-from,
.mode-fade-leave-to {
  opacity: 0;
}

.imgbg {
  filter: grayscale(50%) !important;
}

.imgbg:hover {
  filter: brightness(1.1) !important;
}

.move_up {
  display: inline-block;
}

.bor {
  border: red 0px solid !important;
  padding: 15px !important;
}

.mar {
  margin: 10px !important;
}

.imgspol {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 9px;
}

.flying_image_1 {
  z-index: 20 !important;
  /* left: 400px; */
}
.my_flying_image_1-enter-active {
  transition: all 3.3s ease;
}
.my_flying_image_1-leave-active {
  transition: all 3.8s cubic-bezier(0.1, 0.1, 0.5, 4);
}
.my_flying_image_1-enter {
  transform: translateX(400px);
  opacity: 0;
}

.flying_image_2 {
  z-index: 20 !important;
  right: 250px;
}
.my_flying_image_2-enter-active {
  transition: all 3.3s ease;
}
.my_flying_image_2-leave-active {
  transition: all 3.8s cubic-bezier(3, 0.5, 0.5, 1);
}
.my_flying_image_2-enter {
  transform: translateX(-350px);
  opacity: 0;
}

.flying_div_1 {
  z-index: 20 !important;
}
.my_flying_div_1-enter-active {
  transition: all 3.1s ease;
}
.my_flying_div_1-leave-active {
  transition: all 2.8s cubic-bezier(0.1, 0.1, 0.5, 3);
}
.my_flying_div_1-enter {
  transform: translateY(380px);
  opacity: 0;
}
</style>
