<template>
  <div class="wrapper landing-page">
    <div class="page-header page-header-small mhp">
      <parallax
        class="page-header-image"
        style="background-image: url('img/FotkyFoto_348408708_L.jpg')"
      >
      </parallax>
      <div class="content-center mw">
        <h1 class="title main_size_genovesa">COVID</h1>
        <h2 class="title main_size_covid1">
          Software pro analýzu sekvenačních dat genomu SARS-CoV-2
        </h2>
          <div class="col-md-8 mr-auto ml-auto">
            <h4 class="title bor_1">Pro online přihlášení do naší aplikace klikněte<br />
              <a href="https://covid.bioxsys.cz/" target="_new"
                ><n-button type="default" size="sm"><b>ZDE</b></n-button></a
              >
            </h4>
          </div>
        <div class="text-center"></div>
      </div>
    </div>

    <!--     <div class="bg_color_main">

            <div class="row">
                <div class="col-md-6 ml-auto mr-auto text-center">
                        <h4 class="title_center3">Clouvodá aplikace pro analýzu sekvenačních dat viru SARS-CoV-2 </h4>
                </div>
            </div>
    </div> -->
    <div class="col-md-12 bg_color_main8 pt-2"></div>
    <div class="about-description text-center bg_color_main3">
      <div class="col-md-12">
        <div class="container pt-5 pb-5">
          <div class="row pt-5">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h2 class="title text-white">
                <b>Co vám aplikace COVID nabízí?</b>
              </h2>
            </div>
          </div>

          <div class="row pb-5">
            <div class="col-md-3 bg_color_main4_padding">
              <div
                class="bg_color_main4"
                style="background-image: url('img/bg_cov_02.jpg')"
              >
                <div class="info info-hover">
                  <div class="icon icon-circle">
                    <i class="fas fa-shield-alt" style="color: green"></i>
                  </div>
                  <h4 class="title main_size_covid2">Secure</h4>
                  <p class="description text-2">
                    Splňuje hlavní požadavky na zabezpečení a šifrování
                    sekvenačních dat.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-3 bg_color_main4_padding">
              <div
                class="bg_color_main4"
                style="background-image: url('img/bg_cov_02.jpg')"
              >
                <div class="info info-hover">
                  <div class="icon icon-circle">
                    <i class="fas fa-cloud-upload-alt" style="color: blue"></i>
                  </div>
                  <h4 class="title main_size_covid2">All-in-one analýza</h4>
                  <p class="description text-2">
                    Využívá de-novo aasembly nebo reference based
                    bioinformatickou analýzu sekvenačních dat (amplikon nebo
                    enchriment kity)
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-3 bg_color_main4_padding">
              <div
                class="bg_color_main4"
                style="background-image: url('img/bg_cov_02.jpg')"
              >
                <div class="info info-hover">
                  <div class="icon icon-circle">
                    <i class="fas fa-database" style="color: red"></i>
                  </div>
                  <h4 class="title main_size_covid2">Databáze variant</h4>
                  <p class="description text-2">
                    Jeho součástí je databáze všech variant nalezených v genomu
                    SARS-CoV-2 s přehledem jednotlivých mutací viru.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-3 bg_color_main4_padding">
              <div
                class="bg_color_main4"
                style="background-image: url('img/bg_cov_02.jpg')"
              >
                <div class="info info-hover">
                  <div class="icon icon-circle">
                    <i class="fas fa-viruses" style="color: orange"></i>
                  </div>
                  <h4 class="title main_size_covid2">
                    Epidemiologické informace
                  </h4>
                  <p class="description text-2">
                    Umožňuje vyplnit formulář obsahující informace o vzorku dle
                    standardu databáze GiSAID.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-3 bg_color_main4_padding">
              <div
                class="bg_color_main4"
                style="background-image: url('img/bg_cov_02.jpg')"
              >
                <div class="info info-hover">
                  <div class="icon icon-circle">
                    <i class="fas fa-poll-h" style="color: black"></i>
                  </div>
                  <h4 class="title main_size_covid2">Zařazení do Klád</h4>
                  <p class="description text-2">
                    Zahrnuje možnost srovnání analyzovaných dat s referenční
                    sekvencí a tím možnost zařazení do tzv. klád.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-3 bg_color_main4_padding">
              <div
                class="bg_color_main4"
                style="background-image: url('img/bg_cov_02.jpg')"
              >
                <div class="info info-hover">
                  <div class="icon icon-circle">
                    <i class="fas fa-bezier-curve" style="color: purple"></i>
                  </div>
                  <h4 class="title main_size_covid2">Pango Lineages</h4>
                  <p class="description text-2">
                    Zařazení sekvence do konkrétní linie (britská,
                    jihoafrická...) dle Pango nomenklatury.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-3 bg_color_main4_padding">
              <div
                class="bg_color_main4"
                style="background-image: url('img/bg_cov_02.jpg')"
              >
                <div class="info info-hover">
                  <div class="icon icon-circle">
                    <i class="fas fa-server" style="color: #098DBA"></i>
                  </div>
                  <h4 class="title main_size_covid2">Propojení s NextClade</h4>
                  <p class="description text-2">
                    Díky propojení s nástrojem NextClade lze data přehledně
                    vizualizovat.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-3 bg_color_main4_padding">
              <div
                class="bg_color_main4"
                style="background-image: url('img/bg_cov_02.jpg')"
              >
                <div class="info info-hover">
                  <div class="icon icon-circle">
                    <i class="fas fa-cloud-upload-alt" style="color: pink"></i>
                  </div>
                  <h4 class="title main_size_covid2">Sdílení na GiSAID</h4>
                  <p class="description text-2">
                    Formátuje FASTA data do podoby splňující kritéria sdílení na
                    GiSAID serveru.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 bg_color_main7 pt-2"></div>
    <div class="testimonials-1 bg_color_main2">
      <div class="container">
        <div class="col-md-12">
          <h1 class="main_size_covid3 text-center">Hlavní funkcionality</h1>

          <card type="blog" class="bg_color_main6 mb-5">
            <img
              slot="image"
              class="img rounded img_top"
              src="img/covid1.png"
            />
            <div
              class=""
              v-waypoint="{ active: true, callback: waypoint_my_flying_div_1 }"
            >
              <transition name="my_flying_div_1">
                <div v-if="show_my_flying_div_1" class="flying_div_1">
                  <h6 class="main_size_covid5 text-center">1</h6>
                  <h6 class="main_size_covid4 text-center">
                    EPIDEMIOLOGICKÝ FORMULÁŘ
                  </h6>
                  <p class="text-2 text-white text-center">
                    Epidemiologický formulář dle databáze GiSAID.
                  </p>
                </div>
              </transition>
            </div>
          </card>

          <card type="blog" class="bg_color_main6 mb-5">
            <img
              slot="image"
              class="img rounded img_top"
              src="img/covid2.png"
            />
            <div
              class=""
              v-waypoint="{ active: true, callback: waypoint_my_flying_div_2 }"
            >
              <transition name="my_flying_div_2">
                <div v-if="show_my_flying_div_2" class="flying_div_2">
                  <h6 class="main_size_covid5 text-center">2</h6>
                  <h6 class="main_size_covid4 text-center">KONTROLA KVALITY</h6>
                  <p class="text-2 text-white text-center">
                    Kontrola kvality sekvenačních dat včetně detailní informace
                    o pokrytí jednotlivých genů viru.
                  </p>
                </div>
              </transition>
            </div>
          </card>

          <card type="blog" class="bg_color_main6 mb-5">
            <img
              slot="image"
              class="img rounded img_top"
              src="img/covid3.png"
            />
            <div
              class=""
              v-waypoint="{ active: true, callback: waypoint_my_flying_div_3 }"
            >
              <transition name="my_flying_div_3">
                <div v-if="show_my_flying_div_3" class="flying_div_3">
                  <h6 class="main_size_covid5 text-center">3</h6>
                  <h6 class="main_size_covid4 text-center">
                    STATUS KVALITY VZORKŮ
                  </h6>
                  <p class="text-2 text-white text-center">
                    Status kvality vzorků, celkový přehled o kvalitě sekvenace,
                    zařazení do linií (britská mutace, jihoafrická mutace...),
                    informace o zařazení do klády.
                  </p>
                </div>
              </transition>
            </div>
          </card>

          <card type="blog" class="bg_color_main6 mb-5">
            <img
              slot="image"
              class="img rounded img_top"
              src="img/covid4.png"
            />
            <div
              class=""
              v-waypoint="{ active: true, callback: waypoint_my_flying_div_4 }"
            >
              <transition name="my_flying_div_4">
                <div v-if="show_my_flying_div_4" class="flying_div_4">
                  <h6 class="main_size_covid5 text-center">4</h6>
                  <h6 class="main_size_covid4 text-center">
                    JEDNODUCHÁ FILTRACE
                  </h6>
                  <p class="text-2 text-white text-center">
                    Jednoduchá filtrace a výběr finálních sekvencí ve FASTA
                    formátu pro vizualizaci v nástroji NextClade.
                  </p>
                </div>
              </transition>
            </div>
          </card>

          <card type="blog" class="bg_color_main6 mb-5">
            <img
              slot="image"
              class="img rounded img_top"
              src="img/covid5.png"
            />
            <div
              class=""
              v-waypoint="{ active: true, callback: waypoint_my_flying_div_5 }"
            >
              <transition name="my_flying_div_5">
                <div v-if="show_my_flying_div_5" class="flying_div_5">
                  <h6 class="main_size_covid5 text-center">5</h6>
                  <h6 class="main_size_covid4 text-center">VIZUALIZACE</h6>
                  <p class="text-2 text-center">
                    Vývoj mutací viru přehledně vizualizován ve fylogenetickém
                    stromu.
                  </p>
                </div>
              </transition>
            </div>
          </card>
        </div>
      </div>

      <h4 class="title text-center">
        Pro více informací nás kontaktuje
        <router-link class="router-link-item" to="/kontaktujte">
          zde<br /><br />
        </router-link>
      </h4>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Card, Button, FormGroupInput, Tabs, TabPane } from "@/components";
import { Carousel, CarouselItem } from "element-ui";
import VueWaypoint from "vue-waypoint";
Vue.use(VueWaypoint);

export default {
  name: "landing-page",
  bodyClass: "landing-page",
  components: {
    Card,
    Tabs,
    TabPane,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },

      show_my_flying_div_1: false,
      show_my_flying_div_2: false,
      show_my_flying_div_3: false,
      show_my_flying_div_4: false,
      show_my_flying_div_5: false,
    };
  },

  methods: {
    handleScroll() {
      window.scrollTo({ top: 1400, left: 0, behavior: "smooth" });
    },

    waypoint_my_flying_div_1({ going, direction }) {
      if (going === this.$waypointMap.GOING_IN) {
        if (this.show_my_flying_div_1) {
        } else {
          this.show_my_flying_div_1 = true;
        }
      }
    },

    waypoint_my_flying_div_2({ going, direction }) {
      if (going === this.$waypointMap.GOING_IN) {
        if (this.show_my_flying_div_2) {
        } else {
          this.show_my_flying_div_2 = true;
        }
      }
    },

    waypoint_my_flying_div_3({ going, direction }) {
      if (going === this.$waypointMap.GOING_IN) {
        if (this.show_my_flying_div_3) {
        } else {
          this.show_my_flying_div_3 = true;
        }
      }
    },

    waypoint_my_flying_div_4({ going, direction }) {
      if (going === this.$waypointMap.GOING_IN) {
        if (this.show_my_flying_div_4) {
        } else {
          this.show_my_flying_div_4 = true;
        }
      }
    },

    waypoint_my_flying_div_5({ going, direction }) {
      if (going === this.$waypointMap.GOING_IN) {
        if (this.show_my_flying_div_5) {
        } else {
          this.show_my_flying_div_5 = true;
        }
      }
    },
  },
};
</script>
<style scoped>
.card-description {
  color: black !important;
  font-weight: 500 !important;
}

.description {
  margin-top: 30px !important;
}

.text-white {
  color: white !important;
}

.text-2 {
  color: #B5D3E6 !important;
}

.main_size_genovesa {
  font-weight: 900 !important;
  font-size: 80px !important;
  color: whitesmoke !important;
}

.main_size_covid1 {
  font-weight: 600 !important;
  font-size: 35px !important;
  color: #E2EAF0 !important;
}

.main_size_covid2 {
  font-weight: 700 !important;
  font-size: 22px !important;
  color: #955259 !important;
  padding-top: 30px !important;
}

.main_size_covid3 {
  font-weight: 700 !important;
  font-size: 55px !important;
  color: white !important;
  padding-top: 80px !important;
}

.main_size_covid4 {
  font-weight: 900 !important;
  font-size: 19px !important;
  color: #E2EAF0 !important;
}

.main_size_covid5 {
  font-weight: 900 !important;
  font-size: 70px !important;
  color: whitesmoke !important;
}

h3 {
  margin-bottom: 10px !important ;
}

.title {
  padding-top: 1px !important ;
}

.title_center3 {
  text-align: center !important;
  margin-top: 100px !important;
  padding: 20 px !important;
  font-weight: 600 !important;
  color: #5A6F7D !important;
}

.bg_color_main1 {
  background-color: #2b337a !important;
  padding-top: 5px !important;
  padding-bottom: 100px !important;
}

.bg_color_main2 {
  background-color: #C2B7B8 !important;
  padding: 5px !important;
  font-weight: 800 !important;
  font-size: 15px !important;
  color: black !important;
}

.bg_color_main3 {
  background-color: #788F9E !important;
}

.bg_color_main4 {
  background-color: #193547 !important;
  padding: 5px !important;
  height: 450px !important;
  border: #5A6F7D 10px solid !important;
  border-radius: 2vw 2vw 2vw 2vw !important;
}

.bg_color_main4_padding {
  padding: 10px !important;
}

.bg_color_main6 {
  background-color: #5A6F7D !important;
  font-weight: 900 !important;
  font-size: 16px !important;
  color: white !important;
  margin-bottom: 100px !important;
  border-radius: 1vw 1vw 1vw 1vw !important;
  border: #AB9597 10px solid !important;
}

.bg_color_main7 {
  background-color: #AB9597 !important;
}

.bg_color_main8 {
  background-color: #5a6f7d !important;
}

.bor_1 {
  border-radius: 2rem 2rem 2rem 2rem !important;
  background: white !important;
  padding: 10px 20px 10px 20px !important;
  font-weight: 600 !important;
  font-size: 25px !important;
  color: #788F9E !important;
  height: auto !important;
  opacity: 0.7 !important;
}

.img_top {
  margin-top: 20px !important;
}

.mw {
  max-width: 1200px !important;
}

@media only screen and (min-device-width: 800px) {
  h1 {
    margin-top: 10px !important;
  }

  .mhp {
    max-height: 10px !important;
  }
}

@media only screen and (max-device-width: 799px) {
  h1 {
    margin-top: 90px !important;
  }

  .mhp {
    max-height: 1100px !important;
  }
}

.flying_div_1 {
  z-index: 20 !important;
  top: 400px;
}
.my_flying_div_1-enter-active {
  transition: all 2.1s ease;
}
.my_flying_div_1-leave-active {
  transition: all 3.8s cubic-bezier(0.1, 0.1, 0.5, 4);
}
.my_flying_div_1-enter {
  transform: translateY(400px);
  opacity: 0;
}

.flying_div_2 {
  z-index: 20 !important;
  top: 400px;
}
.my_flying_div_2-enter-active {
  transition: all 2.1s ease;
}
.my_flying_div_2-leave-active {
  transition: all 3.8s cubic-bezier(0.1, 0.1, 0.5, 4);
}
.my_flying_div_2-enter {
  transform: translateY(400px);
  opacity: 0;
}

.flying_div_3 {
  z-index: 20 !important;
  top: 400px;
}
.my_flying_div_3-enter-active {
  transition: all 2.1s ease;
}
.my_flying_div_3-leave-active {
  transition: all 3.8s cubic-bezier(0.1, 0.1, 0.5, 4);
}
.my_flying_div_3-enter {
  transform: translateY(400px);
  opacity: 0;
}

.flying_div_4 {
  z-index: 20 !important;
  top: 400px;
}
.my_flying_div_4-enter-active {
  transition: all 2.1s ease;
}
.my_flying_div_4-leave-active {
  transition: all 3.8s cubic-bezier(0.1, 0.1, 0.5, 4);
}
.my_flying_div_4-enter {
  transform: translateY(400px);
  opacity: 0;
}

.flying_div_5 {
  z-index: 20 !important;
  top: 400px;
}
.my_flying_div_5-enter-active {
  transition: all 2.1s ease;
}
.my_flying_div_5-leave-active {
  transition: all 3.8s cubic-bezier(0.1, 0.1, 0.5, 4);
}
.my_flying_div_5-enter {
  transform: translateY(400px);
  opacity: 0;
}
</style>

