<template>
    <div class="wrapper landing-page">
        <div class="page-header ">
            <parallax class="page-header-image"
                 style="background-image: url('img/1090_M.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title title_text">Bioinformatické zpracování NGS dat v prostředí</h1>
                <h1 class="title"></h1>
                <img src="img/python-logo-generic_1.svg" height="150px" alt="" class="" />

            </div>
        </div>

        <div class="features-1">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto">
                        <h2 class="title">Intenzivní týdenní pobytový kurz Python & Bioinformatika</h2>
                        <h4 class="description text-black">Kurz vás naučí soubor praktických dovedností se zaměřením na analýzu NGS dat.</h4>
                        <h4 class="description text-black">Je určen biologům, vědeckým pracovníkům, studentům, bioinformatikům i statistikům, kteří si chtějí analyzovat sekvenační data bez závislosti na jakémkoliv komerčním software. Celý kurz je v českém jazyce a je vhodný pro začátečníky.</h4>
                        <h4 class="description text-black">Jsme tým profesionálních bioinformatiků se zaměřením na klinickou praxi, vědeckou činnost, vývoj komerčního software a programátorů s dlouhodobými lektorskými zkušenostmi.</h4>
                        <h4 class="description text-black">Kurz se skládá se ze tří částí:</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="info info-hover">
                            <div class="icon icon-dark">
                                <i class="fas fa-dna"></i>
                            </div>
                            <h4 class="info-titlex main_kurz_bold">Bioinformatika</h4>
                            <p class="description text-black">Kompletní analýza DNA-seq sekvenačních dat od demultiplexingu BCL souborů po kontrolu kvality FASTQ, filtraci nekvalitních sekvencí a adapterů, zarovnání k referenční sekvenci, kontrola pokrytí sekvenovaných úseků, vizualizaci, hledání variant a jejich následnou anotaci s klinickou interpretací.</p>
                            <p class="description text-black">Důraz je kladen na praktické demonstrace v prostředí linux. Nejsou potřeba žádné předchozí zkušenosti s analýzou dat či programovacím jazykem. Po školení Vám zůstane SD karta se všemi programy a kódem, který jste v průběhu školení vytvořili.</p>
                    </div>
                    </div>
                    <div class="col-md-4">
                        <div class="info info-hover">
                            <div class="icon icon-dark">
                                <i class="fab fa-python"></i>
                            </div>
                            <h4 class="info-titlex main_kurz_bold">Python</h4>
                            <p class="description text-black">Python je jeden ze základních programovacích jazyků v bioinformatice. Naučíte se základní syntaxy a pochopíte význam objektového programování. Vyzkoušíte jak lze "těžit" velká data z databázích, vizualizovat anotovaný VCF soubor a následně ho uložit do databázové struktury.</p>
                            <p class="description text-black">Výpočet základních statistických informací a vykreslení grafů. Práce s nejrozšířenějším nástrojem pro výpočetí biologii a bioinformatiku => Biopython. </p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="info info-hover">
                            <div class="icon icon-dark">
                                <i class="fas fa-comments"></i>
                            </div>
                            <h4 class="info-titlex main_kurz_bold">Výměna zkušeností</h4>
                            <p class="description text-black">Díky pobytovému pětidennímu kurzu máte příležitost využít volný čas na konzultace se zkušenými bioinformatiky, kteří budou stále k dispozici a lze tak řešit konkrétní Vaše případy či požadavky. Pokud budete chtít relaxovat sami a v klidu si opakovat nabité znalosti, tak je možné zvolit relaxaci v bazénu, sauně nebo solné jeskyni.</p>
                            <p class="description text-black">K dispozici bude plná penze pro účastníky a večery si lze také zpřijemnit v hotelové Py-vnici. Věříme, že vzájemná komunikace a sdílení informací dokáže každého účastníka posunout o velký krok kupředu.</p>
                        </div>
                    </div>
                    <n-button type="primary" block class="btn-primary" @click.native="detail_kurzu = !(detail_kurzu)">{{ detail_kurzu ? 'Skrýt detailní popis kurzu' : 'Zobrazit detailní popis kurzu' }}</n-button>
                </div>


                <div class="row" v-if="detail_kurzu">
                    <h2 class="title title_center ml-auto mr-auto">Popis bloku Bioinformatika</h2>
                    <div class="col-md-12 justify-content-center odsazeni_vice_o_kurzech">
                        <h5 class="nadpisy_detail">Charakteristika:</h5>
                            <p>Vytvořit si vlastní bootovací médium a instalovat libovolný OS systém </p>
                            <p>Pokročilejší práci v příkazové řádce </p>
                            <p>Demultiplexování BCL dat dle SampleSheet</p>
                            <p>Zhodnocení NGS dat od sekvenace po pokrytí jednotlivých genů</p>
                            <p>Instalaci bioinformatických balíčků v prostředí anacodna</p>
                            <p>Filtrace sekvenačních dat dle kvality či kontamiance</p>
                            <p>Evaulace přípravy knihovny - PCR duplikáty, on/off target reads</p>
                            <p>Anotace VCF souborů</p>
                            <p>Filtrace dle klinické významnosti</p>
                            <p>Základy skriptování a automatizace práce </p>
                            <p>Práce s databázemi UCSC a BioMart</p>
                            <p>Základní práce v programovacím jazyku Python</p>
                    </div>

                    <div class="col-md-12 justify-content-center odsazeni_vice_o_kurzech">
                        <h5 class="nadpisy_detail">Naše filozofie:</h5>
                            <p>Práce s open source balíčky</p>
                            <p>Každý příklad zkoušet prakticky - školení probíha na reálných datech</p>
                            <p>Nenásilně, ale přesto intenzivně, pochopit dané téma</p>
                            <p>Odnést si celou svoji vytvořenou práci domů</p>
                            <p>Předat prostřednitvím lektorů jejich mnohaletou zkušenost v oboru</p>
                            <p>Spojit lidi z různých oborů a sdílet vzájemné zkušenosti</p>

                    </div>

                    <div class="col-md-12 justify-content-center odsazeni_vice_o_kurzech">
                        <h5 class="nadpisy_detail">Co se na kurzu naučíte:</h5>
                            <p>Vysvětlení terminologie NGS dat v bioinformatice</p>
                            <p>Vytvoření vlastního bootovací USB s Linux systémem</p>
                            <p>Instalace OS Linux ve virtuálním prostředí Virtual Box</p>
                            <p>Pochopení struktury adresářů v OS Linux</p>
                            <p>Základní příkazy a orientace v příkazové řádce</p>

                            <p>Instalace prostředí Ananconda a nezbytných bioinformatických balíčků</p>
                            <p>Stažení sekvenačního experimentu z BaseSpace skrz Basemount v příkazové řádce</p>
                            <p>Demultiplexing BCL souborů a tvoření generování FASTQ souborů</p>
                            <p>Kontrola kvality FASTQ souborů a různé filtrační přístupy</p>
                            <p>Stažení referenční sekvence a základní rozdíl mezi jednotlivými "patches"</p>

                            <p>Výběr vhodného zarovnávacího algoritmu dle typu sekvenace</p>
                            <p>Zarovnání k referenční sevenci a tvorba BAM souboru</p>
                            <p>Pokročilá kvality kontrola BAM souboru</p>
                            <p>Filtrace BAM souboru dle klinických kriteriích</p>
                            <p>Přehled algoritmů pro hledání krátkých variant (SNP a INDELs)</p>
                            <p>Generování VCF souborů</p>

                            <p>Možnosti vizualizace BAM a VCF soubrů</p>
                            <p>Stažení anotačních databází a anotace VCF</p>
                            <p>Filtrace A-VCF souborů dle klinické revelevance</p>

                            <p>Praktická ukázka s jednotlivými databázemi BioMart a UCSC</p>
                            <p>Základní tvoření shell skriptů v linuxu</p>
                            <p>Automatizace celé workflow z předešlých dní</p>            

                    </div>
                        <!-- <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                            <h5 class="nadpisy_detail">Připravujeme...</h5>
                            <ul>
                                <li>Školení pro pokročilejší bioinformatiky - datové analýzy v Pythonu, CNV calling, TRIO analýzy, práce s unikátními molekulovými barkódy (UMI), analýza celoexomových dat (WES) a spousta dalších...</li>
                            </ul>
                        </div> -->
                </div>



                <div class="row" v-if="detail_kurzu">
                    <h2 class="title title_center ml-auto mr-auto">Popis bloku Python</h2>
                    <div class="col-md-12 justify-content-center odsazeni_vice_o_kurzech">
                        <h5 class="nadpisy_detail">Charakteristika:</h5>
                            <p>Naučíme vás řešit praktické úlohy v Bioinformatice pomocí Pythonu</p>
                            <p>Objektovým přístupem k problému dokážete zvládnout i rozsáhlé úlohy snadno</p>

                </div>

                    <div class="col-md-12 justify-content-center odsazeni_vice_o_kurzech">
                        <h5 class="nadpisy_detail">Co se na kurzu naučíte:</h5>
                            <p>Používat Python pro zpracování a ukládání dat do databázových systémů</p>
                            <p>Psát a spouštět scripty na Windows i Linuxu</p>
                            <p>Porozumět chybovým hláškám překladače a umět si analyzovat a opravovat chyby</p>
                            <p>Pochopit objektový přístup k řešení problému a zvládnout tak řešit i rozsáhlé úlohy</p>
                            <p>Pracovat s repozitářem modulů a využít naplno jeho bohatých možností pro vlastní development</p>
                            <p>Instalovat si Python a knihovny třetích stran na všech používaných platformách</p>
                            <p>Vyladit si vlastní prostředí pro vývoj vč. editoru, IDE a souvisejících nástrojů</p>
                            <p>Pohybovat se v ekosystému Pythonu a řešit tak komplexní problémy ve srozumitelném prostředí</p>

                    </div>
                        <!-- <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                            <h5 class="nadpisy_detail">Připravujeme...</h5>
                            <ul>
                                <li>Školení pro pokročilejší bioinformatiky - datové analýzy v Pythonu, CNV calling, TRIO analýzy, práce s unikátními molekulovými barkódy (UMI), analýza celoexomových dat (WES) a spousta dalších...</li>
                            </ul>
                        </div> -->
                </div>


                <div class="row" v-if="detail_kurzu">
                    <h2 class="title title_center ml-auto mr-auto">Časový plán kurzu</h2>
                    <div class="col-md-12  mr-auto ml-auto">
                        <el-table :data="tableData" class="">
                        <!-- <el-table-column type="index"></el-table-column> -->
                        <el-table-column min-width="50" prop="ikona" label="">
                            <div slot-scope="{row}" class="text-right table-actions">
                                <div class="icon icon-primary icon-circle">
                                    <i class="[iktyp, ikona]" v-bind:class="ikona"></i>
                                </div>
                            </div>                            
                        </el-table-column>
                        <el-table-column min-width="50" prop="cas1" label="Od"></el-table-column>
                        <el-table-column min-width="50" prop="cas2" label="Do"></el-table-column>
                        <el-table-column min-width="250" prop="udalost" label="Událost"></el-table-column>

                        </el-table>
                    </div>





                    <n-button type="primary" block class="btn-primary" @click.native="detail_amalka = !(detail_amalka)">{{ detail_amalka ? 'Skrýt popis hotelu a akcí' : 'Zobrazit popis hotelu a akcí' }}</n-button>
                    <div class="row" v-if="detail_amalka">
                        <h2 class="title title_center ml-auto mr-auto nadpisy_detail">Popis hotelu a možností relaxace</h2>
                        <div class="row">
                            <div class="col-md-5 ml-auto mt-5">
                                <card type="blog" raised>
                                    <img slot="image" class="img rounded" src="img/amalka_vchod.jpg">
                                    <img slot="image" class="img rounded" src="img/amalka_restaurace.jpg">
                                    <img slot="image" class="img rounded" src="img/amalka_pivnice.jpg">
                                </card>
                            </div>
                            <div class="col-md-5 mr-auto doprovodne_akce">
                                <info-section type="warning"
                                            icon="fas fa-hotel"
                                            title="Hotel Amálka"
                                            description="Kurz pořádáme v příjemném hotelu rodinného typu Amálka ve Straškově, 20 minut severně od Prahy po D8."
                                />
                                <info-section type="warning"
                                            icon="fas fa-train"
                                            title="Dobrá dostupnost autem i vlakem"
                                            description="Možnost parkování u hotelu. Pojedete-li vlakem, vyzvedneme vás v Roudnici nad Labem."
                                />
                                <info-section type="warning"
                                            icon="fas fa-bed"
                                            title="Pohodlné ubytování"
                                            description="2, 3 a 4 lůžkové pokoje s vlastní koupelnou."
                                />                        
                                <info-section type="warning"
                                            icon="fas fa-utensils"
                                            title="Plná penze, občerstvení"
                                            description="K dispozici bude plná penze a občerstvení (včetně kávy) po celou dobu školení."
                                />
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-md-5 ml-auto mt-5 doprovodne_akce">
                                <info-section type="warning"
                                            icon="fas fa-glass-cheers"
                                            title="Pořádáme společné večerní posezení"
                                            description="Zajímá vás detailnější debata o probrané kapitole ze školení nebo jaké jsou mederní trendy v bioinformatice, tak večer můžete strávit v hotelové Py-vnici. "
                                />
                                <info-section type="warning"
                                            icon="fas fa-beer"
                                            title="Sauna"
                                            description=" Pokud zvolíte relaxaci pro načerpání síly na další den je k dispozici finská, tropická nebo infra sauna."
                                />
                                <info-section type="warning"
                                            icon="fas fa-swimming-pool"
                                            title="Bazén"
                                            description="Nebo můžete relaxovat v bazénu s whirlpoolem."
                                />
                            </div>
                            <div class="col-md-5 mr-auto">
                                <card type="blog" raised>
                                    <img slot="image" class="img rounded" src="img/amalka_sauna.jpg">
                                    <img slot="image" class="img rounded" src="img/amalka_bazen.jpg">
                                    <img slot="image" class="img rounded" src="img/amalka_infrasauna.jpg">
                                </card>
                            </div>
                        </div>
                        <hr/>
                    </div>
                    <template slot="footer">
                    <n-button type="danger" @click.native="modals.akce = false">Back</n-button>
                    </template>

                </div>


            </div>
        </div>


        <div class="">
            <div class="container">
                <div class="row">
                    <div class="col-md-10 ml-auto mr-auto text-center">
                        <h2 class="title ">Certifikace</h2>
                        <br />
                        <card type="blog" plain>
                            <div class="card-image">
                                <img class="img img-raised rounded" src="img/FotkyFoto_74819005.jpg" />
                            </div>
                            <h6 class="category text-info">Certifikát</h6>
                            <h3 class="card-title">
                                Certifikát o absolvování kurzu Python & Bioinformatika
                            </h3>
                        </card>

                    </div>
                </div>
            </div>
        </div>



        <div class="">
            <div class="">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 ml-auto mr-auto text-center">
                            <h2 class="title">Vyberte si termín kurzu</h2>
                            <div class="title termin_bold">Objednávka do 28. 2. - 5 % sleva.</div>
                            <div class="termin_bold">Sleva pro studenty 5 % sleva.</div>
                            <div class="termin_bold">Společná objednávka více účastníku na stejný pokoj - 5 % sleva pro všechny.</div>
                            <div class="description text-black">Slevy se sčítají. Konec objednávek a potvrzení termínu 31. 3. 2020</div>
                            <div class="description text-black">Vzhledem k omezené kapacitě ověřte dostupnost termínu.</div>
                            <p class="title"> </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <card type="pricing" raised color="orange">
                                <h6 class="category">Termín A</h6>
                                <h1 class="card-title">
                                    <small>CZK</small>
                                    29 500
                                </h1>
                                <ul>
                                    <li>
                                        <b>8. - 12. 6. 2020</b> 
                                    </li>
                                    <li>
                                        ubytování od 7. 6. 2020 po 16 hod.
                                    </li>
                                </ul>

                                <!-- <fg-input placeholder="uveďte den a hodinu, kdy Vám máme zavolat" addon-left-icon="fas fa-clock" v-model="kdy"> </fg-input> -->
                                <fg-input placeholder="jméno a příjmení" addon-left-icon="fas fa-user-circle" v-model="jmeno"> </fg-input>
                                <fg-input placeholder="telefon" addon-left-icon="fas fa-bell" v-model="telefon"> </fg-input>
                                <fg-input placeholder="email ..." addon-left-icon="fas fa-envelope" v-model="email"> </fg-input>
                                <fg-input placeholder="společnost ..." addon-left-icon="fas fa-envelope" v-model="subjekt"> </fg-input>
                                <fg-input placeholder="počet osob ..." addon-left-icon="fas fa-envelope" v-model="osob"> </fg-input>
                                <textarea name="message" class="form-control" rows="6" placeholder="Volitelný text zprávy:" v-model="zprava"> </textarea>

                                <n-button type="light" round>
                                    Objednat A
                                </n-button>
                            </card>
                        </div>

                        <div class="col-md-6">
                            <card type="pricing" raised color="orange">
                                <h6 class="category">Termín B</h6>
                                <h1 class="card-title">
                                    <small>CZK</small>
                                    29 500
                                </h1>
                                <ul>
                                    <li>
                                        <b>15. - 19. 6. 2020</b> 
                                    </li>
                                    <li>
                                        ubytování od 14. 6. 2020 po 16 hod.
                                    </li>
                                </ul>

                                <!-- <fg-input placeholder="uveďte den a hodinu, kdy Vám máme zavolat" addon-left-icon="fas fa-clock" v-model="kdy"> </fg-input> -->
                                <fg-input placeholder="jméno a příjmení" addon-left-icon="fas fa-user-circle" v-model="jmeno"> </fg-input>
                                <fg-input placeholder="telefon" addon-left-icon="fas fa-bell" v-model="telefon"> </fg-input>
                                <fg-input placeholder="email ..." addon-left-icon="fas fa-envelope" v-model="email"> </fg-input>
                                <fg-input placeholder="společnost ..." addon-left-icon="fas fa-envelope" v-model="subjekt"> </fg-input>
                                <fg-input placeholder="počet osob ..." addon-left-icon="fas fa-envelope" v-model="osob"> </fg-input>
                                <textarea name="message" class="form-control" rows="6" placeholder="Volitelný text zprávy:" v-model="zprava"> </textarea>

                                <n-button type="light" round>
                                    Objednat B
                                </n-button>

                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </div>


<!--         <div class="row">
            <div class="col-1"></div>
            <div class="col-10 ml-auto mr-auto">
                <div class="card text-center pruhledna">
                    <div class="card-header mt-2">
                    </div>
                    <div class="card-body">
                    
                        <div class="about-contact">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-10 mr-auto ml-auto">
                                        <h2 class="text-center title">Poptáváme </h2>
                                        <h4 class="text-center description">Vyplňte formulář, budeme Vás kontaktovat ...</h4>
                                        <form class="contact-form">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label>Jméno a příjmení</label>
                                                    <fg-input addon-left-icon="now-ui-icons users_circle-08" v-model="form.name" placeholder="zadejte jméno ..."> </fg-input>
                                                </div>
                                                <div class="col-md-4">
                                                    <label>Email</label>
                                                    <fg-input addon-left-icon="now-ui-icons ui-1_email-85" v-model="form.email" placeholder="zadejte email ..."> </fg-input>
                                                </div>
                                                <div class="col-md-4">
                                                    <label>Telefon</label>
                                                    <fg-input addon-left-icon="now-ui-icons ui-1_bell-53" v-model="form.phone" placeholder="zadejte telefon ..."> </fg-input>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <label>Typy </label>
                                                <fg-input placeholder="Typy odpadů" v-model="form.longtext"></fg-input>

                                            </div>

                                            <div class="row">
                                                <div class="col-md-4 ml-auto mr-auto text-center">
                                                    <button v-on:click="send_mail()" class="btn btn-primary btn-round mt-4 btn-lg">
                                                        Odeslat
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="card-footer text-muted mb-2">
                        
                    </div>
                </div>
            </div>
            <div class="col-1"></div>
        </div> -->

        


<!--         <div class="testimonials-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 justify-content-center">
                        <el-carousel height="500px" class="carousel-centered">
                            <el-carousel-item>
                                <card type="testimonial" plain>
                                    <img slot="avatar" class="img img-raised rounded" src="img/james.jpg"/>
                                    <h5 class="card-description">"Take up one idea. Make that one idea your life
                                        - think of it, dream of it, live on that idea. Let the brain, muscles,
                                        nerves, every part of your body, be full of that idea, and just leave
                                        every other idea alone. This is the way to success. A single rose can be
                                        my garden... a single friend, my world."
                                    </h5>
                                    <h3 class="card-title">Isaac Hunter</h3>
                                    <div class="card-footer">
                                        <h6 class="category text-primary">Human Resource Director</h6>
                                    </div>
                                </card>
                            </el-carousel-item>
                            <el-carousel-item>
                                <card type="testimonial" plain>
                                    <img slot="avatar" class="img img-raised rounded" src="img/olivia.jpg"/>
                                    <h5 class="card-description">"When we are no longer able to change a
                                        situation - we are challenged to change ourselves. Spread love
                                        everywhere you go. Let no one ever come to you without leaving happier.
                                        Problems are not stop signs, they are guidelines."
                                    </h5>
                                    <h3 class="card-title">Alexa Hailey</h3>
                                    <div class="card-footer">
                                        <h6 class="category text-primary">Human Resource Director</h6>
                                    </div>
                                </card>
                            </el-carousel-item>
                            <el-carousel-item>
                                <card type="testimonial" plain>
                                    <img slot="avatar" class="img img-raised rounded" src="img/emily.jpg"/>
                                    <h5 class="card-description">"When we are no longer able to change a
                                        situation - we are challenged to change ourselves. Spread love
                                        everywhere you go. Let no one ever come to you without leaving happier.
                                        Problems are not stop signs, they are guidelines."
                                    </h5>
                                    <h3 class="card-title">Alexa Hailey</h3>
                                    <div class="card-footer">
                                        <h6 class="category text-primary">Human Resource Director</h6>
                                    </div>
                                </card>
                            </el-carousel-item>
                            <el-carousel-item>
                                <card type="testimonial" plain>
                                    <img slot="avatar" class="img img-raised rounded" src="img/julie.jpg"/>
                                    <h5 class="card-description">"When we are no longer able to change a
                                        situation - we are challenged to change ourselves. Spread love
                                        everywhere you go. Let no one ever come to you without leaving happier.
                                        Problems are not stop signs, they are guidelines."
                                    </h5>
                                    <h3 class="card-title">Alexa Hailey</h3>
                                    <div class="card-footer">
                                        <h6 class="category text-primary">Human Resource Director</h6>
                                    </div>
                                </card>
                            </el-carousel-item>
                            <el-carousel-item>
                                <card type="testimonial" plain>
                                    <img slot="avatar" class="img img-raised rounded" src="img/marie.jpg"/>
                                    <h5 class="card-description">"When we are no longer able to change a
                                        situation - we are challenged to change ourselves. Spread love
                                        everywhere you go. Let no one ever come to you without leaving happier.
                                        Problems are not stop signs, they are guidelines."
                                    </h5>
                                    <h3 class="card-title">Alexa Hailey</h3>
                                    <div class="card-footer">
                                        <h6 class="category text-primary">Human Resource Director</h6>
                                    </div>
                                </card>
                            </el-carousel-item>
                            <el-carousel-item>
                                <card type="testimonial" plain>
                                    <img slot="avatar" class="img img-raised rounded" src="img/mike.jpg"/>
                                    <h5 class="card-description">"When we are no longer able to change a
                                        situation - we are challenged to change ourselves. Spread love
                                        everywhere you go. Let no one ever come to you without leaving happier.
                                        Problems are not stop signs, they are guidelines."
                                    </h5>
                                    <h3 class="card-title">Alexa Hailey</h3>
                                    <div class="card-footer">
                                        <h6 class="category text-primary">Human Resource Director</h6>
                                    </div>
                                </card>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
        </div> -->


      <modal :show.sync="modals.rozvrh" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">Bioinformatika</h4>
        <div class="row">
            <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <h5 class="nadpisy_detail">Co se na kurzu naučíte:</h5>
                <ul>
                    <li>Vytvořit si vlastní bootovací médium a instalovat libovolný OS systém </li>
                    <li>Pokročilejší práci v příkazové řádce </li>
                    <li>Demultiplexování BCL dat dle SampleSheet</li>
                    <li>Zhodnocení NGS dat od sekvenace po pokrytí jednotlivých genů</li>
                    <li>Instalaci bioinformatických balíčků v prostředí anacodna</li>
                    <li>Filtrace sekvenačních dat dle kvality či kontamiance</li>
                    <li>Evaulace přípravy knihovny - PCR duplikáty, on/off target reads</li>
                    <li>Anotace VCF souborů</li>
                    <li>Filtrace dle klinické významnosti</li>
                    <li>Základy skriptování a automatizace práce </li>
                    <li>Práce s databázemi UCSC a BioMart</li>
                    <li>Základní práce v programovacím jazyku Python</li>

                </ul>
            </div>

            <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <h5 class="nadpisy_detail">Naše filozofie:</h5>
                <ul>
                    <li>Práce s open source balíčky</li>
                    <li>Každý příklad zkoušet prakticky</li>
                    <li>Školení probíha na reálných datech</li>
                    <li>Nenásilně ale přesto intenzivně pochopit dané téma</li>
                    <li>Odnést si celou svoji vytvořenou práci domů</li>
                    <li>Předat prostřednitvím lektorů jejich mnohaletou zkušenost v oboru</li>
                    <li>Spojit lidi z různých oborů a sdílet vzájemné zkušenosti</li>
                </ul>

            </div>

            <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <h5 class="nadpisy_detail">1. Den kurzu:</h5>
                <ul>
                    <li>Vysvětlení terminologie NGS dat v bioinformatice</li>
                    <li>Vytvoření vlastního bootovací USB s Linux systémem</li>
                    <li>Instalace OS Linux ve virtuálním prostředí Virtual Box</li>
                    <li>Pochopení struktury adresářů v OS Linux</li>
                    <li>Základní příkazy a orientace v příkazové řádce</li>
                </ul>
            </div>

            <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <h5 class="nadpisy_detail">2. Den kurzu:</h5>
                <ul>
                    <li>Instalace prostředí Ananconda a nezbytných bioinformatických balíčků</li>
                    <li>Stažení sekvenačního experimentu z BaseSpace skrz Basemount v příkazové řádce</li>
                    <li>Demultiplexing BCL souborů a tvoření generování FASTQ souborů</li>
                    <li>Kontrola kvality FASTQ souborů a různé filtrační přístupy</li>
                    <li>Stažení referenční sekvence a základní rozdíl mezi jednotlivými "patches"</li>
                 </ul>

            </div>
            <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <h5 class="nadpisy_detail">3. Den kurzu:</h5>
                <ul>
                    <li>Výběr vhodného zarovnávacího algoritmu dle typu sekvenace</li>
                    <li>Zarovnání k referenční sevenci a tvorba BAM souboru</li>
                    <li>Pokročilá kvality kontrola BAM souboru</li>
                    <li>Filtrace BAM souboru dle klinických kriteriích</li>
                    <li>Přehled algoritmů pro hledání krátkých variant (SNP a INDELs)</li>
                    <li>Generování VCF souborů</li>

                </ul>
            </div>

            <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <h5 class="nadpisy_detail">4. Den kurzu:</h5>
                <ul>
                    <li>Možnosti vizualizace BAM a VCF soubrů</li>
                    <li>Stažení anotačních databází a anotace VCF</li>
                    <li>Filtrace A-VCF souborů dle klinické revelevance</li>
                 </ul>

            </div>
            <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <h5 class="nadpisy_detail">5. Den kurzu:</h5>
                <ul>
                    <li>Praktická ukázka s jednotlivými databázemi BioMart a UCSC</li>
                    <li>Základní tvoření shell skriptů v linuxu</li>
                    <li>Automatizace celé workflow z předešlých dní</li>                </ul>
            </div>

            <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <ul>
                 </ul>

            </div>
                       <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <h5 class="nadpisy_detail">Připravujeme...</h5>
                <ul>
                    <li>Školení pro pokročilejší bioinformatiky - datové analýzy v Pythonu, CNV calling, TRIO analýzy, práce s unikátními molekulovými barkódy (UMI), analýza celoexomových dat (WES) a spousta dalších...</li>
                </ul>
            </div>

            <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <h5 class="nadpisy_detail"></h5>
                <ul>
                 </ul>

            </div>
        </div>


        <template slot="footer">
          <n-button type="danger" @click.native="modals.bioinformatika = false">Back</n-button>
        </template>
      </modal>


      <modal :show.sync="modals.python" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">Detail kurzu Python</h4>
        <div class="row">
            <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <h5 class="nadpisy_detail">Co se na kurzu naučíte:</h5>
                <ul>
                    <li>Vytvořit si vlastní bootovací médium a instalovat libovolný OS systém </li>
                    <li>Pokročilejší práci v příkazové řádce </li>
                    <li>Demultiplexování BCL dat dle SampleSheet</li>
                    <li>Zhodnocení NGS dat od sekvenace po pokrytí jednotlivých genů</li>
                    <li>Instalaci bioinformatických balíčků v prostředí anacodna</li>
                    <li>Filtrace sekvenačních dat dle kvality či kontamiance</li>
                    <li>Evaulace přípravy knihovny - PCR duplikáty, on/off target reads</li>
                    <li>Anotace VCF souborů</li>
                    <li>Filtrace dle klinické významnosti</li>
                    <li>Základy skriptování a automatizace práce </li>
                    <li>Práce s databázemi UCSC a BioMart</li>
                    <li>Základní práce v programovacím jazyku Python</li>

                </ul>
            </div>

            <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <h5 class="nadpisy_detail">Naše filozofie:</h5>
                <ul>
                    <li>Práce s open source balíčky</li>
                    <li>Každý příklad zkoušet prakticky</li>
                    <li>Školení probíha na reálných datech</li>
                    <li>Nenásilně ale přesto intenzivně pochopit dané téma</li>
                    <li>Odnést si celou svoji vytvořenou práci domů</li>
                    <li>Předat prostřednitvím lektorů jejich mnohaletou zkušenost v oboru</li>
                    <li>Spojit lidi z různých oborů a sdílet vzájemné zkušenosti</li>
                </ul>

            </div>

            <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <h5 class="nadpisy_detail">1. Den kurzu:</h5>
                <ul>
                    <li>Vysvětlení terminologie NGS dat v bioinformatice</li>
                    <li>Vytvoření vlastního bootovací USB s Linux systémem</li>
                    <li>Instalace OS Linux ve virtuálním prostředí Virtual Box</li>
                    <li>Pochopení struktury adresářů v OS Linux</li>
                    <li>Základní příkazy a orientace v příkazové řádce</li>
                </ul>
            </div>

            <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <h5 class="nadpisy_detail">2. Den kurzu:</h5>
                <ul>
                    <li>Instalace prostředí Ananconda a nezbytných bioinformatických balíčků</li>
                    <li>Stažení sekvenačního experimentu z BaseSpace skrz Basemount v příkazové řádce</li>
                    <li>Demultiplexing BCL souborů a tvoření generování FASTQ souborů</li>
                    <li>Kontrola kvality FASTQ souborů a různé filtrační přístupy</li>
                    <li>Stažení referenční sekvence a základní rozdíl mezi jednotlivými "patches"</li>
                 </ul>

            </div>
            <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <h5 class="nadpisy_detail">3. Den kurzu:</h5>
                <ul>
                    <li>Výběr vhodného zarovnávacího algoritmu dle typu sekvenace</li>
                    <li>Zarovnání k referenční sevenci a tvorba BAM souboru</li>
                    <li>Pokročilá kvality kontrola BAM souboru</li>
                    <li>Filtrace BAM souboru dle klinických kriteriích</li>
                    <li>Přehled algoritmů pro hledání krátkých variant (SNP a INDELs)</li>
                    <li>Generování VCF souborů</li>

                </ul>
            </div>

            <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <h5 class="nadpisy_detail">4. Den kurzu:</h5>
                <ul>
                    <li>Možnosti vizualizace BAM a VCF soubrů</li>
                    <li>Stažení anotačních databází a anotace VCF</li>
                    <li>Filtrace A-VCF souborů dle klinické revelevance</li>
                 </ul>

            </div>
            <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <h5 class="nadpisy_detail">5. Den kurzu:</h5>
                <ul>
                    <li>Praktická ukázka s jednotlivými databázemi BioMart a UCSC</li>
                    <li>Základní tvoření shell skriptů v linuxu</li>
                    <li>Automatizace celé workflow z předešlých dní</li>                </ul>
            </div>

            <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <ul>
                 </ul>

            </div>
                       <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <h5 class="nadpisy_detail">Připravujeme...</h5>
                <ul>
                    <li>python python pytghon....</li>
                </ul>
            </div>

            <div class="col-md-6 justify-content-center odsazeni_vice_o_kurzech">
                <h5 class="nadpisy_detail"></h5>
                <ul>
                 </ul>
            </div>
        </div>
        <template slot="footer">
          <n-button type="danger" @click.native="modals.python = false">Back</n-button>
        </template>
      </modal>


      <modal :show.sync="modals.akce" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">Detail doprovodných akcí</h4>

                <div class="row">
                    <div class="col-md-5 ml-auto mt-5">
                        <card type="blog" raised>
                            <img slot="image" class="img rounded" src="img/amalka_vchod.jpg">
                            <img slot="image" class="img rounded" src="img/amalka_restaurace.jpg">
                            <img slot="image" class="img rounded" src="img/amalka_pivnice.jpg">
                        </card>
                    </div>
                    <div class="col-md-5 mr-auto doprovodne_akce">
                        <info-section type="warning"
                                      icon="fas fa-hotel"
                                      title="Hotel Amálka"
                                      description="Kurz pořádáme v příjemném hotelu rodinného typu Amálka ve Straškově, 20 minut severně od Prahy po D8."
                        />
                        <info-section type="warning"
                                      icon="fas fa-train"
                                      title="Dobrá dostupnost autem i vlakem"
                                      description="Možnost parkování u hotelu. Pojedete-li vlakem, vyzvedneme vás v Roudnici nad Labem."
                        />
                        <info-section type="warning"
                                      icon="fas fa-bed"
                                      title="Pohodlné ubytování"
                                      description="2, 3 a 4 lůžkové pokoje s vlastní koupelnou."
                        />                        
                        <info-section type="warning"
                                      icon="fas fa-utensils"
                                      title="Plná penze, občerstvení"
                                      description="K dispozici bude plná penze a občerstvení (včetně kávy) po celou dobu školení."
                        />
                    </div>
                </div>
                <hr/>
                <div class="row">
                    <div class="col-md-5 ml-auto mt-5 doprovodne_akce">
                        <info-section type="warning"
                                      icon="fas fa-glass-cheers"
                                      title="Pořádáme společné večerní posezení"
                                      description="Zajímá vás detailnější debata o probrané kapitole ze školení nebo jaké jsou mederní trendy v bioinformatice, tak večer můžete strávit v hotelové Py-vnici. "
                        />
                        <info-section type="warning"
                                      icon="fas fa-beer"
                                      title="Sauna"
                                      description=" Pokud zvolíte relaxaci pro načerpání síly na další den je k dispozici finská, tropická nebo infra sauna."
                        />
                        <info-section type="warning"
                                      icon="fas fa-swimming-pool"
                                      title="Bazén"
                                      description="Nebo můžete relaxovat v bazénu s whirlpoolem."
                        />
                    </div>
                    <div class="col-md-5 mr-auto">
                        <card type="blog" raised>
                            <img slot="image" class="img rounded" src="img/amalka_sauna.jpg">
                            <img slot="image" class="img rounded" src="img/amalka_bazen.jpg">
                            <img slot="image" class="img rounded" src="img/amalka_infrasauna.jpg">
                        </card>
                    </div>
                </div>
                <hr/>

        <template slot="footer">
          <n-button type="danger" @click.native="modals.akce = false">Back</n-button>
        </template>
      </modal>

        <modal :show.sync="modals.mini"
                class="modal-success"
                :show-close="false"
                header-classes="justify-content-center"
                type="mini">
        <div slot="header" class="modal-profile d-flex justify-content-center align-items-center">
            <i class="now-ui-icons users_circle-08"></i>
        </div>
        <p>Děkujeme za odeslání, brzy se ozveme.</p>
        <template slot="footer">
            <n-button type="neutral" link @click.native="modals.mini = false">Close</n-button>
        </template>
        </modal>

        <modal :show.sync="modals.chyba"
                class="modal-danger"
                :show-close="false"
                header-classes="justify-content-center"
                type="mini">
        <div slot="header" class="modal-profile d-flex justify-content-center align-items-center">
            <i class="now-ui-icons users_circle-08"></i>
        </div>
        <p>Nevyplněny kontaktní údaje, ověřte laskavě formulář.</p>
        <p>Povinné je jméno a k němu email nebo telefon.</p>
        <template slot="footer">
            <n-button type="neutral" link @click.native="modals.chyba = false">Close</n-button>
        </template>
        </modal>

    </div>
</template>
<script>
  import { Card, Button, FormGroupInput, Tabs, TabPane, Badge, InfoSection, Modal } from '@/components';
  import { Select, Option, Carousel, CarouselItem, Table, TableColumn } from 'element-ui'
  import axios from 'axios';


  export default {
    name: 'landing-page',
    bodyClass: 'landing-page',
    components: {
      Card,
      Tabs,
      TabPane,
      Badge,
      InfoSection,
      [Modal.name]: Modal,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data() {
      return {
        detail_kurzu: false,
        detail_amalka: false,
        form: {
            name: '',
            email: '',
            phone: '',
            longtext: ''
        },
        modals: {
            python: false,
            bioinformatika: false,
            akce: false,
            rozvrh: false,
            mini: false,
            chyba: false        
        },
        tableData: [{
            id: 1,
            iktyp: 'fas',
            ikona: 'fa-hotel',
            cas1: '7:00',
            cas2: '8:50',
            udalost: 'Snídaně'
          },
          {
            id: 2,
            ikona: 'fas fa-hotel',
            cas1: '9:00',
            cas2: '12:45',
            udalost: 'Výuka blok 1'
          },
          {
            id: 3,
            ikona: 'fas fa-hotel',
            cas1: '13:00',
            cas2: '13:55',
            udalost: 'Oběd'
          },
          {
            id: 4,
            ikona: 'fas fa-hotel',
            cas1: '14:00',
            cas2: '17:45',
            udalost: 'Výuka blok 2'
          },
          {
            id: 5,
            ikona: 'fas fa-hotel',
            cas1: '18:00',
            cas2: '19:00',
            udalost: 'Večeře'
          },
          {
            id: 6,
            ikona: 'fas fa-hotel',
            cas1: '19:00',
            cas2: '22:00',
            udalost: 'Volitelné společenské akce nebo individuální odpočinek'
          }]


      }
    },
    methods: {
        send_mail: function() {
            if ((this.form.name) && ((this.form.email) || (this.form.phone))) {
                axios.post("http://127.0.0.1:8010/send_mail/", {
                    privat_api_key: "bioxsys_cz_secret_code",
                    subject: "TEST Bioxsys",
                    name: this.form.name,
                    email: this.form.email,
                    phone: this.form.phone,
                    longtext: this.form.longtext
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }            
                )
                .then(response => {
                    this.modals.mini = true;
                    this.form.name = "";
                    this.form.email = "";
                    this.form.phone = "";
                    this.form.longtext = "";
                })
            }
            else {
                this.modals.chyba = true;
            }
        }

    }    
  }
</script>
<style>
.card-description {
    color: black !important;
    font-weight: 500 !important;
}

.text-white {
    font-weight: 500 !important;
}

.text-black {
    color: black !important;
    font-weight: 500 !important;

}

.card-profile {
    margin-top: 5px !important;
}

.modal-dialog {
    max-width: 80% ! important;
    max-height: 80% ! important;
}

.title_text {
    color: white !important;
    font-size: 45px !important;
    font-weight: 600 !important;
}

.main_kurz_bold {
    color: black !important;
    font-size: 25px !important;
    font-weight: 450 !important;

}

.odsazeni_vice_o_kurzech {
    color: black !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin-top: 40px !important;
    padding-left: 60px !important;
    padding-right: 20px !important;


}

.nadpisy_detail {
    color: black !important;
    font-size: 18px !important;
    font-weight: 600 !important;

}

.nadpisy_poddetail {
    color: black !important;
    font-size: 18px !important;
    font-weight: 600 !important;

}

.doprovodne_akce {
    color: black !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin-top: 5px !important;
    padding-left: 20px !important;


}

.title_center {
    text-align: center !important;
    margin-top: 30px !important;

}

.schedule_center {
    text-align: center !important;

}

.termin_bold {
    text-align: center !important;
    font-weight: 600 !important;
    font-size: 15px !important;

}

.carousel-centered {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
}

.project-5 {
    padding: 10px 0 !important;
}

p {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    color: black !important;
    font-weight: 500 !important;
}
</style>    
