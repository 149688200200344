<template>
 
 
       <div class="blogs-1">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 ml-auto mr-auto">
                        <h2 class="title title_center">FAQ</h2>

                        <card type="blog" plain>
                            <div class="row">
                                <div class="col-md-10  ml-auto mr-auto">
                                    <collapse :multiple-active="false">
                                        <collapse-item v-for="(one, index) in faq" v-bind:key="index" v-bind:value="one" v-bind:title="one.q" name="1">
                                        <div> {{ one.a }} </div>
                                        </collapse-item>
                                    </collapse>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-10 ml-auto mr-auto">
                                    <video autoplay muted loop>
                                        <source src="/img/api_thumb_600.mp4" type="video/mp4">
                                     </video>
                                </div>
                            </div>                            
                        </card>

                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
  import { Card, Button, FormGroupInput, Tabs, TabPane, Collapse, CollapseItem } from '@/components';

  export default {
    name: 'landing-page',
    bodyClass: 'landing-page',
    components: {
      Card,
      Tabs,
      TabPane,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput,
      Collapse,
      CollapseItem
    },
    data() {
      return {
        form: {
          firstName: '',
          email: '',
          message: ''
        },
        faq: [
            {
                q: "K čemu slouží software Genovesa?",
                a: "Genovesa je sofistikovaná aplikace která slouží jako laboratorní informační systém (LIS) pro evidenci pacientů, sekundární a terciální analýzu NGS sekvenačních dat, platforma pro sdílení anaotovaných variant, jejich intepretaci a tvoření reportu. "
            },
            {
                q: "Data jsou uložena na cloudu nebo lokálně?",
                a: "Privátní data pacientů jsou vždy uložena lokálně na dané klinice. Sekundární a terciální analýza může probíhat na serveru lokálně nebo na našem cloudu. "
            },
            {
                q: "Dodáváte i hardware v případě, že chci lokální verzi?",
                a: "Ne, dle Vašich potřeb na výpočetní kapacitu sekvenačních dat Vám navrhneme server na míru a proveme instalaci našeho software Genovesa."
            },
        ]
      }
    }
  }
</script>
<style>
.card-description {
    color: black !important;
    font-weight: 500 !important;
}

.text-white {
    font-weight: 300 !important;
}

.title_center {
    text-align: center !important;
    margin-top: 30px !important;
    padding-top: 80px !important;


}

video {
  width: 100%;
  height: auto;
}

</style>