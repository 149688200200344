<template>
        <div class="blogs-1">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 ml-auto mr-auto">
                        <h2 class="title title_center">FAQ</h2>

                        <card type="blog" plain>
                            <div class="row">
                                <div class="col-md-10  ml-auto mr-auto">
                                    <collapse :multiple-active="false">
                                        <collapse-item v-for="(one, index) in faq" v-bind:key="index" v-bind:value="one" v-bind:title="one.q" name="1">
                                        <div> {{ one.a }} </div>
                                        </collapse-item>
                                    </collapse>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-10 ml-auto mr-auto">
                                    <video autoplay muted loop>
                                        <source src="/img/sroubovice_1.mp4" type="video/mp4">
                                     </video>
                                </div>
                            </div>                            
                        </card>

                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
  import { Card, Button, FormGroupInput, Tabs, TabPane, Collapse, CollapseItem } from '@/components';

  export default {
    name: 'landing-page',
    bodyClass: 'landing-page',
    components: {
      Card,
      Tabs,
      TabPane,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput,
      Collapse,
      CollapseItem
    },
    data() {
      return {
        form: {
          firstName: '',
          email: '',
          message: ''
        },
        faq: [
            {
                q: "Jaké typy sekvenačních dat jste schopni analyzovat?",
                a: "Analyzujeme data typu DNA-seq, RNA-seq (kvantifikace a diferenciální exprese), Fúzní geny, miRNA, Metylace, metagenomická data, genotypizace, TRIO analýzy, strukturní změny a CNV.."
            },
            {
                q: "Uděláte nám workflow na míru daného projektu?",
                a: "Ano, stačí nás kontaktovat v našem formuláři a popsat stručně daný projekt. My Vás co nejdříve kontaktujeme abychom dohodli detaily projektu."
            },
            {
                q: "Analyzujete i celoexomová (WES) či celogenomová (WGS) data?",
                a: "Naše výpočetní servery a sestavené worflow jsou primárně konstruované na WES a WGS data. Samozřejmě neopomíjíme ani klinické exomy a panelové sekvenování."
            },
        ]
      }
    }
  }
</script>
<style>
.card-description {
    color: black !important;
    font-weight: 500 !important;
}

.text-white {
    font-weight: 300 !important;
}

.title_center {
    text-align: center !important;
    margin-top: 30px !important;

}

video {
  width: 100%;
  height: auto;
}

</style>
