<template>
       <div class="">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 ml-auto mr-auto">
                        <h2 class="title title_center">FAQ - Kurzy</h2>

                        <card type="blog" plain>
                            <div class="row">
                                <div class="col-md-10  ml-auto mr-auto">
                                    <collapse :multiple-active="false">
                                        <collapse-item v-for="(one, index) in faq" v-bind:key="index" v-bind:value="one" v-bind:title="one.q" name="1">
                                        <div> {{ one.a }} </div>
                                        <hr>
                                        </collapse-item>
                                    </collapse>
                                </div>
                            </div>

<!--                             <div class="row">
                                <div class="col-md-10 ml-auto mr-auto">
                                    <video autoplay muted loop>
                                        <source src="/img/sroubovice_1.mp4" type="video/mp4">
                                     </video>
                                </div>
                            </div>        -->
                        </card>

                    </div>
                </div>
            </div>
        </div>


</template>
<script>
  import { Card, Button, FormGroupInput, Tabs, TabPane, Collapse, CollapseItem } from '@/components';

  export default {
    name: 'landing-page',
    bodyClass: 'landing-page',
    components: {
      Card,
      Tabs,
      TabPane,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput,
      Collapse,
      CollapseItem
    },
    data() {
      return {
        form: {
          firstName: '',
          email: '',
          message: ''
        },
        faq: [
            {
                q: "Mohu se zůčastnit kurzu i když jsem nikdy nepracoval v příkazové řádce nebo neprogramoval?",
                a: "Samozřejmě, že můžete. Cílem kurzu je naučit základy v příkazové řádce úplné začátečníky."
            },
            {
                q: "Co budu schopen analyzovat po absolvování školení?",
                a: "Budete schopen provést kontrolu kvality sekvenačních dat, filtrovat FASTQ, zarovnat data k referenční sekvenci, pokročilá kvality kontrola (read depth, on/off targets, PCR duplikáty,..), nalézt varianty a anotovat je, filtrovat anotovaná data v Pythonu a mnoho dalšího."
            },

        ]
      }
    }
  }
</script>
<style>
.card-description {
    color: black !important;
    font-weight: 500 !important;
}

.text-white {
    font-weight: 300 !important;
}

.title_center {
    text-align: center !important;
    margin-top: 10px !important;
    padding-top: 80px !important;

}

video {
  width: 100%;
  height: auto;
}

</style>
