<template>
    <div class="wrapper landing-page">
        <div class="page-header ">
            <parallax class="page-header-image"
                 style="background-image: url('img/960_M.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">Tvoříme budoucnost personalizované bioinformatiky.</h1>
                <div class="text-center">

                </div>
            </div>
        </div>

        <div class="section section-about-us">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">BIOINFORMATICKÉ PROFESIONÁLNÍ ŘEŠENÍ</h2>
                        <h5 class="description">Požadujete specializovanou bioinformatickou analýzu na míru nebo raději automatizovanou validovanou diagnostickou workflow - tak jste na správném místě.</h5>
                    </div>
                </div>
                <div class="separator separator-primary"></div>
                <div class="section-story-overview">
                    <div class="row">
                        <div class="col-md-6">
                            <!-- First image on the left side -->
                            <div class="image-container image-left" style="background-image: url('img/dna_13.jpg')">
                                <p class="blockquote blockquote-primary">"Poskytujeme profesionální bioinformatické automatizované řešení zaměřené na data z vysokokapacitních NGS sekvenátorů."
                                    <br>
                                    <br>
                                    <small>-NOAA</small>
                                </p>
                            </div>
                            <!-- Second image on the left side of the article -->
                            <div class="image-container image-left-bottom"
                                 style="background-image: url('img/826_L.jpg')"></div>
                        </div>
                        <div class="col-md-5">
                            <!-- First image on the right side, above the article -->
                            <div class="image-container image-right"
                                 style="background-image: url('img/840_M.jpg')"></div>
                            <h4>Soustředíme se na diagnostické řešení převážně celoexomových (WES) a panelových dat. Naší doménou není pouze analýza DNA-seq, ale i RNA-seq, Methyl-Seq, Genotypování a Metagenomika.</h4>

                            <h2></h2>
                            <h2>VarStore</h2>

                            <p>VarStore je softwarová automatizovaná platforma pro analýzu a management diagnostických dat generovaných se sekvenátorů nové generace. Naší hlavní dómenou jsou validované workflow od celoexomových a panelových dat (FASTQ souborů) až po výsledný diagnostický report. 
                            </p>
                            <p>VarStore řeší komplexní analýzu od kvality kontrol sekvenačních dat, sekundární analýzy (FASTQ - VFC), terciální analýzy (anotace VCF) až po uložení variant do relační databáze a přehlednou grafickou vizualizaci, filtraci a tvoření reportu.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="testimonials-1 section-image" style="background-image: url('img/hpserv_01.jpg')">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 ml-auto mr-auto text-center">
                        <h2 class="title">Jaké je zabezpečení dat?</h2>
                        <h5 class="description text-white">Data dělíme podle charakteristiky na privátní a anonymizovaná.</h5>
                        <h5 class="description text-white">V našem DB clusteru máme v čitelné podobě pouze data anonymizovaná, pro účely vlastního zpracování. Veškerá privátní data jsou uložena na vašem osobním serveru (Bioxsys personal server - BPS) a umístěna na místě vámi zvoleném.</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <card type="testimonial">
                            <img slot="avatar" class="img img-raised" src="img/michael.jpg">
                            <p class="card-description">
                                Zabezpečení dat našich pacientů je klíčová věc. S Bioxsysu se na to můžeme spolehnout.
                            </p>
                            <template slot="raw-content">
                                <div class="icon icon-primary">
                                    <i class="fas fa-quote-right"></i>
                                </div>
                                <div class="card-footer">
                                    <h4 class="card-title">Michael Elijah</h4>
                                    <p class="category">@michaelelijah</p>
                                </div>
                            </template>
                        </card>
                    </div>
                    <div class="col-md-4">
                        <card type="testimonial">
                            <img slot="avatar" class="img img-raised" src="img/olivia.jpg">
                            <p class="card-description">
                                Citlivá data mám uložena v ordinaci, v zamčené skříni, pod kamerovým systémem. To je moje jistota. 
                            </p>
                            <template slot="raw-content">
                                <div class="icon icon-primary">
                                    <i class="fas fa-quote-right"></i>
                                </div>
                                <div class="card-footer">
                                    <h4 class="card-title">Olivia Harper</h4>
                                    <p class="category">@oliviaharper</p>
                                </div>
                            </template>
                        </card>
                    </div>
                    <div class="col-md-4">
                        <card type="testimonial">
                            <img slot="avatar" class="img img-raised" src="img/james.jpg">
                            <p class="card-description">
                                BPS je moje jistota, k pacientským datům se nikdo nedostane. Do cloudu bych je neuložil.
                            </p>
                            <template slot="raw-content">
                                <div class="icon icon-primary">
                                    <i class="fas fa-quote-right"></i>
                                </div>
                                <div class="card-footer">
                                    <h4 class="card-title">James Logan</h4>
                                    <p class="category">@jameslogan</p>
                                </div>
                            </template>
                        </card>
                    </div>
                </div>
            </div>
        </div>

        <div class="section section-about-us">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">BIOINFORMATICKÉ PROFESIONÁLNÍ ŘEŠENÍ</h2>
                        <h5 class="description">Požadujete specializovanou bioinformatickou analýzu na míru nebo raději automatizovanou validovanou diagnostickou workflow - tak jste na správném místě.</h5>
                    </div>
                </div>
                <div class="separator separator-primary"></div>
                <div class="section-story-overview">
                    <div class="row">
                        <div class="col-md-6">
                            <!-- First image on the left side -->
                            <div class="image-container image-left" style="background-image: url('img/dna_13.jpg')">
                                <p class="blockquote blockquote-primary">"Poskytujeme profesionální bioinformatické automatizované řešení zaměřené na data z vysokokapacitních NGS sekvenátorů."
                                    <br>
                                    <br>
                                    <small>-NOAA</small>
                                </p>
                            </div>
                            <!-- Second image on the left side of the article -->
                            <div class="image-container image-left-bottom"
                                 style="background-image: url('img/826_L.jpg')"></div>
                        </div>
                        <div class="col-md-5">
                            <!-- First image on the right side, above the article -->
                            <div class="image-container image-right"
                                 style="background-image: url('img/840_M.jpg')"></div>
                            <h4>Soustředíme se na diagnostické řešení převážně celoexomových (WES) a panelových dat. Naší doménou není pouze analýza DNA-seq, ale i RNA-seq, Methyl-Seq, Genotypování a Metagenomika.</h4>

                            <h2></h2>
                            <h2>VarStore</h2>

                            <p>VarStore je softwarová automatizovaná platforma pro analýzu a management diagnostických dat generovaných se sekvenátorů nové generace. Naší hlavní dómenou jsou validované workflow od celoexomových a panelových dat (FASTQ souborů) až po výsledný diagnostický report. 
                            </p>
                            <p>VarStore řeší komplexní analýzu od kvality kontrol sekvenačních dat, sekundární analýzy (FASTQ - VFC), terciální analýzy (anotace VCF) až po uložení variant do relační databáze a přehlednou grafickou vizualizaci, filtraci a tvoření reportu.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="testimonials-1 section-image" style="background-image: url('img/dna_10.jpg')">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 ml-auto mr-auto text-center">
                        <h2 class="title">Co říkají o VarStore odborníci?</h2>
                        <h4 class="description text-white">Je to skvělé!</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <card type="testimonial">
                            <img slot="avatar" class="img img-raised" src="img/michael.jpg">
                            <p class="card-description">
                                Už nikdy nic jiného. Ušetří mi hodně času. A mohu se na ně spolehnout.
                            </p>
                            <template slot="raw-content">
                                <div class="icon icon-primary">
                                    <i class="fas fa-quote-right"></i>
                                </div>
                                <div class="card-footer">
                                    <h4 class="card-title">Michael Elijah</h4>
                                    <p class="category">@michaelelijah</p>
                                </div>
                            </template>
                        </card>
                    </div>
                    <div class="col-md-4">
                        <card type="testimonial">
                            <img slot="avatar" class="img img-raised" src="img/olivia.jpg">
                            <p class="card-description">
                                Skvělý nástroj, férový přístup. Je vidět, že to dělají s nadšením. 
                            </p>
                            <template slot="raw-content">
                                <div class="icon icon-primary">
                                    <i class="fas fa-quote-right"></i>
                                </div>
                                <div class="card-footer">
                                    <h4 class="card-title">Olivia Harper</h4>
                                    <p class="category">@oliviaharper</p>
                                </div>
                            </template>
                        </card>
                    </div>
                    <div class="col-md-4">
                        <card type="testimonial">
                            <img slot="avatar" class="img img-raised" src="img/james.jpg">
                            <p class="card-description">
                                Nikdy jsem nic takového neviděl. Intuitivní a spolehlivá aplikace.
                            </p>
                            <template slot="raw-content">
                                <div class="icon icon-primary">
                                    <i class="fas fa-quote-right"></i>
                                </div>
                                <div class="card-footer">
                                    <h4 class="card-title">James Logan</h4>
                                    <p class="category">@jameslogan</p>
                                </div>
                            </template>
                        </card>
                    </div>
                </div>
            </div>
        </div>

        <div class="pricing-2">
            <div class="pricing-2" id="pricing-2">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center">
                            <h2 class="title">Pick the best plan for you</h2>
                            <tabs pills type="primary" centered>
                                <tab-pane label="Legal Entity"></tab-pane>
                                <tab-pane label="Individual"></tab-pane>
                            </tabs>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <card type="pricing" plain>
                                <h6 class="category">Enterprise</h6>
                                <h1 class="card-title">
                                    <small>$</small>
                                    59
                                </h1>
                                <ul>
                                    <li>
                                        <b>10GB</b> Disk Space
                                    </li>
                                    <li>
                                        <b>100GB</b> Monthly Bandwidth
                                    </li>
                                    <li>
                                        <b>20</b> Email Accounts
                                    </li>
                                    <li>
                                        <b>Unlimited</b> subdomains
                                    </li>
                                </ul>
                                <n-button type="primary" round>
                                    Sign Up
                                </n-button>
                            </card>
                        </div>
                        <div class="col-md-4">
                            <card type="pricing"
                                  raised
                                  class="card-background"
                                  style="background-image: url('img/pricing2.jpg')">

                                <h6 class="category text-info">Professional</h6>
                                <h1 class="card-title">
                                    <small>$</small>
                                    29
                                </h1>
                                <ul>
                                    <li>
                                        <b>5GB</b> Disk Space
                                    </li>
                                    <li>
                                        <b>50GB</b> Monthly Bandwidth
                                    </li>
                                    <li>
                                        <b>10</b> Email Accounts
                                    </li>
                                    <li>
                                        <b>Unlimited</b> subdomains
                                    </li>
                                </ul>
                                <a href="#pablo" class="btn btn-neutral btn-round">
                                    Sign Up
                                </a>

                            </card>
                        </div>
                        <div class="col-md-4">
                            <card type="pricing" plain>

                                <h6 class="category">Standard</h6>
                                <h1 class="card-title">
                                    <small>$</small>
                                    17
                                </h1>
                                <ul>
                                    <li>
                                        <b>2GB</b> Disk Space
                                    </li>
                                    <li>
                                        <b>25GB</b> Monthly Bandwidth
                                    </li>
                                    <li>
                                        <b>5</b> Email Accounts
                                    </li>
                                    <li>
                                        <b>Unlimited</b> subdomains
                                    </li>
                                </ul>
                                <a href="#pablo" class="btn btn-primary btn-round">
                                    Get Started
                                </a>

                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section-contact-us text-center">
            <div class="container">
                <h2 class="title">Co říkají o VarStore odborníci?</h2>
                <p class="description">Your project is very important to us.</p>
                <div class="row">
                    <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
                        <fg-input
                                class="input-lg"
                                placeholder="First Name..."
                                v-model="form.firstName"
                                addon-left-icon="now-ui-icons users_circle-08">
                        </fg-input>
                        <fg-input
                                class="input-lg"
                                placeholder="Email Here..."
                                v-model="form.email"
                                addon-left-icon="now-ui-icons ui-1_email-85">
                        </fg-input>
                        <div class="textarea-container">
                            <textarea class="form-control"
                                      name="name" rows="4" cols="80"
                                      v-model="form.message"
                                      placeholder="Type a message..."></textarea>
                        </div>
                        <div class="send-button">
                            <n-button type="primary" round block size="lg">Send Message</n-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import { Card, Button, FormGroupInput, Tabs, TabPane } from '@/components';

  export default {
    name: 'landing-page',
    bodyClass: 'landing-page',
    components: {
      Card,
      Tabs,
      TabPane,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput
    },
    data() {
      return {
        form: {
          firstName: '',
          email: '',
          message: ''
        }
      }
    }
  }
</script>
<style>
.card-description {
    color: black !important;
    font-weight: 500 !important;
}

.text-white {
    font-weight: 500 !important;
}
</style>
