<template>
       <div class="">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 ml-auto mr-auto">
                        <h2 class="title title_center">FAQ</h2>

                        <el-select class="center" v-model="id_faq_cat" placeholder="Select">
                            <el-option
                            v-for="item in faq_cats"
                            :key="item.id_faq_cat"
                            :label="item.faq_cat"
                            :value="item.id_faq_cat">
                            </el-option>
                        </el-select>


                        <card type="blog" plain>
                            <div class="row">
                                <div class="col-md-10  ml-auto mr-auto">
                                    <collapse :multiple-active="false">
                                        <collapse-item v-for="(one, index) in g_faqs(id_faq_cat)" v-bind:key="index" v-bind:value="one" name="1">
                                            <template slot="title">
                                                <span v-html="one.question"></span>
                                            </template>
                                        <div v-html="one.answer">  </div>
                                        <hr>
                                        </collapse-item>
                                    </collapse>
                                </div>
                            </div>

<!--                             <div class="row">
                                <div class="col-md-10 ml-auto mr-auto">
                                    <video autoplay muted loop>
                                        <source src="/img/sroubovice_1.mp4" type="video/mp4">
                                     </video>
                                </div>
                            </div>
 -->                        </card>

                    </div>
                </div>
            </div>
        </div>


</template>
<script>
  import { Card, Button, FormGroupInput, Tabs, TabPane, Collapse, CollapseItem } from '@/components';
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

  export default {
    name: 'landing-page',
    bodyClass: 'landing-page',
    components: {
      Card,
      Tabs,
      TabPane,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput,
      Collapse,
      CollapseItem
    },

    data() {
      return {

        form: {
          firstName: '',
          email: '',
          message: ''
        },

        id_faq_cat: 1000,

      }
    },

    computed: {
      ...mapState({
        token: state => state.token,
        faqs: state => state.faqs,
        faq_cats: state => state.faq_cats,

      }),

      ...mapGetters({
        g_faqs: 'g_faqs',


      }),

    },


  methods: {
      ...mapMutations({
        m_kurzy: 'm_kurzy',

      }),


      ...mapActions({
        a_faqs: 'a_faqs',

      }),

  },


  beforeRouteEnter(to, from, next) {
      next( vm => {
      vm.a_faqs ( {} );
    });
  },


  mounted () {
    this.a_faqs ( {} );
  },



  }


</script>

<style>
.card-description {
    color: black !important;
    font-weight: 500 !important;
}

.text-white {
    font-weight: 300 !important;
}

.title_center {
    text-align: center !important;
    margin-top: 30px !important;
    padding-top: 80px !important;


}

video {
  width: 100%;
  height: auto;
}

</style>
