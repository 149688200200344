<template>
    <div class="wrapper blog-post">
        <div class="page-header page-header-small">
            <parallax class="page-header-image" style="background-image: url('img/bg24.jpg')"></parallax>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">WeChat Lucky Money</h2>
                        <h4>WeChat launched in 2013.</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="button-container">
                            <a href="#pablo" class="btn btn-primary btn-round btn-lg">
                                <i class="now-ui-icons text_align-left"></i> Read Article
                            </a>
                            <a href="#pablo" class="btn btn-icon btn-lg btn-twitter btn-round">
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a href="#pablo" class="btn btn-icon btn-lg btn-facebook btn-round">
                                <i class="fab fa-facebook-square"></i>
                            </a>
                            <a href="#pablo" class="btn btn-icon btn-lg btn-google btn-round">
                                <i class="fab fa-google"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 ml-auto mr-auto">
                            <h3 class="title">The Castle Looks Different at Night...</h3>
                            <p>This is the paragraph where you can write more details about your product. Keep you user
                                engaged by providing meaningful information. Remember that by this time, the user is
                                curious, otherwise he wouldn't scroll to get here. Add a button if you want the user to
                                see more. We are here to make life better.
                                <br/>
                                <br/> And now I look and look around and there’s so many Kanyes I've been trying to
                                figure out the bed design for the master bedroom at our Hidden Hills compound... and
                                thank you for turning my personal jean jacket into a couture piece.</p>
                            <p class="blockquote blockquote-primary">
                                “And thank you for turning my personal jean jacket into a couture piece.”
                                <br>
                                <br>
                                <small>
                                    Kanye West, Producer.
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section section-blog col-md-10 ml-auto mr-auto">
                <div class="container">
                    <div class="section-story-overview">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="image-container image-left" style="background-image: url('img/bg35.jpg')">
                                    <!-- First image on the left side -->
                                    <p class="blockquote blockquote-primary">"Over the span of the satellite record,
                                        Arctic sea ice has been declining significantly, while sea ice in the
                                        Antarctichas increased very slightly"
                                        <br>
                                        <br>
                                        <small>-NOAA</small>
                                    </p>
                                </div>
                                <!-- Second image on the left side of the article -->
                                <div class="image-container image-left-bottom"
                                     style="background-image: url('img/bg29.jpg')">
                                </div>
                            </div>
                            <div class="col-md-5">
                                <!-- First image on the right side, above the article -->
                                <div class="image-container image-right"
                                     style="background-image: url('img/project16.jpg')">
                                </div>
                                <h3>So what does the new record for the lowest level of winter ice actually mean</h3>
                                <p>The Arctic Ocean freezes every winter and much of the sea-ice then thaws every
                                    summer, and that process will continue whatever happens with climate change. Even if
                                    the Arctic continues to be one of the fastest-warming regions of the world, it will
                                    always be plunged into bitterly cold polar dark every winter. And year-by-year, for
                                    all kinds of natural reasons, there’s huge variety of the state of the ice.
                                </p>
                                <p>
                                    For a start, it does not automatically follow that a record amount of ice will melt
                                    this summer. More important for determining the size of the annual thaw is the state
                                    of the weather as the midnight sun approaches and temperatures rise. But over the
                                    more than 30 years of satellite records, scientists have observed a clear pattern of
                                    decline, decade-by-decade.
                                </p>
                                <p>The Arctic Ocean freezes every winter and much of the sea-ice then thaws every
                                    summer, and that process will continue whatever happens with climate change. Even if
                                    the Arctic continues to be one of the fastest-warming regions of the world, it will
                                    always be plunged into bitterly cold polar dark every winter. And year-by-year, for
                                    all kinds of natural reasons, there’s huge variety of the state of the ice.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 ml-auto mr-auto">
                            <h3 class="title">Rest of the Story:</h3>
                            <p>We are here to make life better. And now I look and look around and there’s so many
                                Kanyes I've been trying to figure out the bed design for the master bedroom at our
                                Hidden Hills compound... and thank you for turning my personal jean jacket into a
                                couture piece.
                                <br/> I speak yell scream directly at the old guard on behalf of the future. daytime All
                                respect prayers and love to Phife’s family Thank you for so much inspiration. </p>
                            <p> Thank you Anna for the invite thank you to the whole Vogue team And I love you like
                                Kanye loves Kanye Pand Pand Panda I've been trying to figure out the bed design for the
                                master bedroom at our Hidden Hills compound...The Pablo pop up was almost a pop up of
                                influence. All respect prayers and love to Phife’s family Thank you for so much
                                inspiration daytime I love this new Ferg album! The Life of Pablo is now available for
                                purchase I have a dream. Thank you to everybody who made The Life of Pablo the number 1
                                album in the world! I'm so proud of the nr #1 song in the country. Panda! Good music
                                2016!</p>
                            <p> I love this new Ferg album! The Life of Pablo is now available for purchase I have a
                                dream. Thank you to everybody who made The Life of Pablo the number 1 album in the
                                world! I'm so proud of the nr #1 song in the country. Panda! Good music 2016!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section section-blog-info">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 ml-auto mr-auto">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="blog-tags">
                                        Tags:
                                        <span class="label label-primary">Photography</span>
                                        <span class="label label-primary">Stories</span>
                                        <span class="label label-primary">Castle</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <a href="#pablo" class="btn btn-google btn-round pull-right">
                                        <i class="fab fa-google"></i> 232
                                    </a>
                                    <a href="#pablo" class="btn btn-twitter btn-round pull-right">
                                        <i class="fab fa-twitter"></i> 910
                                    </a>
                                    <a href="#pablo" class="btn btn-facebook btn-round pull-right">
                                        <i class="fab fa-facebook-square"></i> 872
                                    </a>
                                </div>
                            </div>
                            <hr/>
                            <card type="profile" plain>
                                <template slot="raw-content">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <div class="card-avatar">
                                                <a href="#pablo">
                                                    <img class="img img-raised" src="img/mike.jpg">
                                                </a>
                                                <div class="ripple-container"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <h4 class="card-title">Alec Thompson</h4>
                                            <p class="description">I've been trying to figure out the bed design for the
                                                master bedroom at our Hidden Hills compound...I like good music from
                                                Youtube.</p>
                                        </div>
                                        <div class="col-md-2">
                                            <button type="button" class="btn btn-default pull-right btn-round">Follow
                                            </button>
                                        </div>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section section-comments">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 ml-auto mr-auto">
                            <div class="media-area">
                                <h3 class="title text-center">3 Comments</h3>
                                <comment v-for="comment in comments"
                                         :avatar="comment.avatar"
                                         :author="comment.author"
                                         :date="comment.date"
                                         :comment="comment.comment"
                                         :replies="comment.replies"
                                         :key="comment.id">
                                </comment>
                            </div>
                            <h3 class="title text-center">Post your comment</h3>
                            <div class="media media-post">
                                <a class="pull-left author" href="#pablo">
                                    <div class="avatar">
                                        <img class="media-object img-raised" alt="64x64" src="img/olivia.jpg">
                                    </div>
                                </a>
                                <div class="media-body">
                                    <textarea class="form-control" v-model="form.comment"
                                              placeholder="Write a nice reply or go home..." rows="4"></textarea>
                                    <div class="media-footer">
                                        <a href="#pablo" class="btn btn-primary pull-right">
                                            <i class="now-ui-icons ui-1_send"></i> Reply
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- end media-post -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="section">
                <div class="container">
                    <div class="col-md-12">
                        <h2 class="title text-center">Similar Stories</h2>
                        <br/>
                        <div class="blogs-1" id="blogs-1">
                            <div class="row">
                                <div class="col-md-10 ml-auto mr-auto">
                                    <card type="blog" plain>
                                        <template slot="raw-content">
                                            <div class="row">
                                                <div class="col-md-5">
                                                    <div class="card-image">
                                                        <img class="img img-raised rounded"
                                                             src="img/examples/card-blog4.jpg" alt="">
                                                    </div>
                                                </div>
                                                <div class="col-md-7">
                                                    <h6 class="category text-info">Enterprise</h6>
                                                    <h3 class="card-title">
                                                        <a href="#pablo">Warner Music Group buys concert discovery
                                                            service Songkick</a>
                                                    </h3>
                                                    <p class="card-description">
                                                        Warner Music Group announced today it’s acquiring the selected
                                                        assets of the music platform Songkick, including its app for
                                                        finding concerts and the company’s trademark.
                                                    </p>
                                                    <p class="author">
                                                        by
                                                        <a href="#pablo">
                                                            <b>Sarah Perez</b>
                                                        </a>, 2 days ago
                                                    </p>
                                                </div>
                                            </div>
                                        </template>
                                    </card>
                                    <card type="blog" plain>
                                        <div class="row">
                                            <div class="col-md-7">
                                                <h6 class="category text-danger">
                                                    <i class="now-ui-icons now-ui-icons media-2_sound-wave"></i> Startup
                                                </h6>
                                                <h3 class="card-title">
                                                    <a href="#pablo">Insticator raises $5.2M to help publishers</a>
                                                </h3>
                                                <p class="card-description">
                                                    Insticator is announcing that it has raised $5.2 million in Series A
                                                    funding. The startup allows online publishers to add quizzes, polls
                                                    and other interactive elements...
                                                </p>
                                                <p class="author">
                                                    by
                                                    <a href="#pablo">
                                                        <b>Anthony Ha</b>
                                                    </a>, 5 days ago
                                                </p>
                                            </div>
                                            <div class="col-md-5">
                                                <div class="card-image">
                                                    <img class="img img-raised rounded"
                                                         src="img/examples/card-blog6.jpg">
                                                </div>
                                            </div>
                                        </div>
                                    </card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import { Card, Button, Comment, Badge, InfoSection, Checkbox } from '@/components';

  export default {
    name: 'blog-post',
    bodyClass: 'blog-post',
    components: {
      Card,
      InfoSection,
      Badge,
      Comment,
      [Button.name]: Button,
      [Checkbox.name]: Checkbox
    },
    data() {
      return {
        form: {
          comment: ''
        },
        comments: [{
          avatar: 'img/james.jpg',
          author: 'Tina Andrew',
          date: '7 minutes ago',
          comment: `<p>Chance too good. God level bars. I'm so proud of @LifeOfDesiigner #1 song in the country. Panda! Don't be scared of the truth because we need to restart the human foundation in truth I stand with the most humility. We are so blessed!</p>
                            <p>All praises and blessings to the families of people who never gave up on dreams. Don't forget, You're Awesome!</p>`,
        }, {
          avatar: 'img/michael.jpg',
          author: 'John Camber',
          date: 'Yesterday',
          comment: `<p>Hello guys, nice to have you on the platform! There will be a lot of great stuff coming soon. We will keep you posted for the latest news.</p>
                            <p> Don't forget, You're Awesome!</p>`,
          replies: [{
            avatar: 'img/julie.jpg',
            author: 'Tina Andrew',
            date: '2 Days Ago',
            comment: `<p>Hello guys, nice to have you on the platform! There will be a lot of great stuff coming soon. We will keep you posted for the latest news.</p>
                                <p> Don't forget, You're Awesome!</p>`,
            liked: true,
            likes: 25
          }]
        },
        ]
      }
    }
  }
</script>
<style>
</style>
