<template>
    <div class="wrapper landing-page">
        <div class="page-header page-header-small">
            <parallax class="page-header-image" style="background-image: url('img/covid_main.png')"> </parallax>
            <div class="content-center">
                <h1 class="title title_text">Kompletní bioinformatická analýza genomu SARS-CoV-2</h1>
                <h1 class="title"></h1>
                <h3 class="title">Informace o cloudové Aplikaci COVID naleznete  <router-link class="router-link-item" to="/covid">zde</router-link></p></h3>
                <div class="text-center">
                    <n-button type="button" icon round class="go_down" @click.native="handleScroll">
                        <i class="fas fa-arrow-down"></i>
                    </n-button>  
                </div>
            </div>
        </div>

        <div class="features-1">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                        <div class="info info-hover">
                            <div class="icon icon-dark"><i class="fas fa-align-right"></i></div>
                            <h4 class="info-titlex main_kurz_bold">Celogenomové zarovnání</h4>
                            <p class="description text-black">Analýza založená na mapování sekvenované RNA k SARS-CoV-2 referenčnímu genomu  <a href="https://www.ncbi.nlm.nih.gov/nuccore/1798174254" target="_blank">NC_045512.2</a> (identickou se sekvencí NM_908947 známou také jako Wuhan-Hu-1) o celkové délce 29903 bp.</p>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                        <div class="info info-hover">
                            <div class="icon icon-dark">
                            <i class="fas fa-code-branch"></i>
                            </div>
                            <h4 class="info-titlex main_kurz_bold">De-novo rekonstrukce</h4>
                            <p class="description text-black">Sestavení de-novo referenčního genomu pomocí de Bruijn grafů. Referenční sekvence tvoří výsledný jeden scafold (FASTA formát) včetně funkční anotace (predikce genů kódujících jednotlivé proteiny - <a href="https://www.ncbi.nlm.nih.gov/nuccore/NC_045512.2?report=graph" target="_blank">CDS</a>).</p>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                        <div class="info info-hover">
                            <div class="icon icon-dark">
                                <i class="fas fa-fingerprint"></i>
                            </div>
                            <h4 class="info-titlex main_kurz_bold">SNP + INDELs analýza</h4>
                            <p class="description text-black">Nalezení krátkých jednonukleotidových variant (včetně delecí) vůči referenčnímu genomu <a href="https://www.ncbi.nlm.nih.gov/nuccore/1798174254" target="_blank">NC_045512</a> a jejich následná anotace a funkční zařazení.</p>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                        <div class="info info-hover">
                            <div class="icon icon-dark">
                                <i class="fas fa-sitemap"></i>
                            </div>
                            <h4 class="info-titlex main_kurz_bold">Fylogenetický stom a zařazení do linií</h4>
                            <p class="description text-black">Vícenásobné zarovnání sekvenačních dat s databází kompletních genomů SARS-CoV-2 rozdělených dle geografického původu. Tvorba fylogenetických stromů s největší pravděpodonostní zařazení dané sekvence.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<!--         <div class="">
            <div class="container">
                <div class="row">
                    <div class="col-md-10 ml-auto mr-auto text-center">
                        <h2 class="title ">Struktura SARS-CoV-2</h2>
                        <br />
                        <card type="blog" plain>
                            <div class="card-image">
                                <img class="img img-raised rounded" src="img/covid_protein.png" />
                                <figcaption>Fig.1: Kompletní řetězec RNA sekvence viru SARS-CoV-2, která má délku 29903 páru bazí (lidský genom má délku více jak 3 miliardy bazí). Zdroj dat: <a href="https://www.ncbi.nlm.nih.gov/nuccore/?term=sars-cov-2">NCBI.</a> </figcaption>

                            </div>
                        </card>

                    </div>
                </div>
            </div>
        </div> -->
<!--        <div class="">
            <div class="container">
                <div class="row">
                    <div class="col-md-10 ml-auto mr-auto text-center">
                        <h2 class="title ">Fylogenetický strom</h2>
                        <br />
                        <card type="blog" plain>
                            <div class="card-image">
                                <img class="img img-raised rounded" src="img/covid_tree.svg" />
                                <figcaption>Fig.1: Ukázka vytvoření klastru podobnosti dvou českých pacientů (zvýrazněny zeleně) vůči referenčním genomům SARS-CoV-2 z různých oblastí Evropy. Ve spodní části fylogenetického stromu lze vidět originální referenční sekvenci NC_045512 označovanou jako Wuhan-Hu-1 (zvýrazněna červeně). Použitá data byla stažena z veřejné databáze <a href="https://www.gisaid.org/">GISAID</a> a <a href="https://www.ncbi.nlm.nih.gov/nuccore/?term=sars-cov-2">NCBI.</a> </figcaption>

                            </div>
                        </card>

                    </div>
                </div>

                <div class="row">
                    <div class="col-md-10 ml-auto mr-auto text-center">
                        <h2 class="title ">Referenční genom SARS-CoV-2 NC_045512 o celkové délce 29903 bp</h2>
                        <br />
                        <card type="blog" plain>
                            <div class="card-image">
                                <a href="https://www.ncbi.nlm.nih.gov/nuccore/NC_045512.2?report=graph#" target="_blank">
                                    <img class="img img-raised rounded" src="img/covid19_genome.jpg" />
                                </a>
                                <figcaption>Fig.2: Kompletní sekvence genomu SARS-CoV-2 sestavená de-novo (Megahit), sekvenovaná technologií Illumina.</figcaption>

                            </div>
                        </card>

                    </div>
                </div>

            </div>
        </div>  -->
    </div>
</template>
<script>
  import { Card, Button, FormGroupInput, Tabs, TabPane, Badge, InfoSection, Modal } from '@/components';
  import { Select, Option, Carousel, CarouselItem, Table, TableColumn } from 'element-ui'
  import axios from 'axios';


  export default {
    name: 'landing-page',
    bodyClass: 'landing-page',
    components: {
      Card,
      Tabs,
      TabPane,
      Badge,
      InfoSection,
      [Modal.name]: Modal,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data() {
      return {
        detail_kurzu: false,
        detail_amalka: false,
        a: {
            jmeno: '',
            telefon: '',
            email: '',
            osob: '',
            zprava: ''
        },
        b: {
            jmeno: '',
            telefon: '',
            email: '',
            osob: '',
            zprava: ''
        },        
        modals: {
            mini: false,
            chyba: false,
            dekujeme: false,
        },
        tableData: [{
            id: 1,
            iktyp: 'fas',
            ikona: 'fa-hotel',
            cas1: '7:00',
            cas2: '8:50',
            udalost: 'Snídaně'
          },
          {
            id: 2,
            ikona: 'fas fa-hotel',
            cas1: '9:00',
            cas2: '12:45',
            udalost: 'Výuka blok 1'
          },
          {
            id: 3,
            ikona: 'fas fa-hotel',
            cas1: '13:00',
            cas2: '13:55',
            udalost: 'Oběd'
          },
          {
            id: 4,
            ikona: 'fas fa-hotel',
            cas1: '14:00',
            cas2: '17:45',
            udalost: 'Výuka blok 2'
          },
          {
            id: 5,
            ikona: 'fas fa-hotel',
            cas1: '18:00',
            cas2: '19:00',
            udalost: 'Večeře'
          },
          {
            id: 6,
            ikona: 'fas fa-hotel',
            cas1: '19:00',
            cas2: '22:00',
            udalost: 'Volitelný odpočinek'
          }
        ],


        
        
        }
    },
    methods: {
        send_mail: function() {
            if ((this.form.name) && ((this.form.email) || (this.form.phone))) {
                axios.post("/ra/send_mail/", {
                    privat_api_key: "bioxsys_cz_secret_code",
                    subject: "TEST Bioxsys",
                    name: this.form.name,
                    email: this.form.email,
                    phone: this.form.phone,
                    longtext: this.form.longtext
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }            
                )
                .then(response => {
                    this.modals.mini = true;
                    this.form.name = "";
                    this.form.email = "";
                    this.form.phone = "";
                    this.form.longtext = "";
                })
            }
            else {
                this.modals.chyba = true;
            }
        },

        objednat_a: function() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.a.email)) {
                axios.post("/ra/order_course", {
                    termin: "A",
                    jmeno: this.a.jmeno,
                    telefon: this.a.telefon,
                    email: this.a.email,
                    osob: this.a.osob,
                    zprava: this.a.zprava
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }            
                )
                .then(response => {
                    this.a.jmeno = "";
                    this.a.telefon = "";
                    this.a.email = "";
                    this.a.osob = "";
                    this.a.zprava = "";
                    this.modals.dekujeme = true;
                })
            }
            else {
                this.modals.chyba = true;
            }
        },

        objednat_b: function() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.b.email)) {
                axios.post("/ra/order_course", {
                    termin: "B",
                    jmeno: this.b.jmeno,
                    telefon: this.b.telefon,
                    email: this.b.email,
                    osob: this.b.osob,
                    zprava: this.b.zprava
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }            
                )
                .then(response => {
                    this.b.jmeno = "";
                    this.b.telefon = "";
                    this.b.email = "";
                    this.b.osob = "";
                    this.b.zprava = "";
                    this.modals.dekujeme = true;
                })
            }
            else {
                this.modals.chyba = true;
            }
        }
    },
    
    
    methods: {
      handleScroll () {
        window.scrollTo({top: 900, left: 0, behavior: 'smooth'});
      },
    },

}
</script>
<style>
.card-description {
    color: black !important;
    font-weight: 500 !important;
}

.text-white {
    font-weight: 500 !important;
}

.text-black {
    color: black !important;
    font-weight: 500 !important;

}

.card-profile {
    margin-top: 5px !important;
}

.modal-dialog {
    max-width: 80% ! important;
    max-height: 80% ! important;
}

.title_text {
    color: white !important;
    font-size: 45px !important;
    font-weight: 600 !important;
}

.main_kurz_bold {
    color: black !important;
    font-size: 30px !important;
    font-weight: 800 !important;

}

.odsazeni_vice_o_kurzech {
    color: black !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin-top: 40px !important;
    padding-left: 60px !important;
    padding-right: 20px !important;


}

.nadpisy_detail {
    color: black !important;
    font-size: 18px !important;
    font-weight: 600 !important;

}


.kurzy_vice_nadpis {
    color: black !important;
    font-size: 20px !important;
    font-weight: 800 !important;
    text-align: left !important;
    padding-top: 0px !important;

}



.kurzy_vice {
    color: black !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    text-align: left !important;
    display: list-item !important;
    padding-left: 10px !important;
}


.doprovodne_akce {
    color: black !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    text-align: center !important;
}

.title_center {
    text-align: center !important;
    margin-top: 30px !important;

}

.schedule_center {
    text-align: center !important;

}

.termin_bold {
    text-align: center !important;
    font-weight: 600 !important;
    font-size: 15px !important;

}


.termin_bold_big_orange {
    text-align: center !important;
    font-weight: 800 !important;
    font-size: 25px !important;
    color: #f96332 !important;
}

.carousel-centered {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
}

.project-5 {
    padding: 10px 0 !important;
}

p {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    color: black !important;
    font-weight: 500 !important;
}

figcaption {
    margin-top: 10px !important;
}

.blue_card {
    background-color: #407bff !important;
}

.go_down {
    font-weight: 800 !important;
    margin-top: 15px !important;
    background-color: orangered !important;
}
</style>    




