 <template>
    <div class="wrapper landing-page">
        <div class="page-header page-header-small">
            <parallax class="page-header-image"
                 style="background-image: url('img/805_M.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">Jistota pro realizaci projektů libovolného rozsahu</h1>
                <h1 class="title"></h1>

                <div class="text-center">
                    <n-button type="button" icon round class="go_down" @click.native="handleScroll">
                        <i class="fas fa-arrow-down"></i>
                    </n-button>  
                </div>
            
            </div>
        </div> 

        <div class="features-1">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 ml-auto mr-auto">
                        <!-- <h2 class="title">Intenzivní týdenní pobytový kurz Python & Bioinformatika</h2> -->
                        <h4 class="description text-black">Realizace a integrace IT projektů vyžaduje mnoho úsilí všech účastníků. Náš software development tým Vám pomůže nesnáze překonat ve všech fázích realizace u libovolně velkého projektu.</h4>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                        <div class="info info-hover">
                            <div class="icon icon-dark"><i class="fas fa-users"></i></div>
                            <h4 class="info-titlex main_kurz_bold">Předprojektové konzultace</h4>
                            <p class="description text-black">Příprava projektu vyžaduje nejen znalost cíle, ale i nástrojů, kterými bude realizován. Zapojte nás do přípravy zadání a ušetřete významnou část prostředků optimalizací návrhu.</p>
                            <p class="description text-black">Nevíte-li, jestli využít služeb našeho datacentra včetně výpočetních serverů nebo raději zvolit lokální řešení, rádi Vám poradíme a vysvětlíme rozdíly.</p>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                        <div class="info info-hover">
                            <div class="icon icon-dark"><i class="fas fa-tasks"></i></div>
                            <h4 class="info-titlex main_kurz_bold">Realizace projektu</h4>
                            <p class="description text-black">Realizace probíhá v několika fázích od zahájení vývoje po implementaci a testování. K řízení projektu používáme projektový systém Redmine, kde mají přístup všichni, kdo se podílejí na celém projektu a mohou jednoduše monitorovat status celého projektu. Poslední fáze testování probíhá ve velmi intezivní komunikaci s našimi vývojáři nebo bioinformatiky.</p>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                        <div class="info info-hover">
                            <div class="icon icon-dark">
                                <i class="fas fa-phone-volume"></i>
                            </div>
                            <h4 class="info-titlex main_kurz_bold">Technická podpora</h4>
                            <p class="description text-black">Řešíte-li nějaký problém, nebo si jen nevíte rady, naše technická podpora je Vám k dispozici pět dní v týdnu. Samozřejmostí podpory je i pravidelný update aplikací a bioinformatických databází.</p>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>


    </div>
</template>
<script>
  import { Card, Button, FormGroupInput, Tabs, TabPane, Badge, InfoSection } from '@/components';

  export default {
    name: 'landing-page',
    bodyClass: 'landing-page',
    components: {
      Card,
      Tabs,
      TabPane,
      Badge,
      InfoSection,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput
    },
    data() {
      return {
        form: {
          firstName: '',
          email: '',
          message: ''
        }
      }
    },

    methods: {
    handleScroll () {
        window.scrollTo({top: 900, left: 0, behavior: 'smooth'});
      },
    }, 
  
  }
</script>
<style>
.card-description {
    color: black !important;
    font-weight: 500 !important;
}

.text-white {
    font-weight: 500 !important;
}

.text-black {
    color: black !important;
    font-weight: 500 !important;
}

.card-profile {
    margin-top: 5px !important;
}

.go_down {
    font-weight: 800 !important;
    margin-top: 15px !important;
    background-color: orangered !important;
}

</style>
