<template class="fl">
  <div class="wrapper landing-page">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/hp_databaze.jpg')"
      >
      </parallax>
      <div class="content-center">
        <h1 class="title"> Hledáme </h1>
        <h2 class="title"> Full-stack vývojáře/vývojářku (i junior) webových aplikací ve Vue, Pythonu, Postgresu, Mongu </h2>

        <!-- <div class="text-center">
          <a
            href="https://www.facebook.com/groups/1016610115019629/"
            class="btn btn-primary btn-icon btn-round"
          >
            <i class="fab fa-facebook-square"></i>
          </a>
          <a
            href="https://twitter.com/BioinformatikC"
            class="btn btn-primary btn-icon btn-round"
          >
            <i class="fab fa-twitter"></i>
          </a>
        </div> -->
      </div>
    </div>

    <div class="section section-about-us pad">
      <div class="team-4">
        <div class="container">

          <div class="row">
            <div class="col-xl-12 col-lg-12 mt-5">
              <div slot="raw-content" class="row">
                <div class="col-md-12">
                  <div class="card-body">
                    <h3 class="card-title"> Hledáme nadšeného a talentovaného full-stack vývojáře/ku, který/á se chce podílet na vývoji špičkového softwaru pro analýzu biologických dat v jedné z nejzajímavějších bioinformatických firem v České republice. </h3>
                    <h6 class="category text-primary"></h6>

                    <h4 class="title"> Co u nás budeš dělat: </h4>
                    <ul class="card-description">
                      <li> Vývoj moderních webových aplikací pro zdravotnictví. </li>
                      <li> Spolupráce na vývoji laboratorních informačních systémů. </li>
                      <li> Úzká spolupráce s biology a bioinformatiky na řešení komplexních problémů. </li>
                      <li> Příležitost podílet se na vývoji inovativních produktů, které mění svět medicíny. </li>
                    </ul>

                    <h4 class="title"> Co od tebe očekáváme: </h4>
                    <ul class="card-description">
                      <li> Znalost následujících technologií: Vue, Python, PostgreSQL, MongoDB, Linux, Git. </li>
                      <li> Chuť učit se nové technologie a řešit komplexní problémy. </li>
                      <li> Samostatnost, proaktivní přístup a týmového ducha. </li>
                      <li> Základní znalost anglického jazyka. </li>
                    </ul>

                    <h4 class="title"> Co ti nabízíme: </h4>
                    <ul class="card-description">
                      <li> Práci na zajímavých projektech s vysokým dopadem na zdravotnictví. </li>
                      <li> Školení a mentoring: Rádi tě zaškolíme a poskytneme ti veškerou podporu, kterou budeš potřebovat k úspěšnému zvládnutí náročných úkolů. </li>
                      <li> Možnost profesního růstu a osobního rozvoje. </li>
                      <li> Příjemné pracovní prostředí v dynamické společnosti. </li>
                      <li> Flexibilní pracovní dobu včetně možnosti home office. </li>
                      <li> Atraktivní mzdové ohodnocení (40 000 - 80 000 Kč/měsíc). </li>
                      <li> Možnost pracovat na moderních technologiích. </li>
                      <li> Stabilní zaměstnání v rychle rostoucí společnosti. </li>
                      <li> Práce na HPP. </li>
                    </ul>

                    <h4 class="title"> Benefity: </h4>
                    <ul class="card-description">
                      <li> Macbook Pro, Mobilní telefon, tarif s neomezenými daty. </li>
                      <li> Kancelář v centru města (5 min. od vlak. nádraží), flexibilní pracovní doba. </li>
                      <li> Po zapracování možný homeoffice. </li>
                      <li> Stravenkový paušál, po roce 5 týdnů dovolené, sickdays. </li>
                    </ul>

                    <h4 class="title"> O nás: </h4>
                    <p class="card-description">
                      Jsme zavedená česká společnost specializující se na bioinformatiku. Vyvíjíme sofistikovaný software pro analýzu biologických dat, který využívají nemocnice po celé České republice. Naším cílem je přispět k rozvoji personalizované medicíny a zlepšení diagnostiky a léčby pacientů.
                    </p>

                    <h5 class="title"> Místo výkonu práce: Ústí nad Labem (po zapracování s možností home office) </h5>
                    <h5 class="title"> Nástup: Ihned nebo dle dohody </h5>
                    <h5 class="title"> Pokud tě naše nabídka zaujala, neváhej nám zaslat svůj životopis <a href="mailto:info@bioxsys.cz"> ZDE</a>. </h5>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Card,
  Button,
  InfoSection,
  FormGroupInput,
  Tabs,
  TabPane,
  Modal,
} from "@/components";

export default {
  name: "landing-page",
  bodyClass: "landing-page",
  components: {
    InfoSection,
    Card,
    Tabs,
    TabPane,
    Modal,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
      modal_broz: false,
      modal_steiger: false,
      modal_zembol: false,
      modal_vicha: false,
      modal_novakova: false,
    };
  },
};
</script>
<style>
.pad {
  padding: 0 !important;
}
.al {
  text-align: right !important;
}
</style>
