<template>

    <div class="page-header signup-page section-image">
        <div class="page-header-image"
             style="background-image: url('img/771_L_a.jpg')">
        </div>



        <div class="content-center">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto">
                            <info-section type="primary" icon="now-ui-icons location_pin">
                                <h4 class="info-title">Korespondenční adresa:</h4>
                                <p class="description text_white"> Bioxsys s.r.o.
                                    <br> Na Kopečku 15
                                    <br> Ústí nad Labem
                                    <br> 400 11
                                    <br> Česká republika
                                    <br> IČO: 08280231
                                </p>
                                </p>
                            </info-section>

                            <info-section type="primary" icon="now-ui-icons tech_mobile">
                                <h4 class="info-title">Telefon:</h4>
                                <p class="description text_white">Ing. Petr Brož</p>
                                <p class="description text_white">CEO & Bioinformatician</p>
                                <p class="description text_white">+420 604 799 725</p>
                                <p class="description text_white">Josef Steiger</p>
                                <p class="description text_white">CTO & Software Developer</p>
                                <p class="description text_white">+420 734 786 999</p>
                            </info-section>

                            <info-section type="primary" icon="now-ui-icons ui-1_email-85">
                                <h4 class="info-title">Email:</h4>
                                <p class="description text_white"><a href="mailto:info@bioxsys.cz"> info@bioxsys.cz </a>
                                <p class="description text_white"><a href="mailto:info@bioxsys.cz"> broz@bioxsys.cz </a>
                                <p class="description text_white"><a href="mailto:info@bioxsys.cz"> steiger@bioxsys.cz </a>
                                </p>
                            </info-section>
                        </div>

                        <div class="col-md-6 ml-auto mr-auto kontejner">
                            <form>
                            <card type="contact" raised header-classes="text-center">
                                <h5 slot="header" class="card-title text_black">Vyplňte formulář a my se Vám ozveme</h5>
                                <div class="row">

                                    <div class="col-md-12">
                                        <fg-input placeholder="jméno a příjmení" addon-left-icon="fas fa-user-circle" v-model="jmeno">
                                        </fg-input>
                                    </div>

                                    <div class="col-md-12">
                                        <fg-input placeholder="telefon" addon-left-icon="fas fa-bell" v-model="telefon">
                                        </fg-input>
                                    </div>

                                    <div class="form-group col-md-12">
                                        <fg-input placeholder="email ..." addon-left-icon="fas fa-envelope" v-model="myemail">
                                        </fg-input>
                                    </div>

                                    <div class="form-group col-md-12">
                                        <textarea name="message" class="form-control" rows="6" placeholder="Text zprávy:" v-model="zprava"> </textarea>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 ml-auto">
                                        <n-button type="primary" class="pull-right" @click.native="send_mail()"> Poslat zprávu </n-button>
                                    </div>
                                </div>


                            </card>
                            </form>
                        </div>


                    </div>
                </div>
        </div>



        <modal :show.sync="modal_chyba"
                class="modal-danger"
                :show-close="false"
                header-classes="justify-content-center"
                type="mini">
        <div slot="header" class="modal-profile d-flex justify-content-center align-items-center">
            <i class="fas fa-exclamation-triangle"></i>
        </div>
        <p>E-mail není ve správném formátu, ověřte laskavě formulář.</p>
        <p></p>
        <div class="col-md-2 ml-auto mr-auto">
            <n-button type="neutral" wide link @click.native="modal_chyba = false">Zavřít</n-button>
        </div>
        </modal>


        <modal :show.sync="modal_dekujeme"
                class="modal-info"
                :show-close="false"
                header-classes="justify-content-center"
                type="mini">
        <div slot="header" class="modal-profile d-flex justify-content-center align-items-center">
            <i class="fas fa-smile-beam"></i>
        </div>
        <p>Děkujeme.</p>
        <p></p>
        <div class="col-md-2 ml-auto mr-auto">
            <n-button type="neutral" wide link @click.native="modal_dekujeme = false">Zavřít</n-button>
        </div>
        </modal>

    </div>
</template>

<script>
  import { Card, Button, InfoSection, FormGroupInput, Checkbox, Modal } from '@/components';
  import { Carousel, CarouselItem } from 'element-ui';
  import axios from 'axios';
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';


  export default {
    components: {
      Card,
      InfoSection,
      [Button.name]: Button,
      [Checkbox.name]: Checkbox,
      [FormGroupInput.name]: FormGroupInput,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Modal.name]: Modal,
    },
    data() {
      return {
        kdy: "",
        jmeno: "",
        telefon: "",
        myemail: "",
        zprava: "",
        modal_chyba: false,
        modal_dekujeme: false
      }
    },


    computed: {
        ...mapState({


        }),

        ...mapGetters({


        }),


    },


    methods: {

      ...mapMutations({
        m_kurzy: 'm_kurzy',


      }),


      ...mapActions({
        a_kontaktujte: 'a_kontaktujte',
      }),





        send_mail: function() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.myemail)) {
                this.a_kontaktujte( {
                    kdy: this.kdy,
                    jmeno: this.jmeno,
                    telefon: this.telefon,
                    myemail: this.myemail,
                    zprava: this.zprava
                    } )

                    this.kdy = "";
                    this.jmeno = "";
                    this.telefon = "";
                    this.myemail = "";
                    this.zprava = "";
                    this.modal_dekujeme = true;
            }
            else {
                this.modal_chyba = true;
            }
        }

    }



  }
</script>
<style>
.text_black {
    color: black !important;
    font-weight: 600 !important;
    padding-top: 30px !important;
}

.text_white {
    color: white !important;
    font-weight: 500 !important;

}

.kontejner {

    border-radius: 0px !important;
    padding-top: 50px !important;
}

</style>
