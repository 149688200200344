<template class="fl">
  <div class="wrapper landing-page">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/p_onas.jpg')"
      >
      </parallax>
      <div class="content-center">
        <h1 class="title">O nás</h1>
        <h3 class="title">Kdo stojí za společností a dává naší práci smysl</h3>

        <!-- <div class="text-center">
          <a
            href="https://www.facebook.com/groups/1016610115019629/"
            class="btn btn-primary btn-icon btn-round"
          >
            <i class="fab fa-facebook-square"></i>
          </a>
          <a
            href="https://twitter.com/BioinformatikC"
            class="btn btn-primary btn-icon btn-round"
          >
            <i class="fab fa-twitter"></i>
          </a>
        </div> -->
      </div>
    </div>

    <div class="section section-about-us pad">
      <div class="team-4">
        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div slot="raw-content" class="row">
                <div class="col-md-8">
                  <div class="card-body">
                    <h3 class="card-title al">Ing. Petr Brož</h3>
                    <h6 class="category text-primary al">CEO & Bioinformatik</h6>
                    <p class="card-description al">
                      Vystudoval jsem VUT v Brně obor Biomedicínské inženýrství
                      a Bioinformatika. Po škole jsem nastoupil do firmy, která
                      byla výhradním distributorem společnosti Illumina a díky
                      této skutečnosti jsem měl tu čest být u vzniku NGS v České
                      republice a získat tak bohaté zkušenosti s analýzou a
                      interpretací NGS dat. Jsem autorem softwarové NGS
                      platformy FinalistDX, která je stále využíváná v mnoha
                      diagnostických laboratořích. <br />
                      Moje motto zní:
                    </p>
                    <p class="card-description text-right text-primary">
                      <i
                        >„Nechtěj být člověkem, který je úspešný, ale člověkem,
                        který za něco stojí.“</i
                      >
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="card-image">
                    <img class="img" src="img/broz_01.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-12 col-lg-12 mt-5">
              <div slot="raw-content" class="row">
                <div class="col-md-3">
                  <div class="card-image">
                    <img class="img" src="img/pepa_01.jpg" />
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="card-body">
                    <h3 class="card-title">Josef Steiger</h3>
                    <h6 class="category text-primary">CTO & Senior Software developer</h6>
                    <p class="card-description">
                      Drobná spojnice mezi experty z fascinujícího oboru a
                      jejich sympatizanty. Díky schopnosti dát komunikaci
                      prostředí a nástroje mohu posouvat úroveň jejich konání
                      dál, což je primárním smyslem mé činnosti. Současně se
                      nesmírně bavím svou prací, jelikož vyvíjet rozsáhlé
                      systémy v Pythonu a Vue.JS prostě nic jiného než zábava
                      není.
                    </p>
                    <p class="card-description text-primary">
                      <i
                        >„Uprostřed každého problému se nachází příležitost.“
                        <br /><small>Albert Einstein</small></i
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Card,
  Button,
  InfoSection,
  FormGroupInput,
  Tabs,
  TabPane,
  Modal,
} from "@/components";

export default {
  name: "landing-page",
  bodyClass: "landing-page",
  components: {
    InfoSection,
    Card,
    Tabs,
    TabPane,
    Modal,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
      modal_broz: false,
      modal_steiger: false,
      modal_zembol: false,
      modal_vicha: false,
      modal_novakova: false,
    };
  },
};
</script>
<style>
.pad {
  padding: 0 !important;
}
.al {
  text-align: right !important;
}
</style>
