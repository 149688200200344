 <template>
    <div class="wrapper landing-page">
        <div class="page-header page-header-small">
            <parallax class="page-header-image"
                 style="background-image: url('img/960_M.jpg')">
            </parallax>
            <div class="content-center">
                <h1 class="title main_size">Bioinformatické služby na míru Vašim požadavkům</h1>
                <h1 class="title"></h1>

                <div class="text-center">
                    <n-button type="button" icon round class="go_down" @click.native="handleScroll">
                        <i class="fas fa-arrow-down"></i>
                    </n-button>  
                </div>
            
            </div>
         
        </div> 

        <div class="features-1">
            <div class="container">
                <div class="row">
                <div class="row">
                    <div class="col-md-12 ml-auto mr-auto">
                        <!-- <h2 class="title">Intenzivní týdenní pobytový kurz Python & Bioinformatika</h2> -->
                        <h4 class="description text-black">S klesající cenou za sekvenaci rapidně roste množství genetických dat, kvůli tomu jsou kladeny nejenom vyšší nároky na výpočetní sílu, ale také na přesnou analýzu a interpretaci výsledků. 
                            Náš tým bioinformatiků s mnohaletou zkušeností Vám pomůžou tyto nesnáze překonat.</h4>
                    </div>
                </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="info info-hover">
                            <div class="icon icon-dark">
                                <i class="fas fa-dna"></i>
                            </div>
                            <h4 class="info-titlex">DNA-seq</h4>
                            <p class="description text-black">Analýza dat od malých sekvenačních panelů až po celoexomová (WES) a celogenomová (WGS) včetně anotace, filtrace, vizualizace a interpretace variant (somatických i zárodečných).</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="info info-hover">
                            <div class="icon icon-primary">
                                <i class="fas fa-dna"></i>
                            </div>
                            <h4 class="info-titlex">RNA-seq</h4>
                            <p class="description text-black">Kvantifikace a diferenciální exprese transkriptů včetně anotace a stanovení Genome Ontology. Analýza fúzních genů a hledání variant v jednotlivých transkriptech.</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="info info-hover">
                            <div class="icon icon-primary">
                                <i class="fas fa-bacon"></i>
                            </div>
                            <h4 class="info-titlex">Detekce fúsních genů</h4>
                            <p class="description text-black">Sekvenace RNA-seq dat a následné mapování k referenčnímu transkriptomu s následnou detekcí fúsních genů včetně grafického vyjadření dané fúze. Ukázka grafu fúze <a class="hesla" href="https://data.bioxsys.cz/web/fuzni_geny_example.pdf" target="_new">zde.</a> </p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="info info-hover">
                            <div class="icon icon-primary">
                                <i class="fas fa-chart-bar"></i>
                            </div>
                            <h4 class="info-titlex">Metylace - WGBS</h4>
                            <p class="description text-black">Bisulfidové sekvenování a následná analýza diferencálně metylovaných regionů napříč celým genomem. Vizualizace aktivovaných a inaktivovaných regionů v promotorech (TSS) nebo unvitř CpG ostrůvků.</p>
                        </div>
                    </div>
                                        <div class="col-md-4">
                        <div class="info info-hover">
                            <div class="icon icon-primary">
                                <i class="fas fa-sitemap"></i>
                            </div>
                            <h4 class="info-titlex">Metagenomika - 16S rRNA</h4>
                            <p class="description text-black">Analýza mikrobiomu, jeho přiřazení a klasifikace do jednotlivých skupin na základě referenčního genomu včetně určení poměru jednotlivých mikrobů.</p>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="info info-hover">
                            <div class="icon icon-primary">
                                <i class="fas fa-baby"></i>
                            </div>
                            <h4 class="info-titlex">NIPT analýza</h4>
                            <p class="description text-black">Analýza chromosomalních aberací pomocí metody NIPT snižuje nutnost invazivního testování a poskytuje klinické informace o aneuploidiich napříč genomem, včetně detekce pohlaví a fetální frakce dle norem SLG.</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="info info-hover">
                            <div class="icon icon-primary">
                                <i class="fas fa-bacteria"></i>
                            </div>
                            <h4 class="info-titlex">De-novo assembly</h4>
                            <p class="description text-black">De-novo assembly virů, bakterí i rostlin včetně skládání kontigů do scaffoldů a následnou anotaci dané sekvence.</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="info info-hover">
                            <div class="icon icon-primary">
                                <i class="fas fa-walking"></i>
                            </div>
                            <h4 class="info-titlex">Rekreační genetika</h4>
                            <p class="description text-black">Návrhy a optimalizace genotipizačních panelů a následná bioinformatická analýza (SNP, INDEL a CNV) zahrnující stanovení genotypu.</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="info info-hover">
                            <div class="icon icon-primary">
                                <i class="fas fa-users"></i>
                            </div>
                            <h4 class="info-titlex">Specializované analýzy</h4>
                            <p class="description text-black">TRIO analýzy, Copy Number Variation (CNV), genotypizace, Tumor Burden Mutation (TMB), Mikrosatelitní nestabilita (MSI), design sekvenačních experimentů a další.</p>
                        </div>
                    </div>
                                        <div class="col-md-4">
 <!--                        <div class="info info-hover">
                            <div class="icon icon-primary">
                                <i class="fas fa-dna"></i>
                            </div>
                            <h4 class="info-titlex">Návrhy panelů</h4>
                            <p class="description text-black">.</p>
                        </div> -->
                    </div>
                </div>                
            </div>
        </div>


    </div>
</template>
<script>
  import { Card, Button, FormGroupInput, Tabs, TabPane, Badge, InfoSection } from '@/components';

  export default {
    name: 'landing-page',
    bodyClass: 'landing-page',
    components: {
      Card,
      Tabs,
      TabPane,
      Badge,
      InfoSection,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput
    },
    data() {
      return {
        form: {
          firstName: '',
          email: '',
          message: ''
        }
      }
    },

    methods: {
    handleScroll () {
       window.scrollTo({top: 900, left: 0, behavior: 'smooth'});
     },
    }, 
  
  }
</script>
<style>
.card-description {
    color: black !important;
    font-weight: 500 !important;
}

.text-white {
    font-weight: 500 !important;
}


@media only screen and (max-width: 500px) {
  .main_size {
    font-size: 35px !important;
    font-weight: 1000 !important;
  }
}

.text-black {
    color: black !important;
    font-weight: 500 !important;
}

.card-profile {
    margin-top: 5px !important;
}

.go_down {
    font-weight: 800 !important;
    margin-top: 15px !important;
    background-color: orangered !important;
}

</style>
