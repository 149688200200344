<template>
  <div class="wrapper landing-page">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/IMG_4494.jpeg')"
      >
      </parallax>
      <div class="content-center">
        <h1 class="title title_text">
          Specializované bioinformatické kurzy<br />
          prezenční i on-line <br />
          navíc s dotací od MPSV.
        </h1>
        <el-carousel
          :interval="10000"
          height="140px"
          direction="vertical"
          :autoplay="true"
        >
          <el-carousel-item v-for="item in pararusel" :key="item">
            <h3 class="medium"><span v-html="item"></span></h3>
          </el-carousel-item>
        </el-carousel>

        <div class="text-center">
          <n-button
            type="button"
            icon
            round
            class="go_down"
            @click.native="handleScroll"
          >
            <i class="fas fa-arrow-down"></i>
          </n-button>
        </div>
      </div>
    </div>

    <div class="features-1">
      <div class="container">
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <h2 class="title">Pořádáme odborné kurzy bioinformatiky <br /> a zpracování bioinformatických dat</h2>
            <h3 class="title text-primary">
              Linuxu, Bioinformatika, Zpracování NGS dat a statistika v Pythonu
            </h3>
            <h4 class="description text-black">
              Pracujemě s různorodými reálnými daty. Veškeré poznatky a vytvořený kód tak můžete snadno použít i na vaše data.
            </h4>
            <h4 class="description text-black">
              Určeno pro biologicky zaměřené obory, začátečníky v bioinformatice a pro ty, kteří si chtějí osvojit analýzu NGS dat a
              nabyté znalosti aplikovat na svých pracovištích. Výuka probíhá v
              českém jazyce.
            </h4>

            <h4 class="description text-black">
              Našim zákazníkům, kteří využívají dotační prostředky, nabízíme možnost zakoupit vouchery, které lze uplatnit na další školení.
              Každý voucher má hodnotu jednoho přihlášeného účastníka na jeden den školení a jeho platnost je jeden rok.
              Vouchery lze snadno využít prostřednictvím našeho registračního systému, kde při registraci stačí zvolit platbu přes voucher.
              Tímto způsobem Vám poskytujeme flexibilní nástroj pro efektivní plánování a využití vzdělávacích prostředků.
            </h4>

            <h4 class="description text-black">
              Podpora MPSV / "Kurzy digitálního vzdělávání" <br /> Lze získat příspěvek dle podmínek programu <a href="https://www.mpsv.cz/jsem-v-kurzu" target="_new"> "Jsem v kurzu"</a> až 82 % ceny kurzu.
            </h4>
            <h4 class="description text-black">
              V průběhu kurzu máte příležitost využít volný čas na konzultace se zkušenými bioinformatiky.
            </h4>
            <h4 class="description text-black">
              Jsme tým programátorů a bioinformatiků s mnohaletou praxí se
                  zaměřením na analýzu dat lidského genomu včetně klinické
                  interpretace. Klademe důraz na praktické demonstrace a
                  možnosti vyzkoušení všech potřebných kroků.
            </h4>
            <h4 class="description text-black">
              Vlastní zařízení není potřeba. Technické zázemí bude zajištěno během celého kurzu.
            </h4>

          </div>
        </div>

        <div class="features-4">
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <div
                  class="card card-background card-raised"
                  style="background-image: url('img/skoleni_01.jpg')"
                  data-background-color
                >
                  <div class="info">
                    <div class="icon">
                      <i class="fas fa-dna white"></i>
                    </div>
                    <div class="description">
                      <h4 class="title">Bioinformatika</h4>
                      <p class="white">
                        Kompletní analýza DNA-seq sekvenačních dat od kontroly
                        kvality sekvenování až po hledání variant a jejich
                        následnou anotaci včetně klinické interpretace.<br /><br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  class="card card-background card-raised"
                  style="background-image: url('img/skoleni_02.jpg')"
                  data-background-color
                >
                  <div class="info">
                    <div class="icon">
                      <i class="fab fa-python white"></i>
                    </div>
                    <div class="description">
                      <h4 class="title">Python</h4>
                      <p class="white">
                        Python je jeden ze základních programovacích jazyků v
                        bioinformatice. Naučíte se nejen syntaxi jazyka, ale
                        především objektově uvažovat a programovat.<br /><br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  class="card card-background card-raised"
                  style="background-image: url('img/skoleni_03.jpg')"
                  data-background-color
                >
                  <div class="info">
                    <div class="icon">
                      <i class="fas fa-comments white"></i>
                    </div>
                    <div class="description">
                      <h4 class="title">Výměna zkušeností</h4>
                      <p class="white">
                        V průběhu kurzu máte příležitost využít volný
                        čas na konzultace se zkušenými bioinformatiky či kolegy z oboru.
                        Potkejte se s lidmi, se kterými si budete rozumět. A to nejen v učebně, ale také při legendárních večerních disputacích.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section section-about-us">
          <div class="container">
            <div class="row">
              <div class="col-md-12 ml-auto mr-auto text-center">
                <h2 class="title"> Seznam kurzů </h2>

                <el-collapse v-model="ktery_kurz" accordion>
                  <el-collapse-item v-for="one in kurzy" title="" :name="one.id_course_type">
                    <template slot="title">
                      <h4 class=""> <b> {{ one.course_type_title }} </b> ({{ one.course_type_name }})</h4>
                    </template>

                    <h4 class="description text-black">  </h4>
                    <div v-html="one.course_type_perex" />

                    <h3 class="description text-black"> OSNOVA </h3>
                    <div v-html="one.course_type_outline" />


                  </el-collapse-item>

                </el-collapse>


              </div>
            </div>

            <div class="container">
              <div class="row">
                <div class="col-md-6">

                </div>
                <div class="col-md-6">

                </div>
              </div>
            </div>
          </div>
        </div>


    <div class="">
      <div class="">
        <div class="container">
          <div class="row">
            <div class="col-md-10 ml-auto mr-auto text-center">
              <h2 class="title">Termíny kurzů</h2>
              <div class="description text-black">

                <el-calendar>
                  <template
                    slot="dateCell"
                    slot-scope="{date, data}"
                  >
                    <p :class="">
                      {{ data.day.split('-').slice(1).reverse().join('. ') }}.
                    </p>
                    <div v-if="Object.keys(terminy_kurzu).includes(data.day)">
                      <el-popover
                        v-for="one in terminy_kurzu[data.day]"
                        placement="right"
                        title="Detaily kurzu"
                        width="700"
                        trigger="hover"
                        >
                        <template slot="default" slot-scope="scope">
                          <p><b> {{ one.course_type_title }} </b></p>
                          <p v-if="one.classroom"> učebna Ústí nad Labem </p>
                          <p v-else> on-line na Teamsech </p>
                          <p><b>Volných míst: {{ one.available - one.reservations }} </b></p>
                          <p><b>Cena kurzu (bez DPH / s DPH): {{ one.course_type_days * 3500 }} / {{ (one.course_type_days * 3500) * 1.21 }} </b></p>

                        </template>
                        <el-button slot="reference" slot-scope="scope" style="width: 100%" :style="{ 'background-color': one.course_type_color }" @click="click_calendar(one.id_course)"> {{ one.course_type_name }} </el-button>
                      </el-popover>


                    </div>
                  </template>
                </el-calendar>

              </div>
              <p class="title"></p>
            </div>
          </div>

        </div>
      </div>
    </div>



        <card class="text-center bl_card">
          <div class="pricing-2 mala_mezera_nad">
            <div class="pricing-2 mala_mezera_nad" id="pricing-2">
              <div class="container">
                <div class="row">
                  <div class="col-md-12 ml-auto mr-auto text-center">
                    <h2 class="title"> Základní organizační pokyny </h2>

                    <h5 class="description text-black">
                      Nejpozději 14 dní před zahájením je kurz potvrzen nebo zamítnut, pokud není naplněna minimální kapacita kurzu. V tento den též obdržíte výzvu k úhradě ceny za kurz a podrobné informace. Potvrzení kurzu provedeme jakmile bude naplnena minimální kapacita pro konání, abyste si mohli s předstihem zajistit ubytování.
                    </h5>

                    <h5 class="description text-black">
                      ON-SITE kurz začíná první den v 9:30 nebo dle dohody účastníků s lektorem. <br  />
                      Výuka probíhá od 9:00 do 12:00 a od 13:00 do 16:00.
                    </h5>

                    <h5 class="description text-black">
                      Cena kurzu obsahuje voucher na oběd - doporučujeme navštívit společně
                      <a href="https://pivovarskasenkovna.cz/" target="_new"> Pivovarskou šenkovnu </a> nebo
                      <a href="https://restauracetyrsovka.cz/" target="_new"> Tyršovku. </a>

                    </h5>

                    <h5 class="description text-black">
                      UBYTOVAT se můžete v
                      <a href="https://www.cmyk-hostel.cz/cs/" target="_new"> Hostelu CMYK </a> (1 minuta od učebny),
                      <a href="https://www.pivovarnarychte.cz/" target="_new"> Hotelu na Rychtě </a> (cca. 5 min. cesty od učebny) nebo
                      <a href="https://www.penzionkorida.cz/cs/" target="_new"> Penzionu Korida </a> (cca. 5 min. cesty od učebny)
                    </h5>

                    <h5 class="description text-black">
                      Nudit se nebudete ani po skončení výuky. Učebna, vč. posezení na střeše, je vám otevřena až do večera. <br />
                      V blízkých <a href="https://www.msul.cz/mestske-lazne/mestske-lazne-bazen/" target="_new"> Městských lázních </a> je k dispozici bazén, sauna i pára.<br />
                      Cyklonadšenci si mohou projet <a href="https://strednicechy.rozhlas.cz/labska-cyklostezka-vede-od-pramene-labe-do-hamburku-cestou-nabizi-spoustu-8513606" target="_new"> labskou cyklostezku </a> až do Hamburku, pokud se stihnou vrátit na další výuku. Vaše kolo si můžete uschovat u nás nebo si lze zapůjčit v <a href="https://www.msul.cz/cyklocentrum/" target="_new"> Cyklocentru </a>.
                    </h5>
                    <h5 class="description text-black">
                      Pokud jedete autem, lze parkovat v podzemí v <a href="https://www.google.com/maps/place/Podzemn%C3%AD+gar%C3%A1dar%C5%BEe+-+Zan%C3%A1dra%C5%BE%C3%AD/@50.659264,14.0449331,19.64z/data=!4m6!3m5!1s0x470984aa1afda97f:0xcd8cd1bfe68d5488!8m2!3d50.6597033!4d14.0457861!16s%2Fg%2F11c6cc2dqh?authuser=0&entry=ttu" target="_new"> Zanádraží </a> (hlídané, cena 100 CZK / den) nebo v parkovacím domě <a href="https://www.google.com/maps/place/Gar%C3%A1%C5%BEe+Centrum/@50.6605389,14.0456347,18.62z/data=!4m6!3m5!1s0x470983545a32116b:0xb9805859d58b9c71!8m2!3d50.6611955!4d14.0464834!16s%2Fg%2F12qgx_7hj?authuser=0&entry=ttu" target="_new"> Garáže Centrum </a> (80 CZK / den).

                    </h5>

                  </div>
                </div>
                <div class="row" height="10">
                  <div class="col-md-12 ml-auto mr-auto text-center"></div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <card type="pricing" plain>


                    </card>
                  </div>
                </div>

              </div>


            </div>
          </div>
        </card>


        <div class="col-md-12 mt-12">
          <card type="profile" plain>
            <img
              slot="avatar"
              class="img img-raised"
              src="img/zembol_02.jpg"
            />

            <h3 class="jmeno">Ing. Filip Zembol</h3>
            <h6 class="category text-primary"> Vedoucí vzdělávací sekce </h6>
            <p class="card-description">
              Jakékoli dotazy ohledně kurzů vám rád zodpovím osobně na tel. +420 608 243 153
            </p>
            <div class="card-footer">

            </div>
          </card>
        </div>

        <div class="row" v-if="detail_kurzu">
          <template slot="footer">
            <n-button type="danger" @click.native="modals.akce = false"
              >Back</n-button
            >
          </template>
        </div>
      </div>
    </div>



    <modal :show.sync="modals.rezervace"
      class="modal-default"
      :show-close="true"
      header-classes="justify-content-center"
      type="mini"
      >
      <div
        slot="header"
        class="modal-profile d-flex justify-content-center align-items-center"
      >
        <i class="fas fa-smile-beam"></i>
      </div>
      <p>Vybrali jste si kurz</p>
      <p> <b>{{ vybrany_kurz.course_type_name }} / {{ vybrany_kurz.course_type_title }} v termínu {{ moment(vybrany_kurz.date_start).format('LL') }} - {{ moment(vybrany_kurz.date_end).format('LL') }} {{ vybrany_kurz.classroom ? " v učebně v Ústí nad Labem." : " ve verzi ON LINE." }} </b> </p>

      <p>Po vyplnění a odeslání formuláře zaevidujeme Vaší rezervaci a obratem obdržíte potvrzovací e-mail. V něm najdete tlačítko pro ověření a potvrzení registrace. Kliknutím na něj bude rezervace potvrzena a místo na kurzu bude pro Vás zajištěno.</p>
      <p>Konání kurzu bude potvrzeno 14 dní před jeho zahájením, kdy obdržíte výzvu k úhradě na částku, kterou budete hradit Vy. <br />Do té doby je nutné doručit případné potvrzení výše dotace ze strany MPSV. Jinak bude ve výzvě požadována plná cena kurzu.</p>
      <p>Úhrada kurzu z Vaší strany a potvrzení výše dotace ze strany MPSV jsou nutnou podmínkou pro účast na kurzu. <br /></p>

      <p><b>Těšíme se na Vás.</b></p>
      <p> <br /> <br /> </p>

      <el-form ref="form_rezervace" :model="form_rezervace" :rules="rules_rezervace" label-width="180px">
        <el-row class="box-card-modal-01" style="margin: 0px">
          <el-col :span="24" style="margin-bottom: 10px">
            <p > <b> Rezervační údaje </b> </p>
          </el-col>

          <el-col :span="24">
            <el-row :gutter="10" style="margin: 0px">
              <el-col :span="12" style="">
                <el-form-item label="Firma / Organizace:">
                  <el-input size="mini" v-model="form_rezervace.i_company"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6" style="">
                <el-form-item label="IČ:">
                  <el-input size="mini" v-model="form_rezervace.i_companynum"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6" style="">
                <el-form-item label="DIČ:">
                  <el-input size="mini" v-model="form_rezervace.i_vatnum"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12" style="">
                <el-form-item label="Jméno:" prop="i_fn">
                  <el-input size="mini" v-model="form_rezervace.i_fn"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" style="">
                <el-form-item label="Příjmení:" prop="i_sn">
                  <el-input size="mini" v-model="form_rezervace.i_sn"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12" style="">
                <el-form-item label="Ulice:" prop="i_street1">
                  <el-input size="mini" v-model="form_rezervace.i_street1"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" style="">
                <el-form-item label="Doplňující info:">
                  <el-input size="mini" v-model="form_rezervace.i_street2"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12" style="">
                <el-form-item label="Město:" prop="i_city">
                  <el-input size="mini" v-model="form_rezervace.i_city"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6" style="">
                <el-form-item label="PSČ:" prop="i_zip">
                  <el-input size="mini" v-model="form_rezervace.i_zip"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6" style="">
                <el-form-item label="Země:">
                  <el-input size="mini" v-model="form_rezervace.i_country"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8" style="">
                <el-form-item label="E-mail:" prop="i_email">
                  <el-input size="mini" v-model="form_rezervace.i_email"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" style="">
                <el-form-item label="Telefon:" prop="i_phone">
                  <el-input size="mini" v-model="form_rezervace.i_phone"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" style="">
                <el-form-item label=" Budete čerpat příspěvek od MPSV?:" label-width="340px">
                  <el-checkbox :disabled="form_rezervace.i_voucher" v-model="form_rezervace.i_mpsv" @change="change_i_mpsv"> </el-checkbox>
                </el-form-item>
              </el-col>

              <el-col :span="4" style="">
                <el-form-item v-if="!form_rezervace.i_mpsv" label="Uhradit kurz vouchery?:" label-width="250px">
                  <el-checkbox v-model="form_rezervace.i_voucher"> </el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="4" style="">
                <el-form-item v-if="!form_rezervace.i_mpsv && form_rezervace.i_voucher" label="V 1:" label-width="100px" prop="i_voucher_1">
                  <el-input size="mini" v-model="form_rezervace.i_voucher_1"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4" style="">
                <el-form-item v-if="!form_rezervace.i_mpsv && form_rezervace.i_voucher && vybrany_kurz.course_type_days > 1" label="V 2:" label-width="100px" prop="i_voucher_2">
                  <el-input size="mini" v-model="form_rezervace.i_voucher_2"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4" style="">
                <el-form-item v-if="!form_rezervace.i_mpsv && form_rezervace.i_voucher && vybrany_kurz.course_type_days > 2" label="V 3:" label-width="100px" prop="i_voucher_3">
                  <el-input size="mini" v-model="form_rezervace.i_voucher_3"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4" style="">
                <el-form-item v-if="!form_rezervace.i_mpsv && form_rezervace.i_voucher && vybrany_kurz.course_type_days > 3" label="V 4:" label-width="100px" prop="i_voucher_4">
                  <el-input size="mini" v-model="form_rezervace.i_voucher_4"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4" style="">
                <el-form-item v-if="!form_rezervace.i_mpsv && form_rezervace.i_voucher && vybrany_kurz.course_type_days > 4" label="V 5:" label-width="100px" prop="i_voucher_5">
                  <el-input size="mini" v-model="form_rezervace.i_voucher_5"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24" style="">
                <el-form-item label="Poznámka:">
                  <el-input type="textarea" :rows="6" size="mini" v-model="form_rezervace.i_note"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <br />
                <el-button type="success" style="width: 100%" @click.native="click_rezervovat()" > Závazně rezervovat místo na kurzu </el-button>
              </el-col>

            </el-row>
          </el-col>
        </el-row>


      </el-form>


      <div class="col-md-2 ml-auto mr-auto">
        <n-button
          type="neutral"
          wide
          link
          @click.native="modals.dekujeme = false"
          >Zavřít</n-button
        >
      </div>
    </modal>


    <modal
      :show.sync="modals.mini"
      class="modal-success"
      :show-close="false"
      header-classes="justify-content-center"
      type="mini"
      >
      <div
        slot="header"
        class="modal-profile d-flex justify-content-center align-items-center"
      >
        <i class="now-ui-icons users_circle-08"></i>
      </div>
      <p>Děkujeme za odeslání, brzy se ozveme.</p>
      <template slot="footer">
        <n-button type="neutral" link @click.native="modals.mini = false"
          >Close</n-button
        >
      </template>
    </modal>


    <modal
      :show.sync="modals.chyba"
      class="modal-danger"
      :show-close="false"
      header-classes="justify-content-center"
      type="mini"
      >
      <div
        slot="header"
        class="modal-profile d-flex justify-content-center align-items-center"
      >
        <i class="fas fa-exclamation-triangle"></i>
      </div>
      <p>E-mail není ve správném formátu, ověřte laskavě formulář.</p>
      <p></p>
      <div class="col-md-2 ml-auto mr-auto">
        <n-button type="neutral" wide link @click.native="modals.chyba = false"
          >Zavřít</n-button
        >
      </div>
    </modal>


    <modal
      :show.sync="modals.dekujeme"
      class="modal-info"
      :show-close="false"
      header-classes="justify-content-center"
      type="mini"
      >
      <div
        slot="header"
        class="modal-profile d-flex justify-content-center align-items-center"
      >
        <i class="fas fa-smile-beam"></i>
      </div>
      <p>Děkujeme za objednávku.</p>
      <p>Spojíme se s Vámi, abychom domluvili ubytování.</p>
      <p>Těšíme se na Vás.</p>
      <p></p>
      <div class="col-md-2 ml-auto mr-auto">
        <n-button
          type="neutral"
          wide
          link
          @click.native="modals.dekujeme = false"
          >Zavřít</n-button
        >
      </div>
    </modal>


  </div>
</template>

<script>
import {
  Card,
  Button,
  FormGroupInput,
  Tabs,
  TabPane,
  Badge,
  InfoSection,
  Modal,
} from "@/components";
import {
  Input,
  Select,
  Option,
  Carousel,
  CarouselItem,
  Table,
  TableColumn,
  Calendar,
  Row,
  Col,
  Form,
  FormItem,
} from "element-ui";

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import axios from "axios";
import moment from 'moment';
moment.locale("cs");

export default {
  name: "landing-page",
  bodyClass: "landing-page",
  components: {
    Card,
    Tabs,
    TabPane,
    Badge,
    InfoSection,
    Calendar,
    Row,
    Col,
    Form,
    FormItem,
    Input,
    [Modal.name]: Modal,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },


  data() {
      var val_voucher_1 = (rule, value, callback) => {
        if (this.form_rezervace.i_voucher && this.vybrany_kurz.course_type_days > 0 && (value == undefined || value == "")) {
          callback(new Error('Nezadán voucher'))
        } else if (this.form_rezervace.i_voucher && this.vybrany_kurz.course_type_days > 0 && !(value == undefined || value == "") && value !== this.form_rezervace.i_voucher_2 && value !== this.form_rezervace.i_voucher_3 && value !== this.form_rezervace.i_voucher_4 && value !== this.form_rezervace.i_voucher_5 ) {
          axios.patch(process.env.VUE_APP_BE + '/vouchery', { voucher: value }, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8' } })
            .then(response => {
              if (response.data["voucher_ok"]) {
                callback();
              } else {
                callback(new Error('Neplatný voucher'));
              };
            }, error => {
              console.log(error);
              callback(new Error('Neplatný voucher'));
            });

        } else if (this.form_rezervace.i_voucher && this.vybrany_kurz.course_type_days > 0 && !(value == undefined || value == "") && (value === this.form_rezervace.i_voucher_2 || value === this.form_rezervace.i_voucher_3 || value === this.form_rezervace.i_voucher_4 || value === this.form_rezervace.i_voucher_5 )) {
          callback(new Error('Duplicitní vouchery'));

        } else {
          callback();
        }
      };


      var val_voucher_2 = (rule, value, callback) => {
        if (this.form_rezervace.i_voucher && this.vybrany_kurz.course_type_days > 1 && (value == undefined || value == "")) {
          callback(new Error('Nezadán voucher'))
        } else if (this.form_rezervace.i_voucher && this.vybrany_kurz.course_type_days > 1 && !(value == undefined || value == "") && value !== this.form_rezervace.i_voucher_1 && value !== this.form_rezervace.i_voucher_3 && value !== this.form_rezervace.i_voucher_4 && value !== this.form_rezervace.i_voucher_5 ) {
          axios.patch(process.env.VUE_APP_BE + '/vouchery', { voucher: value }, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8' } })
            .then(response => {
              if (response.data["voucher_ok"]) {
                callback();
              } else {
                callback(new Error('Neplatný voucher'));
              };

            }, error => {
              console.log(error);
              callback(new Error('Neplatný voucher'));
            });
        } else if (this.form_rezervace.i_voucher && this.vybrany_kurz.course_type_days > 1 && !(value == undefined || value == "") && (value === this.form_rezervace.i_voucher_1 || value === this.form_rezervace.i_voucher_3 || value === this.form_rezervace.i_voucher_4 || value === this.form_rezervace.i_voucher_5 )) {
          callback(new Error('Duplicitní vouchery'));
        } else {
          callback();
        }
      };


      var val_voucher_3 = (rule, value, callback) => {
        if (this.form_rezervace.i_voucher && this.vybrany_kurz.course_type_days > 2 && (value == undefined || value == "")) {
          callback(new Error('Nezadán voucher'))
        } else if (this.form_rezervace.i_voucher && this.vybrany_kurz.course_type_days > 2 && !(value == undefined || value == "") && value !== this.form_rezervace.i_voucher_1 && value !== this.form_rezervace.i_voucher_2 && value !== this.form_rezervace.i_voucher_4 && value !== this.form_rezervace.i_voucher_5 ) {
          axios.patch(process.env.VUE_APP_BE + '/vouchery', { voucher: value }, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8' } })
            .then(response => {
              if (response.data["voucher_ok"]) {
                callback();
              } else {
                callback(new Error('Neplatný voucher'));
              };
            }, error => {
              console.log(error);
              callback(new Error('Neplatný voucher'));
            });
        } else if (this.form_rezervace.i_voucher && this.vybrany_kurz.course_type_days > 2 && !(value == undefined || value == "") && (value === this.form_rezervace.i_voucher_1 || value === this.form_rezervace.i_voucher_2 || value === this.form_rezervace.i_voucher_4 || value === this.form_rezervace.i_voucher_5 )) {
          callback(new Error('Duplicitní vouchery'));
        } else {
          callback();
        }
      };


      var val_voucher_4 = (rule, value, callback) => {
        if (this.form_rezervace.i_voucher && this.vybrany_kurz.course_type_days > 3 && (value == undefined || value == "")) {
          callback(new Error('Nezadán voucher'))
        } else if (this.form_rezervace.i_voucher && this.vybrany_kurz.course_type_days > 3 && !(value == undefined || value == "") && value !== this.form_rezervace.i_voucher_1 && value !== this.form_rezervace.i_voucher_2 && value !== this.form_rezervace.i_voucher_3 && value !== this.form_rezervace.i_voucher_5 ) {
          axios.patch(process.env.VUE_APP_BE + '/vouchery', { voucher: value }, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8' } })
            .then(response => {
              if (response.data["voucher_ok"]) {
                callback();
              } else {
                callback(new Error('Neplatný voucher'));
              };
            }, error => {
              console.log(error);
              callback(new Error('Neplatný voucher'));
            });
        } else if (this.form_rezervace.i_voucher && this.vybrany_kurz.course_type_days > 3 && !(value == undefined || value == "") && (value === this.form_rezervace.i_voucher_1 || value === this.form_rezervace.i_voucher_2 || value === this.form_rezervace.i_voucher_3 && value === this.form_rezervace.i_voucher_5 )) {
          callback(new Error('Duplicitní vouchery'));
        } else {
          callback();
        }
      };


      var val_voucher_5 = (rule, value, callback) => {
        if (this.form_rezervace.i_voucher && this.vybrany_kurz.course_type_days > 4 && (value == undefined || value == "")) {
          callback(new Error('Nezadán voucher'))
        } else if (this.form_rezervace.i_voucher && this.vybrany_kurz.course_type_days > 4 && !(value == undefined || value == "") && value !== this.form_rezervace.i_voucher_1 && value !== this.form_rezervace.i_voucher_2 && value !== this.form_rezervace.i_voucher_3 && value !== this.form_rezervace.i_voucher_4 ) {
          axios.patch(process.env.VUE_APP_BE + '/vouchery', { voucher: value }, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8' } })
            .then(response => {
              if (response.data["voucher_ok"]) {
                callback();
              } else {
                callback(new Error('Neplatný voucher'));
              };
            }, error => {
              console.log(error);
              callback(new Error('Neplatný voucher'));
            });
        } else if (this.form_rezervace.i_voucher && this.vybrany_kurz.course_type_days > 4 && !(value == undefined || value == "") && (value === this.form_rezervace.i_voucher_1 || value === this.form_rezervace.i_voucher_2 || value === this.form_rezervace.i_voucher_3 || value === this.form_rezervace.i_voucher_4 )) {
          callback(new Error('Duplicitní vouchery'));
        } else {
          callback();
        }
      };


    return {
      ktery_kurz: "",
      vybrany_kurz: {},
      form_rezervace: {
        i_company: "",
        i_fn: "",
        i_sn: "",
        i_street1: "",
        i_street2: "",
        i_city: "",
        i_zip: "",
        i_country: "",
        i_companynum: "",
        i_vatnum: "",
        i_note: "",
        i_email: "",
        i_phone: "",
        i_mpsv: false,
        i_voucher: false,
        i_voucher_1: "",
        i_voucher_2: "",
        i_voucher_3: "",
        i_voucher_4: "",
        i_voucher_5: "",
        i_currency: "CZK",
        vybrany_kurz: null,
      },


      detail_kurzu: false,
      detail_amalka: false,
      c: {
        jmeno: "",
        telefon: "",
        email: "",
        osob: "",
        zprava: "",
      },
      d: {
        jmeno: "",
        telefon: "",
        email: "",
        osob: "",
        zprava: "",
      },
      e: {
        jmeno: "",
        telefon: "",
        email: "",
        osob: "",
        zprava: "",
      },
      f: {
        jmeno: "",
        telefon: "",
        email: "",
        osob: "",
        zprava: "",
      },
      modals: {
        mini: false,
        chyba: false,
        dekujeme: false,
        rezervace: false
      },

      pararusel: [
        "Moc děkujeme za velmi přínosné školení, nový pohled na práci s NGS daty a především nekonečnou trpělivost našich školitelů, bez které by bylo téměř nemožné kurz absolvovat. Ještě jednou děkujeme! <br><small>Radek Vodička, FN Olomouc</small>",
        "Tento kurz naprosto předčil má očekávání. Byl opravdu intenzivní, nabitý praktickými informacemi a úkoly. <br><small>Halka Lhotská</small>",
        "Bioinformatické školenie bolo pre mňa veľmi prínosné, ako laik som sa naučil používať rôzne bioinformatické prístupy na analýzu NGS dát. Všetky kapitoly školenia boli detailne vysvetlené s dôrazom na pochopenie základných súvislostí. Vedomosti boli prehlbované pomocou praktických cvičení, na ktorých som si mohol precvičiť reálne situácie. Vrelo odporúčam. <br><small>Tomáš Píš, GHC Genetics</small>",
        "Toto byl jeden z nejlepších kurzů, které jsem kdy absolvoval. Pokud chcete mít pocit, že vyučující Vás opravdu chtějí něco naučit (někdy až proti vůli Vašeho mozku), tak je to tady. <br><small>Aleš Vícha, FN Motol</small>",
      ],


      rules_rezervace: {
        i_fn: [
          { required: true, message: 'Nezadáno JMÉNO', trigger: 'blur' },
        ],
        i_sn: [
          { required: true, message: 'Nezadáno PŘÍJMENÍ', trigger: 'blur' },
        ],
        i_street1: [
          { required: true, message: 'Nezadána ULICE', trigger: 'blur' },
        ],
        i_city: [
          { required: true, message: 'Nezadáno MĚSTO', trigger: 'blur' },
        ],
        i_zip: [
          { required: true, message: 'Nezadáno PSČ', trigger: 'blur' },
        ],
        i_email: [
          { required: true, message: 'Nezadán E-MAIL', trigger: 'blur' },
          { type: "email", message: 'E-MAIL není validní', trigger: 'blur' },
        ],
        i_phone: [
          { required: true, message: 'Nezadán TELEFON', trigger: 'blur' },
        ],

        i_voucher_1: [
          { validator: val_voucher_1, trigger: 'blur' },
        ],
        i_voucher_2: [
          { validator: val_voucher_2, trigger: 'blur' },
        ],
        i_voucher_3: [
          { validator: val_voucher_3, trigger: 'blur' },
        ],
        i_voucher_4: [
          { validator: val_voucher_4, trigger: 'blur' },
        ],
        i_voucher_5: [
          { validator: val_voucher_5, trigger: 'blur' },
        ],

      },

    }
  },

  computed: {
    ...mapState({
      token: state => state.token,
      kurzy: state => state.kurzy,
      l_kurzy: state => state.l_kurzy,
      terminy_kurzu: state => state.terminy_kurzu,

    }),

    ...mapGetters({
      g_kurz: 'g_kurz',


    }),


  },



  methods: {
      ...mapMutations({
        m_kurzy: 'm_kurzy',


      }),


      ...mapActions({
        a_kurzy: 'a_kurzy',
        a_rezervace: 'a_rezervace',
      }),



    handleScroll() {
      window.scrollTo({ top: 1600, left: 0, behavior: "smooth" });
    },


    click_calendar(id_course) {
      this.vybrany_kurz = this.g_kurz(id_course);
      this.form_rezervace.vybrany_kurz = this.vybrany_kurz.id_course;
      this.modals.rezervace = true;

    },


    click_rezervovat() {
      this.$refs.form_rezervace.validate((valid) => {
        if (valid) {
          this.a_rezervace( this.form_rezervace );
          this.$notify({
            title: 'INFO',
            message: "Rezervace byla odeslána.",
            type: 'success',
            position: 'top-left',
            duration: 10000,
          });
          this.modals.rezervace = false;
        } else {
          this.$notify({
            title: 'INFO',
            message: "Červeně označené položky nejsou validní, opravte je, prosím.",
            type: 'danger',
            position: 'top-left',
            duration: 10000,
          });
        }
      })

    },


    send_mail: function () {
      if (this.form.name && (this.form.email || this.form.phone)) {
        axios
          .post(
            "/ra/send_mail/",
            {
              privat_api_key: "bioxsys_cz_secret_code",
              subject: "TEST Bioxsys",
              name: this.form.name,
              email: this.form.email,
              phone: this.form.phone,
              longtext: this.form.longtext,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            this.modals.mini = true;
            this.form.name = "";
            this.form.email = "";
            this.form.phone = "";
            this.form.longtext = "";
          });
      } else {
        this.modals.chyba = true;
      }
    },

    objednat_c: function () {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.c.email)) {
        axios
          .post(
            "/ra/order_course",
            {
              termin: "C",
              jmeno: this.c.jmeno,
              telefon: this.c.telefon,
              email: this.c.email,
              osob: this.c.osob,
              zprava: this.c.zprava,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            this.c.jmeno = "";
            this.c.telefon = "";
            this.c.email = "";
            this.c.osob = "";
            this.c.zprava = "";
            this.modals.dekujeme = true;
          });
      } else {
        this.modals.chyba = true;
      }
    },

    objednat_d: function () {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.d.email)) {
        axios
          .post(
            "/ra/order_course",
            {
              termin: "D",
              jmeno: this.d.jmeno,
              telefon: this.d.telefon,
              email: this.d.email,
              osob: this.d.osob,
              zprava: this.d.zprava,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            this.d.jmeno = "";
            this.d.telefon = "";
            this.d.email = "";
            this.d.osob = "";
            this.d.zprava = "";
            this.modals.dekujeme = true;
          });
      } else {
        this.modals.chyba = true;
      }
    },


    change_i_mpsv(value) {
      if (value) {
        this.form_rezervace.i_voucher = false;
        this.form_rezervace.i_voucher_1 = "";
        this.form_rezervace.i_voucher_2 = "";
        this.form_rezervace.i_voucher_3 = "";
        this.form_rezervace.i_voucher_4 = "";
        this.form_rezervace.i_voucher_5 = "";
      }
    },


  },


  beforeRouteEnter(to, from, next) {
      next( vm => {
      vm.a_kurzy ( {} );
    });
  },


  mounted () {
    this.a_kurzy ( {} );
    console.log(Object.keys(this.terminy_kurzu))
  },




};
</script>

<style lang="scss">
.el-carousel__item h3 {
  color: white;
  font-size: 15px;
  line-height: 1.45em;
  margin: 20px;
  padding: 10px;
}

.el-carousel__item:nth-child(2n) {
  background-color: transparent;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: transparent;
}

.card-description {
  color: black !important;
  font-weight: 500 !important;
}

.text-black {
  color: black !important;
  font-weight: 500 !important;
}

.title_text {
  color: white !important;
  font-size: 45px !important;
  font-weight: 600 !important;
}

.white {
  color: white !important;
}

.p {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  color: black !important;
  font-weight: 500 !important;
}

.white_card {
  background-color: #ffffff !important;
}

.bl_card {
  background-color: #EBF3FA !important;
}

.or_card {
  background-color: #FAEACB !important;
}

.description {
  margin-top: 5px !important;
}

.mala_mezera_nad {
  margin-top: 5px !important;
  padding: 5px !important;
}

.mala_mezera_nad1 {
  margin-top: 50px !important;
  padding: 5px !important;
}

.go_down {
  font-weight: 800 !important;
  margin-top: 15px !important;
  background-color: orangered !important;
}

.fas_my {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.posun_me_1 {
    margin-left: 100px !important;
    margin-right: 100px !important;
}

.el-input {
  border: 0px solid red !important;
}

.el-input--prefix {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.el-input, .el-input__inner {
  border: 0px solid white !important;
}

.test_001 {
    border: 1px solid #E3E3E3;
    border-radius: 30px;
    background-color: white !important;
}

  .box-card-modal-01 {
    background-color: #DDD !important;
    padding: 10px !important;
    margin-top: 0px !important;
    margin-bottom: 5px !important;
    border-radius: 25px !important;
  }

  .el-form-item {
    margin-bottom: 20px !important;
  }
</style>
