import { render, staticRenderFns } from "./Bioinformatika.vue?vue&type=template&id=70e0fe19"
import script from "./Bioinformatika.vue?vue&type=script&lang=js"
export * from "./Bioinformatika.vue?vue&type=script&lang=js"
import style0 from "./Bioinformatika.vue?vue&type=style&index=0&id=70e0fe19&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports