import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';
// import VueNotify from 'vue-notifyjs';
import router from '../main.js';
import moment from 'moment';
moment.locale("cs");

Vue.use(Vuex);
Vue.use(VueAxios, axios);
// Vue.use(VueNotify, { type: 'danger', duration: 30000, horizontalAlign: 'left', verticalAlign: 'top', });

// let notifier = new Vue();

const store = new Vuex.Store({
    state: {
        // token: localStorage.getItem('token'),

        current_url: "",


        kurzy: [],
        l_kurzy: [],
        terminy_kurzu: {},

        faqs: [],
        faq_cats: [],



    },


    mutations: {
        m_current_url(state, value) {
            state.current_url = value;
        },

        m_kurzy(state, value) {
            state.kurzy = value;
        },

        m_l_kurzy(state, value) {
            state.l_kurzy = value;
        },

        m_terminy_kurzu(state, value) {
            state.terminy_kurzu = value;
        },

        m_faqs(state, value) {
            state.faqs = value;
        },

        m_faq_cats(state, value) {
            state.faq_cats = value;
        },


    },


    actions: {

        a_kurzy({ state, commit }, payload) {
            // this.dispatch('a_load_app_data');
            // let payload = {...state.pagination_lab_reqs, ...state.sorting_lab_reqs};


            axios.get(process.env.VUE_APP_BE + '/kurzy', { withCredentials: true, params: payload, headers: { 'Authorization': localStorage.getItem('token'), 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_kurzy', response.data["kurzy"]);
                    commit('m_l_kurzy', response.data["l_kurzy"]);
                    commit('m_terminy_kurzu', response.data["terminy_kurzu"]);
                }, error => {
                    console.log(error);
                });
        },


        a_rezervace({ state, commit }, payload) {
            axios.post(process.env.VUE_APP_BE + '/rezervace', payload, { withCredentials: true, headers: { 'Authorization': localStorage.getItem('token'), 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    // commit('m_kurzy', response.data["kurzy"]);
                    // commit('m_l_kurzy', response.data["l_kurzy"]);
                    // commit('m_terminy_kurzu', response.data["terminy_kurzu"]);
                }, error => {
                    console.log(error);
                });
        },


        a_faqs({ state, commit }, ) {
            axios.get(process.env.VUE_APP_BE + '/faqs', { withCredentials: true, headers: { 'Authorization': localStorage.getItem('token'), 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_faqs', response.data["faqs"]);
                    commit('m_faq_cats', response.data["faq_cats"]);
                }, error => {
                    console.log(error);
                });
        },


        a_kontaktujte({ state, commit }, payload) {
            axios.post(process.env.VUE_APP_BE + '/kontaktujte', payload, { withCredentials: true, headers: { 'Authorization': localStorage.getItem('token'), 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {

                }, error => {
                    console.log(error);
                });
        },



    },


    getters: {

        g_kurz: (state, getters) => (id_course) => {
            for (var x of state.l_kurzy.filter(one => (one.id_course === id_course))) {
                return x;
            }
        },

        g_faqs: (state, getters) => (id_faq_cat) => {
            return state.faqs.filter(one => (one.id_faq_cat === id_faq_cat));

        },




    },


})


export default store;
