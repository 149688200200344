<template>
       <div class="">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 ml-auto mr-auto">
                        <h2 class="title title_center">FAQ - Genovesa</h2>

                        <card type="blog" plain>
                            <div class="row">
                                <div class="col-md-10  ml-auto mr-auto">
                                    <collapse :multiple-active="false">
                                        <collapse-item v-for="(one, index) in faq" v-bind:key="index" v-bind:value="one" v-bind:title="one.q" name="1">
                                        <div> {{ one.a }} </div>
                                        <hr>
                                        </collapse-item>
                                    </collapse>
                                </div>
                            </div>

<!--                             <div class="row">
                                <div class="col-md-10 ml-auto mr-auto">
                                    <video autoplay muted loop>
                                        <source src="/img/sroubovice_1.mp4" type="video/mp4">
                                     </video>
                                </div>
                            </div>      -->                   
                        </card>

                    </div>
                </div>
            </div>
        </div>


</template>
<script>
  import { Card, Button, FormGroupInput, Tabs, TabPane, Collapse, CollapseItem } from '@/components';

  export default {
    name: 'landing-page',
    bodyClass: 'landing-page',
    components: {
      Card,
      Tabs,
      TabPane,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput,
      Collapse,
      CollapseItem
    },
    data() {
      return {
        form: {
          firstName: '',
          email: '',
          message: ''
        },
        faq: [
            {
                q: "K čemu slouží software Genovesa?",
                a: "Genovesa je aplikace, která slouží jako laboratorní informační systém (LIS) pro evidenci a správu pacientů a jejich vyšetření. Provádí sekundární a terciální analýzu NGS sekvenačních dat."
                    + " Dále slouží jako platforma pro sdílení anotovaných variant, jejich intepretaci a vytváření klinických reportů."
            },
            {
                q: "Jak se dá charakterizovat Genovesa?",
                a: "Genovesa je responzivní, reaktivní single page webová aplikace (SPA), se kterou můžete pohodlně pracovat i na mobilních zařízeních. Pro zobrazování některých tabulek potřebujete minimálně velikost tabletu."

            },
            {
                q: "Když je to webová aplikace, mohu ji opravdu provozovat off-line?",
                a: "Ve skutečnosti je Genovesa pseudo-webová. Můžete ji provozovat i off-line, pokud máte backendovou i databázovou část lokálně. Lze ji provozovat i úplně oddělenou od internetu."
            },
            {
                q: "Jak se pracuje s našimi daty?",
                a: "Rozlišujeme dva druhy dat. Privátní (osobní data pacientů) a anonymizovaná (charakteristiky pacientů bez možnosti identifikace pro statistické účely, varianty, anotace a další metadata)." 
                    + " Privátní data pacientů jsou VŽDY uložena lokálně, např. na dané klinice. Je to na Vašem zařízení a nikdo jiný k němu nemá přístup. Tato data nelze sdílet mezi subjekty. My je nikam neukládáme a nemáme k nim přístup."
                    + " Anonymizovaná data ukládáme v našem databázovém úložišti, což je vlastní serverová farma v datovém centru, uzamčená, pod kamerovým dohledem."
                                },
            {
                q: "A proč mají být data uložena jinak než lokálně?",
                a: "Kromě privátních a anonymizovaných dat pacientů ukládáme především metadata, která je z jejich podstaty potřeba sdílet a dále je obohacovat. Například nalezené varianty ukládáme ve sdíleném prostoru a díky tomu k nim mají přístup všichni uživatelé."
                    + " Neustále doplňujeme nové zdroje a verzujeme anotace existujících variant, čímž zásadně obohacujeme informace v úložišti. Oproti izolovanému přístupu k těmto datům, kolaborativní způsob práce přináší obrovský užitek všem."
                    + " Soustředěné úložiště zjednodušuje našim bioinformatikům péči o data, která mohou snadněji obohacovat a zkvalitňovat a rovněž vytváření ML (machine learning) systém nad nimi."
            },
            {
                q: "Mohu sdílet některé projekty nebo jednotlivá vyšetření s jinými subjekty?",
                a: "Ano. To je jednou z významných předností cloudové Genovesy. Každý záznam si udržuje seznam klíčů, které jej zpřístupňují a každý uživatel má unikátní klíč. Pokud vlastník záznamu přiřadí (projekt, vyšetření) cizí (veřejný) klíč, může jej jeho držitel prohlížet."
            },
            {
                q: "Musíme tedy opravdu zadávat rodné číslo pacienta?",
                a: "V zásadě nemusíte. Dojde ovšem k omezení funkcí. Z RČ tvoříme jakýsi identifikátor, který slouží k ověření mezi ostatními daty, abychom mohli zajistit jednoznačnost záznamu v databázi." 
                    + " Nepoznáme sice pacienta, ale poznáme, jestli už je v našich datech zadán. Tím zajistíme, že nebude vícenásobné nahrání záznamu negativně ovlivňovat frekvence variant. Rovněž zajistíme, že nebudeme provádět opakovaně placené úkony, pokud byste nahráli duplicitní data."
                    + " Data tedy sice uložíme do Vašeho datového prostoru, nebudeme s nimi ale dále nijak (statisticky) pracovat a budou svázána pouze s daným vyšetřením. Nespojíme ani různá vyšetření stejného pacienta, jelikož každý takto vložený záznam bude unikátní i když půjde o stejného pacienta."
            },
            {
                q: "Čemu říkáte bezcenná data?",
                a: "Bezcenná ve smyslu statistického zpracování jsou data, která nemůžeme ověřit z hlediska unikátnosti. Proto je nemůžeme použít do frekvenční analýzy nebo jako zdroj pro machine learning system. Nedostávají se ani do našeho notifikačního systému, jehož úkolem je informovat původce záznamu o zajímavých datech, které se vážou k datům pacienta." 
                    + " Získá-li například některá z variant nebo jejich skupina nový status v rámci anotací a identifikovatelný pacient by měl s novým nálezem shodu, původce záznamu je o tom informován. U neidentifikovatelných pacientů není."
            },
            {
                q: "Dodáváte i hardware v případě, že chci lokální verzi?",
                a: "Obecně nejsme dodavatelem hardware, ale rádi Vám poradíme s výběrem dodavatele a hardware. Dle Vašich potřeb na výpočetní kapacitu sekvenačních dat Vám navrhneme server na míru a následně provedeme instalaci našeho software Genovesa."
            },

        ]
      }
    }
  }
</script>
<style>
.card-description {
    color: black !important;
    font-weight: 500 !important;
}

.text-white {
    font-weight: 300 !important;
}

collapse-item {
    color: black !important;
    font-weight: 800 !important;
}

.title_center {
    text-align: center !important;
    margin-top: 30px !important;
    padding-top: 80px !important;
    color: black !important;

}

video {
  width: 100%;
  height: auto;
}

</style>
