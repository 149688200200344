import Vue from "vue";
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import axios from 'axios';

import moment from 'moment';
Vue.prototype.moment = moment
moment.locale("cs");

import "./registerServiceWorker";
import 'animate.css';
import VueTimers from 'vue-timers';



//plugins
import NowUIKit from './plugins/now-ui-kit';

import App from "./App.vue"
import routes from "./routes";
import store from './stores';


import {
    Pagination,
    Dialog,
    Autocomplete,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Menu,
    Submenu,
    MenuItem,
    MenuItemGroup,
    Input,
    InputNumber,
    Radio,
    RadioGroup,
    RadioButton,
    Checkbox,
    CheckboxButton,
    CheckboxGroup,
    Switch,
    Select,
    Option,
    OptionGroup,
    Button,
    ButtonGroup,
    Table,
    TableColumn,
    DatePicker,
    TimeSelect,
    TimePicker,
    Popover,
    Popconfirm,
    Tooltip,
    Breadcrumb,
    BreadcrumbItem,
    Form,
    FormItem,
    Tabs,
    TabPane,
    Tag,
    Tree,
    Alert,
    Slider,
    Icon,
    Row,
    Col,
    Upload,
    Progress,
    Spinner,
    Badge,
    Card,
    Rate,
    Steps,
    Step,
    Carousel,
    CarouselItem,
    Collapse,
    CollapseItem,
    Cascader,
    ColorPicker,
    Transfer,
    Container,
    Header,
    Aside,
    Main,
    Footer,
    Timeline,
    TimelineItem,
    Link,
    Divider,
    Image,
    Calendar,
    Backtop,
    PageHeader,
    CascaderPanel,
    Loading,
    MessageBox,
    Message,
    Notification,
    InfiniteScroll
} from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';

import './assets/sass/web.scss';


Vue.use(Vuex);

// Vue.config.productionTip = false;
Vue.use(NowUIKit);
Vue.use(VueTimers);
Vue.use(VueRouter);


Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Autocomplete);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Popover);
Vue.use(Popconfirm);
Vue.use(Tooltip);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tag);
Vue.use(Tree);
Vue.use(Alert);
Vue.use(Slider);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Upload);
Vue.use(Progress);
Vue.use(Spinner);
Vue.use(Badge);
Vue.use(Card);
Vue.use(Rate);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Cascader);
Vue.use(ColorPicker);
Vue.use(Transfer);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Link);
Vue.use(Divider);
Vue.use(Image);
Vue.use(Calendar);
Vue.use(Backtop);
Vue.use(PageHeader);
Vue.use(CascaderPanel);
Vue.use(InfiniteScroll);


Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;



const router = new VueRouter({
    mode: 'history',
    routes,
    linkActiveClass: 'active',
    scrollBehavior: (to) => {
        if (to.hash) {
            return { selector: to.hash }
        } else {
            return { x: 0, y: 0 }
        }
    }
});

Vue.use(router);


// router.beforeEach((to, from, next) => {
//     const publicPages = ['/', '/welcome', '/validate', ];
//     const authRequired = !publicPages.includes(to.path);
//     const loggedIn = localStorage.getItem('token');

//     if (authRequired && !loggedIn) {
//         return next('/welcome');
//     }

//     next();
// })









new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),

  created() {

  },

  mounted() {
    var currentUrl = window.location;
    this.$store.commit('m_current_url', currentUrl);

    console.log(currentUrl);

    const token = localStorage.getItem('token');
    console.log(token);
    if (token == null) {
      axios.get(process.env.VUE_APP_BE + '/')
          .then(response => {
            localStorage.setItem('token', response.data["token"]);

          }, error => {
              console.log(error);
          });

    }


  },


  xmounted() {
      axios.get(process.env.VUE_APP_BE + '/')
          .then(response => {
              // this.$store.commit('m_l_hpo', response.data["l_hpo"]);

          }, error => {
              console.log(error);
          });

      // if (this.$store.state.token) {
      //     this.$store.dispatch('a_load_profile');
      // }


      if (this.$store.state.token) {
          axios.get(process.env.VUE_APP_BE + '/load_app_data', { withCredentials: true, headers: { 'Authorization': this.$store.state.token, 'Content-Type': 'application/json;charset=UTF-8' } })
              .then(response => {
                  this.$store.commit('m_texts', response.data["texts"]);
                  this.$store.commit('m_countries', response.data["countries"]);

                  this.$store.commit('m_author', response.data["author"]);
                  if (response.data["author"]["a_patient_id"]) {
                      this.$store.commit('m_lab_req_crgh_a', 9732);
                      this.$store.commit('m_lab_req_crgh_b', 9509);
                  }

                  this.$store.commit('m_lang', response.data["author"]["a_lang"]);
                  this.$store.commit('m_doctors_select', response.data["doctors_select"]);
                  this.$store.commit('m_nurses', response.data["nurses"]);
                  this.$store.commit('m_subjects_select', response.data["subjects_select"]);
                  this.$store.commit('m_embryologs_select', response.data["embryologs_select"]);
                  this.$store.commit('m_l_sections', response.data["sections"]);
                  this.$store.commit('m_l_method_groups', response.data["method_groups"]);
                  this.$store.commit('m_l_method_icd10s', response.data["method_icd10s"]);
                  this.$store.commit('m_l_methods', response.data["methods"]);
                  // this.$store.commit('m_d_icd10s', response.data["icd10s"]);
                  this.$store.commit('m_l_insurance_companies', response.data["insurance_companies"]);
                  this.$store.commit('m_l_materials', response.data["materials"]);
                  this.$store.commit('m_l_material_groups', response.data["material_groups"]);
                  this.$store.commit('m_l_gene_groups', response.data["gene_groups"]);
                  this.$store.commit('m_l_genes', response.data["genes"]);
                  this.$store.commit('m_l_genes_uniq', response.data["genes_uniq"]);
                  this.$store.commit('m_select_genes', response.data["genes_uniq"]);

                  // this.$store.commit('m_lang', response.data["author"]["a_lang"]);

                  // this.$store.commit('m_l_gene_group_genes', response.data["gene_group_genes"]);

              }, error => {
                  console.log(error);
              });


      }


      var currentUrl = window.location;
      this.$store.commit('m_current_url', currentUrl);

      var application;
      if (currentUrl.hostname === "glex.bioxsys.cz") {
          application = "GLEX"
      } else {
          application = "GLEX"
      }
      this.$store.commit('m_application', application);

  },


})

export default router

