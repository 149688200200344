<template>


        <footer class="footer better_bg_color" data-background-color="black">
            <div class="container">
                <div class="content">
                    <div class="row">
                        <div class="col-md-3">
                          <img src="img/bioxsys_negative.svg" height="60px" alt="" class="" />
                            <ul class="links-vertical">
                                <li class="router-link-item pt-4">
                                  <a href="mailto:info@bioxsys.cz"><i class="now-ui-icons ui-1_email-85 pad"></i> info@bioxsys.cz</a>
                                </li>
                                <li class="router-link-item pt-1 pl-1">
                                   <i class="now-ui-icons tech_mobile pad"></i> +420 604 799 725
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-3">
                            <h5>Produkty a služby</h5>
                            <ul class="links-vertical">
                                <li>
                                    <router-link class="router-link-item nav-link" to="/genovesa"> GENOVESA </router-link>
                                </li>
                                <li>
                                    <router-link class="router-link-item nav-link" to="/covid"> COVID </router-link>
                                </li>
                                <li>
                                    <router-link class="router-link-item nav-link" to="/biosupport"> Bioinformatické služby </router-link>
                                <li>
                                    <router-link class="router-link-item nav-link" to="/itsupport"> Vývoj software </router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-3">
                            <h5>Kurzy</h5>
                            <ul class="links-vertical">
                                <li>
                                    <a href="mailto:info@bioxsys.cz"><i class="now-ui-icons ui-1_email-85 pad"></i> kurzy@bioxsys.cz</a>
                                </li>
                                <li>
                                    <i class="now-ui-icons tech_mobile pad"></i> +420 608 243 153
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-3">
                            <h5>Sledujte nás</h5>
                            <ul class="social-buttons">
                                <li>
                                  <a href="https://twitter.com/Bioxsys_eu" class="btn btn-icon btn-primary btn-round" target="_new">
                                    <i class="fab fa-twitter"></i>
                                  </a>
                                    &nbsp;
                                </li>
                                <li class="pl-2">
                                  <a href="https://www.linkedin.com/company/bioxsys" class="btn btn-icon btn-primary btn-round" target="_new">
                                      <i class="fab fa-linkedin"></i>
                                  </a>
                                    &nbsp;
                                </li>
                             </ul>
                        </div>
                    </div>
                </div>
                <hr/>
                <div class="row">
                    <div class="copyright mr-auto"><i class="now-ui-icons location_pin pad"></i> Palác Jordán, 6. patro, Dlouhá 3458/2A, 400 11, Ústí nad Labem - město
                    </div>
                    <div class="copyright ml-auto">
                        Copyright &copy;
                        {{year}} Bioxsys s.r.o., 2019 - 2024. All rights reserved
                    </div>
                </div>
            </div>
        </footer>

</template>
<script>
  import { InfoSection } from '@/components';

  export default {
    props: {
      backgroundColor: String,
      type: String
    },
    components: {
      InfoSection,
    },
    data(){
      return {
        year: new Date().getFullYear()
      }
    }
  }
</script>
<style>

.my_icons {

font-size: 20px !important;
}


.better_bg_color {
  background-color: #1E2566 !important;
}

.text_white_footer {
  color: white !important;
  font-size: 12px !important;

}

.copyright {
  align-content: center !important;
  color:white !important;
  font-weight: 10px !important;
}

.pad {
  padding: 7px !important;

}


</style>
