<template>
    <div class="wrapper landing-page">
        <div class="page-header page-header-small mhp">
            <parallax class="page-header-image"
                 style="background-image: url('img/p_genovesa.jpg')">
            </parallax>
            <div class="content-center mw">
                <h1 class="title">GENOVESA</h1><br>
                <h2 class="title">Laboratorní NGS informační systém</h2>
                <div class="bor_4">
                    <h5 class="color_2 title text-center">Svou komplexností pokrývá vlastnosti LIS (evidence a anamnéza pacienta, přílohy testů, aj.) až po automatizovanou analýzu včetně pokročilé kontroly kvality sekvenačních dat a klinickou interpretaci s následným vytvořením reportu. 
                    Díky uložení variant v databázi GENOVESA umožňuje jejich snadnou filtraci, vizualizaci a sdílení mezi klinikami.
                    </h5>
                </div>
            </div>
        </div>
        <div class="bg_color_main">
            <div class="row">
                <div class="ml-auto">
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-3 ml-auto mr-auto text-center" @mouseover="card_rotate(1, false)" @mouseleave="card_rotate(1, true)">
                            <card v-if="card_01" type="testimonial" class="mh bg_color_main4">
                                <img slot="image" class="card-img-top bor_img" :src="imgSrc1">
                                <div class="info info-hover mp">
                                    <h4 class="title">Secure</h4>
                                </div>
                            </card>
                            <card v-else type="testimonial bg_color_main3" class="mh">
                                <div class="info info-hover mp">
                                    <h5 class="title">Secure</h5>                                
                                    <p class="description">Splňuje základní požadavky na zabezpečení a šifrování sekvenačních dat.</p>
                                </div>
                            </card>                        
                        </div>
                        <div class="col-md-3 ml-auto mr-auto text-center" @mouseover="card_rotate(2, false)" @mouseleave="card_rotate(2, true)">
                            <card v-if="card_02" type="testimonial" class="mh bg_color_main5">
                                <div>
                                    <h4 class="title">GDPR ready</h4>
                                </div>
                                <img slot="image" class="card-img-bottom bor_img" :src="imgSrc3" alt="Card image cap">
                            </card>
                            <card v-else type="testimonial bg_color_main3" class="mh">
                                <div>
                                    <h5 class="title">GDPR ready</h5>
                                    <p class="description">Efektivní ochrana osobních dat.</p>
                                </div>
                            </card>                        
                        </div>
                        <div class="col-md-3 ml-auto mr-auto text-center" @mouseover="card_rotate(3, false)" @mouseleave="card_rotate(3, true)">
                            <card v-if="card_03" type="testimonial" class="mh bg_color_main6">
                                <img slot="image" class="card-img-top bor_img" :src="imgSrc4">
                                <div class="info info-hover mp">
                                    <h4 class="title">Bioxsys Cloud</h4>
                                </div>
                            </card>
                            <card v-else type="testimonial bg_color_main3" class="mh">
                                <div class="info info-hover mp">
                                    <h5 class="title">Bioxsys Cloud</h5>
                                    <p class="description">Pacientská data jsou uložena lokálně. Analýza probíhá na našich výpočetních serverech.</p>
                                </div>
                            </card>                        
                        </div>
                        <div class="col-md-3 ml-auto mr-auto text-center" @mouseover="card_rotate(4, false)" @mouseleave="card_rotate(4, true)">
                            <card v-if="card_04" type="testimonial" class="mh bg_color_main7">
                                <img slot="image" class="card-img-top bor_img" :src="imgSrc5">
                                <div class="info info-hover mp">
                                    <h4 class="title">Lokální instalace<br></h4>
                                </div>
                            </card>
                            <card v-else type="testimonial bg_color_main3" class="mh">
                                <div class="info info-hover mp">
                                    <h5 class="title">Lokální instalace</h5>
                                    <p class="description">Kompletní lokální instalace včetně výpočetního serveru a síťového nastavení. Aktualizace databází pomocí virtuální privátní sítě (VPN).</p>
                                </div>
                            </card>                        
                        </div>
                    </div>
                </div>
                <div class="mr-auto">
                </div>
            </div>
        </div>

        <div class="testimonials-1" style="background-image: url('img/podklad_1.jpg')">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="title text-center color_2 pb-5">Hlavní funkcionality</h1>
                        <div class="row mt-5 mb-5" v-waypoint="{ active: true, callback: waypoint_my_flying_image_1 }">
                            <div class="col-md-1">
                                <div class="bg_color_main5 bor_2">
                                1
                                </div>
                            </div>
                            <div class="col-md-7">
                                <h2 class="title color_2">
                                    Všechna data<br> na jednom místě
                                </h2>
                                <p class="card-description">
                                    Sekvenační data lze doplnit o výstupy z dalších diagnostických metod (MLPA, CGH-Array, Chip-Seq, Q-PCR, aj.) včetně kontroly kvality vzorku či sekvenační knihovny. Díky objektové databázi máte k těmto datům okamžitý přístup.
                                    Možnost napojení výsledků na stávající laboratorní informační systém s funkcí import / export různých datových typů.
                                </p>
                            </div>
                            <div class="col-md-4 ml-auto">
                                <transition name="my_flying_image_1">
                                    <div v-if="show_my_flying_image_1" class="flying_image_1">
                                        <el-image
                                        class="img img_top" src="/img/data_sud_3.png"                    
                                        ></el-image>
                                    </div>
                                </transition>
                            </div>
                        </div>
                        
                        <div class="row mt-5 mb-5" v-waypoint="{ active: true, callback: waypoint_my_flying_image_2 }">
                            <div class="col-md-4 mr-auto">
                                <transition name="my_flying_image_2">
                                    <div v-if="show_my_flying_image_2" class="flying_image_2">
                                        <el-image
                                        class="img img_top" src="/img/sprava_pacienta_genovesa_3.png"                    
                                        ></el-image>
                                    </div>
                                </transition>
                            </div>
                            <div class="col-md-1">
                                <div class="bg_color_main5 bor_2">
                                2
                                </div>
                            </div>
                            <div class="col-md-7">
                                <h2 class="title color_2">
                                    Správa<br>pacienta
                                </h2>
                                <p class="card-description">
                                    Pacientská karta nese informace o diagnóze pacienta, rodinné anamnéze, ICD-11 včetně popisu fenotypu dle HPO termínů, které dále slouži pro filtraci a prioritizaci nalezených variant. 
                                    V kartě pacienta můžete jednoduše doplnit STATIM, který pacienta zařadí do přednostního seznamu zpracování včetně možnosti notifikace emailem.
                                </p>
                            </div>
                        </div>

                        <div class="row mt-5 mb-5" v-waypoint="{ active: true, callback: waypoint_my_flying_image_3 }">
                            <div class="col-md-1">
                                <div class="bg_color_main5 bor_2">
                                3
                                </div>
                            </div>                                    
                            <div class="col-md-7">
                                <h2 class="title color_2">
                                    Sekvenační<br>technologie
                                </h2>
                                <p class="card-description">
                                    Genovesa umožňuje analýzu konvenčních sekvenačních dat (FASTQ, BAM, VCF) bez ohledu na sekvenační technologii - Illumina, MGI, PacBio, Oxford Nanopore Technologies, Genapsys a mnoho dalších.
                                </p>
                            </div>
                            <div class="col-md-4 ml-auto">
                                <transition name="my_flying_image_3">
                                    <div v-if="show_my_flying_image_3" class="flying_image_3">
                                        <el-carousel indicator-position="none" :interval="3000">
                                            <el-carousel-item>
                                                <img class="img rounded img_top" src="img/sekvenator_1_1.png" alt="">
                                            </el-carousel-item>
                                            <el-carousel-item>
                                                <img class="img rounded img_top" src="img/sekvenator_2_2.png" alt="">
                                            </el-carousel-item>
                                            <el-carousel-item>
                                                <img class="img rounded img_top" src="img/sekvenator_3_3.png" alt="">
                                            </el-carousel-item>
                                            <el-carousel-item>
                                                <img class="img rounded img_top" src="img/sekvenator_4_4.png" alt="">
                                            </el-carousel-item>
                                            <el-carousel-item>
                                                <img class="img rounded img_top" src="img/sekvenator_5_5.png" alt="">
                                            </el-carousel-item>
                                        </el-carousel>
                                    </div>
                                </transition>
                            </div>
                        </div>

                        <div class="row mt-5 mb-5" v-waypoint="{ active: true, callback: waypoint_my_flying_image_4 }">
                            <div class="col-md-4 mr-auto ">
                                <transition name="my_flying_image_4">
                                    <div v-if="show_my_flying_image_4" class="flying_image_4">
                                        <el-image
                                        class="img img_top" src="/img/sw_loga_3.png"                    
                                        ></el-image>
                                    </div>
                                </transition>
                            </div>
                            <div class="col-md-1">
                                <div class="bg_color_main5 bor_2">
                                4
                                </div>
                            </div>
                            <div class="col-md-7">
                                <h2 class="title color_2">
                                    Komerční<br> bioinformatický software
                                </h2>
                                <p class="card-description">
                                    Genovesa je kompatibilní s výstupy generovanými širokým spektrem bioinformatických nástrojů jako jsou VarSome, NextGene, QIAGEN, 
                                    FinalistDX, SOPHia GENETICS, JSI Sequence pilot, BaseSpace a mnoho dalších. Lze také importovat vlastní akreditovanou bioinformatickou pipeline.
                                </p>
                            </div>
                        </div>

                        <div class="row mt-5 mb-5" v-waypoint="{ active: true, callback: waypoint_my_flying_image_5 }">
                            <div class="col-md-1 ">
                                <div class="bg_color_main5 bor_2">
                                5
                                </div>
                            </div>
                            <div class="col-md-7">
                                <h2 class="title color_2">
                                    Databáze<br> a sdílení
                                </h2>
                                <p class="card-description">
                                    Uložení dat v databázi umožňuje jejich rychlou filtraci a interní klasifikaci včetně komentáře. Možnost filtrace sekvenačních chyb na základě četnosti identických variant v jednom sekvenačním experimentu, 
                                    ve všech experimentech, v rámci kliniky a v globální databázi sdílených klinik. Díky sdílení dat lze urychlit a zpřesnit hodnocení a intepretaci variant pacienta.
                                </p>                                                 
                            </div> 
                            <div class="col-md-4 ml-auto">
                                <transition name="my_flying_image_5">
                                    <div v-if="show_my_flying_image_5" class="flying_image_5">
                                        <el-image
                                        class="img img_top" src="/img/hp_databaze_3.png"                    
                                        ></el-image>
                                    </div>
                                </transition>
                            </div>                                               
                        </div>

                        <div class="row mt-5 mb-5" v-waypoint="{ active: true, callback: waypoint_my_flying_image_6 }">
                            <div class="col-md-4 mr-auto">
                                <transition name="my_flying_image_6">
                                    <div v-if="show_my_flying_image_6" class="flying_image_6">
                                        <el-image
                                        class="img img_top" src="/img/genovesa_bioinformatika_3.png"                    
                                        ></el-image>
                                    </div>
                                </transition>
                            </div>
                            <div class="col-md-1">
                                <div class="bg_color_main5 bor_2">
                                6
                                </div>
                            </div>
                            <div class="col-md-7 order-md-12">
                                <h2 class="title color_2">
                                    Bioinformatická<br>data
                                </h2>
                                <p class="card-description">
                                    Genovesa umožňuje hodnotit data od malých panelů přes klinický exom (CES) až po celo-exomová data (WES). Obsahuje bohaté spektrum anotačních databází s možností individuálního rozšíření. Kontrola kvality sekvenace, FASTQ, BAM a VCF na jednom místě.
                                </p>
                            </div>
                        </div>
                     </div>
                </div>
                <div class="row pt-5 pb-5">
                    <div class="col-md-4"></div>
                    <div class="col-md-3 ml-auto mr-auto"><h5 class="title text-center"><router-link class="router-link-item nav-link color_3" to="/kontaktujte">Pro více informací nás kontaktuje</router-link></h5></div>
                    <div class="col-md-4"></div>
                </div>
            </div> 
        </div>
    </div>

</template>

<script>
  import Vue from 'vue';
  import { Card, Button, FormGroupInput, Tabs, TabPane  } from '@/components';
  import { Carousel, CarouselItem, Image } from 'element-ui';
  import VueWaypoint from 'vue-waypoint';
  Vue.use(VueWaypoint);

  export default {
    name: 'a_genovesa',
    bodyClass: 'landing-page',
    components: {
      Card,
      Tabs,
      TabPane,
      [Button.name]: Button,
      [Image.name]: Image,
      [FormGroupInput.name]: FormGroupInput,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,   

  
    },
    data() {
      return {
        imgSrc1: "img/secure.jpg",
        imgSrc3: "img/gdpr.jpg",
        imgSrc4: "img/cloud.jpg",
        imgSrc5: "img/office_061_b.jpg",
        form: {
          firstName: '',
          email: '',
          message: ''
        },
        card_01: true,
        card_02: true,
        card_03: true,
        card_04: true,  

        show_my_flying_image_1: false,                  
        show_my_flying_image_2: false,
        show_my_flying_image_3: false, 
        show_my_flying_image_4: false,
        show_my_flying_image_5: false,      
        show_my_flying_image_6: false,             
      }
    },
 
      methods: {
      handleScroll () {
        window.scrollTo({top: 900, left: 0, behavior: 'smooth'});
      },


    waypoint_my_flying_image_1 ({ going, direction }) {
      if (going === this.$waypointMap.GOING_IN) {
        if (this.show_my_flying_image_1) {

        } else {
            this.show_my_flying_image_1 = true;
        }
      }
 
    //   if (direction === this.$waypointMap.DIRECTION_TOP) {
    //     console.log('waypoint going top!')
    //   }
    },    
    
    waypoint_my_flying_image_2 ({ going, direction }) {
      if (going === this.$waypointMap.GOING_IN) {
        if (this.show_my_flying_image_2) {

        } else {
            this.show_my_flying_image_2 = true;
        }
      }
    },

    waypoint_my_flying_image_3 ({ going, direction }) {
      if (going === this.$waypointMap.GOING_IN) {
        if (this.show_my_flying_image_3) {

        } else {
            this.show_my_flying_image_3 = true;
        }
      }
    },

    waypoint_my_flying_image_4 ({ going, direction }) {
      if (going === this.$waypointMap.GOING_IN) {
        if (this.show_my_flying_image_4) {

        } else {
            this.show_my_flying_image_4 = true;
        }
      }
    },

    waypoint_my_flying_image_5 ({ going, direction }) {
      if (going === this.$waypointMap.GOING_IN) {
        if (this.show_my_flying_image_5) {

        } else {
            this.show_my_flying_image_5 = true;
        }
      }
    },

    waypoint_my_flying_image_6 ({ going, direction }) {
      if (going === this.$waypointMap.GOING_IN) {
        if (this.show_my_flying_image_6) {

        } else {
            this.show_my_flying_image_6 = true;
        }
      }
    },    
    
    

      card_rotate (w,s) {
        if (w === 1) {
            this.card_01 = s;
        } else if (w === 2) {
            this.card_02 = s;
        } else if (w === 3) {
            this.card_03 = s;        
        } else if (w === 4) {
            this.card_04 = s;
        } else {
            console.log(w,s);
        }

      },


    },
  }
</script>
<style scoped>

.card-description {
    color: black !important;
    font-weight: 500 !important;
}

.description {
    margin-top: 30px !important;
}

h3 {
    margin-bottom: 10px !important ;
}

.title {
    padding-top: 1px !important ;
}

.color_1 {
    color: #EDC880 !important;
}

.color_2 {
    color: #141F45 !important;
}

.color_3 {
    color: #00659F !important;
}

.color_3:hover {
    color: #f96332 !important;
}

.color_4 {
    color: white !important;
}

.bg_color_main {
    background-color: #141F45 !important;
    padding-top: 5px !important;
    padding-bottom: 100px !important;
}

.bg_color_main3 {
    background-color:  #EDC880 !important;
}

.bg_color_main4 {
    background-color: #B1C9EA !important;
}

.bg_color_main5 {
    background-color: #86B3CA !important;
}

.bg_color_main6 {
    background-color: #889DBA !important;
}

.bg_color_main7 {
    background-color: #6A8FB0 !important;
}

.img_top {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    padding: 0 20px 0 20px !important;
}

.mw { 
    max-width: 1300px !important;
}

.mh { 
    min-height: 270px !important;
}

.mp { 
    margin: 0px !important;
    padding: 0px !important;
}

.op {
    opacity: 0.5 !important;
}

.bor_img {
    border-radius: 20px 20px 0 0 !important;
}

.bor_2 {
    text-align: center !important;
    font-size: 2.5rem !important;
    color: white !important;
    line-height: 1.5 !important;
    font-weight: 800 !important;
    border-radius: 10rem 10rem 10rem 0 !important;
    background: #889DBA !important;
    padding: 10px !important;
    height: 4.6rem !important;
    width: 4.6rem !important;
}

.bor_3 {
    text-align: center !important;
    font-size: 2.5rem !important;
    color: white !important;
    line-height: 1.5 !important;
    font-weight: 800 !important;
    border-radius: 10rem 10rem 0 10rem !important;
    background: #889DBA !important;
    padding: 10px !important;
    height: 4.6rem !important;
    width: 4.6rem !important;
}

.bor_4 {
    border-radius: 2rem 2rem 0 2rem !important;
    background: white !important;
    padding: 10px 20px 10px 20px !important;
    height: auto !important;
    width: 100% !important;
    opacity: 0.7 !important;
}

.bor_5 {
    text-align: center !important;
    color: white !important;
    border-radius: 2rem 2rem 0 0 !important;
}

.flying_image_1 { 
    z-index: 20 !important;
    left: 400px;
}
.my_flying_image_1-enter-active {
    transition: all 3.3s ease;
}
.my_flying_image_1-leave-active {
    transition: all 3.8s cubic-bezier(.1, 0.1, 0.5, 4.0);
}
.my_flying_image_1-enter {
    transform: translateX(400px);
    opacity: 0;
}

.flying_image_2 { 
    z-index: 20 !important;
    right: 250px;

}
.my_flying_image_2-enter-active {
    transition: all 3.3s ease;
}
.my_flying_image_2-leave-active {
    transition: all 3.8s cubic-bezier(3.0, 0.5, 0.5, 1.0);
}
.my_flying_image_2-enter {
    transform: translateX(-350px);
    opacity: 0;
}

.flying_image_3 { 
    z-index: 20 !important;
    left: 400px;
}
.my_flying_image_3-enter-active {
    transition: all 3.3s ease;
}
.my_flying_image_3-leave-active {
    transition: all 3.8s cubic-bezier(.1, 0.1, 0.5, 4.0);
}
.my_flying_image_3-enter {
    transform: translateX(400px);
    opacity: 0;
}

.flying_image_4 { 
    z-index: 20 !important;
    right: 250px;

}
.my_flying_image_4-enter-active {
    transition: all 3.3s ease;
}
.my_flying_image_4-leave-active {
    transition: all 3.8s cubic-bezier(3.0, 0.5, 0.5, 1.0);
}
.my_flying_image_4-enter {
    transform: translateX(-350px);
    opacity: 0;
}

.flying_image_5 { 
    z-index: 20 !important;
    left: 400px;
}
.my_flying_image_5-enter-active {
    transition: all 3.3s ease;
}
.my_flying_image_5-leave-active {
    transition: all 3.8s cubic-bezier(.1, 0.1, 0.5, 4.0);
}
.my_flying_image_5-enter {
    transform: translateX(400px);
    opacity: 0;
}

.flying_image_6 { 
    z-index: 20 !important;
    right: 250px;

}
.my_flying_image_6-enter-active {
    transition: all 3.3s ease;
}
.my_flying_image_6-leave-active {
    transition: all 3.8s cubic-bezier(3.0, 0.5, 0.5, 1.0);
}
.my_flying_image_6-enter {
    transform: translateX(-350px);
    opacity: 0;
}

.testimonials-1 {
    padding-bottom: 0px !important;
}


@media only screen and (min-device-width: 800px) {
    h1 {
        margin-top: 10px !important;
    }

    .mhp {
        max-height: 10px !important;
    }    
}

@media only screen and (max-device-width: 799px) {
    h1 {
        margin-top: 120px !important;
    }

    .mhp {
        max-height: 1000px !important;
    }      
}


</style>

