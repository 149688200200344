<template>
    <div class="cd-section" id="teams" >
        <!--     *********    TEAM 1     *********      -->
        <div class="team-1">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">Our Awesome Team 1</h2>
                        <h4 class="description">This is the paragraph where you can write more details about your team.
                            Keep you user engaged by providing meaningful information.</h4>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-4 ml-1">
                        <card type="profile" plain>
                            <img slot="avatar" class="img img-raised" src="img/james.jpg"/>

                            <h3 class="card-title">Oliver Atticus</h3>
                            <h6 class="category text-primary">Web Developer</h6>
                            <p class="card-description">
                                If you want to know what a man's like, take a good look at how he treats his inferiors,
                                not his equals.
                            </p>
                            <div class="card-footer">
                                <a href="#pablo" class="btn btn-icon btn-primary btn-round"><i
                                        class="fab fa-facebook-square"></i></a>
                                <a href="#pablo" class="btn btn-icon btn-primary btn-round"><i
                                        class="fab fa-dribbble"></i></a>
                            </div>
                        </card>
                    </div>
                    <div class="col-md-4 mr-1">
                        <card type="profile" plain>
                            <img slot="avatar" class="img img-raised" src="img/michael.jpg"/>

                            <h3 class="card-title">Smith College</h3>
                            <h6 class="category text-primary">CEO / Co-Founder</h6>
                            <p class="card-description">
                                Two things are infinite: the universe and human stupidity; and I'm not sure about the
                                universe.
                            </p>
                            <div class="card-footer">
                                <a href="#pablo" class="btn btn-icon btn-primary btn-round"><i
                                        class="fab fa-twitter"></i></a>
                                <a href="#pablo" class="btn btn-icon btn-primary btn-round"><i
                                        class="fab fa-facebook-square"></i></a>
                                <a href="#pablo" class="btn btn-icon btn-primary btn-round"><i class="fab fa-google"></i></a>
                            </div>
                        </card>
                    </div>
                    <div class="col-md-4 ml-1 mt-4">
                        <card type="profile" plain>
                            <img slot="avatar" class="img img-raised" src="img/emily.jpg"/>

                            <h3 class="card-title">Sophia Madison</h3>
                            <h6 class="category text-primary">Entrepreneur</h6>
                            <p class="card-description">
                                Be who you are and say what you feel, because those who mind don't matter, and those who
                                matter don't mind.
                            </p>
                            <div class="card-footer">
                                <a href="#pablo" class="btn btn-icon btn-primary btn-round"><i
                                        class="fab fa-twitter"></i></a>
                                <a href="#pablo" class="btn btn-icon btn-primary btn-round"><i
                                        class="fab fa-dribbble"></i></a>
                                <a href="#pablo" class="btn btn-icon btn-primary btn-round"><i
                                        class="fab fa-linkedin"></i></a>
                            </div>
                        </card>
                    </div>
                    <div class="col-md-4 mr-1 mt-4">
                        <card type="profile" plain>
                            <img slot="avatar" class="img img-raised" src="img/olivia.jpg"/>

                            <h3 class="card-title">Emily Chloe</h3>
                            <h6 class="category text-primary">Entrepreneur</h6>
                            <p class="card-description">
                                You know you're in love when you can't fall asleep because reality is finally better
                                than your dreams.
                            </p>
                            <div class="card-footer">
                                <a href="#pablo" class="btn btn-icon btn-primary btn-round"><i class="fab fa-google"></i></a>
                                <a href="#pablo" class="btn btn-icon btn-primary btn-round"><i
                                        class="fab fa-dribbble"></i></a>
                            </div>
                        </card>
                    </div>
                </div>
            </div>
        </div>
        <!--     *********    END TEAM 1      *********      -->
        <!--     *********    TEAM 2     *********      -->
        <div class="team-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">The Executive Team 2</h2>
                        <h4 class="description">This is the paragraph where you can write more details about your team.
                            Keep you user engaged by providing meaningful information.</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <card type="profile" plain>
                            <img slot="image" class="img img-raised rounded"
                                 src="img/examples/card-profile1.jpg"/>

                            <h4 class="card-title">Sofia Scarlett</h4>
                            <h6 class="category">Account Manager</h6>
                            <div class="card-footer">
                                <a href="#pablo" class="btn btn-icon btn-twitter btn-round"><i
                                        class="fab fa-twitter"></i></a>
                                <a href="#pablo" class="btn btn-icon btn-facebook btn-round"><i
                                        class="fab fa-facebook-square"></i></a>
                                <a href="#pablo" class="btn btn-icon btn-dribbble btn-round"><i
                                        class="fab fa-dribbble"></i></a>
                            </div>

                        </card>
                    </div>
                    <div class="col-md-4">
                        <card type="profile" plain>
                            <img slot="image" class="img img-raised rounded"
                                 src="img/examples/card-profile2.jpg"/>

                            <h4 class="card-title">Lucas Jacob</h4>
                            <h6 class="category">Aerospace Engineer</h6>
                            <div class="card-footer">
                                <a href="#pablo" class="btn btn-icon btn-dribbble btn-round"><i
                                        class="fab fa-dribbble"></i></a>
                                <a href="#pablo" class="btn btn-icon btn-facebook btn-round"><i
                                        class="fab fa-facebook-square"></i></a>
                            </div>

                        </card>
                    </div>
                    <div class="col-md-4">
                        <card type="profile" plain>
                            <img slot="image" class="img img-raised rounded"
                                 src="img/examples/card-profile3.jpg"/>

                            <h4 class="card-title">Amelia Charlotte</h4>
                            <h6 class="category">Photographer</h6>
                            <div class="card-footer">
                                <a href="#pablo" class="btn btn-icon btn-linkedin btn-round"><i
                                        class="fab fa-linkedin"></i></a>
                                <a href="#pablo" class="btn btn-icon btn-twitter btn-round"><i
                                        class="fab fa-twitter"></i></a>
                                <a href="#pablo" class="btn btn-icon btn-dribbble btn-round"><i
                                        class="fab fa-dribbble"></i></a>
                            </div>

                        </card>
                    </div>
                </div>
            </div>
        </div>
        <!--     *********    END TEAM 2      *********      -->
        <!--     *********    TEAM 3     *********      -->
        <div class="team-3 section-image" style="background-image: url('img/project21.jpg')">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">The Executive Team 3</h2>
                        <h4 class="description">This is the paragraph where you can write more details about your team.
                            Keep you user engaged by providing meaningful information.</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
                        <card type="profile">
                            <div slot="raw-content" class="row">
                                <div class="col-md-5">
                                    <div class="card-image">
                                        <a href="#pablo">
                                            <img class="img" src="img/olivia.jpg"/>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="card-body">
                                        <h3 class="card-title">Ariana Hazel</h3>
                                        <h6 class="category text-primary"> Fashion Designer</h6>
                                        <p class="card-description">
                                            Happiness resides not in possessions, and not in gold, happiness dwells in
                                            the soul...
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>
                    <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
                        <card type="profile">
                            <div slot="raw-content" class="row">
                                <div class="col-md-5">
                                    <div class="card-image">
                                        <a href="#pablo">
                                            <img class="img" src="img/james.jpg"/>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="card-body">
                                        <h3 class="card-title">Ryan Samuel</h3>
                                        <h6 class="category text-primary">Financial Examiner</h6>
                                        <p class="card-description">
                                            Today you are you! That is truer than true! There is no one alive who is
                                            you-er than you!..
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>
                    <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
                        <card type="profile">
                            <div slot="raw-content" class="row">
                                <div class="col-md-5">
                                    <div class="card-image">
                                        <a href="#pablo">
                                            <img class="img" src="img/michael.jpg"/>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="card-body">
                                        <h3 class="card-title">Joshua Jackson</h3>
                                        <h6 class="category text-primary">Landscape Architect</h6>
                                        <p class="card-description">
                                            Success is not final, failure is not fatal: it is the courage to continue
                                            that counts...
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>
                    <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
                        <card type="profile">
                            <div slot="raw-content" class="row">
                                <div class="col-md-5">
                                    <div class="card-image">
                                        <a href="#pablo">
                                            <img class="img" src="img/emily.jpg"/>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="card-body">
                                        <h3 class="card-title">Nora Hazel</h3>
                                        <h6 class="category text-primary">Legal Secretary</h6>
                                        <p class="card-description">
                                            Do not dwell in the past, do not dream of the future, concentrate the
                                            mind...
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>
                </div>
            </div>
        </div>
        <!--     *********    END TEAM 3      *********      -->
        <!--     *********    TEAM 4     *********      -->
        <div class="team-4">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">The Executive Team 4</h2>
                        <h4 class="description">This is the paragraph where you can write more details about your team.
                            Keep you user engaged by providing meaningful information.</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <card type="profile" plain>
                            <div slot="raw-content" class="row">
                                <div class="col-md-5">
                                    <div class="card-image">
                                        <a href="#pablo">
                                            <img class="img img-raised rounded" src="img/olivia.jpg"/>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="card-body">
                                        <h4 class="card-title">Ella Evelyn</h4>
                                        <h6 class="category">Air Crew Member</h6>
                                        <p class="card-description">
                                            Think in the morning. Act in the noon. Eat in the evening. Sleep in the
                                            night......
                                        </p>
                                        <div class="card-footer">
                                            <a href="#pablo" class="btn btn-icon btn-neutral btn-twitter"><i
                                                    class="fab fa-twitter"></i></a>
                                            <a href="#pablo" class="btn btn-icon btn-neutral btn-facebook"><i
                                                    class="fab fa-facebook-square"></i></a>
                                            <a href="#pablo" class="btn btn-icon btn-neutral btn-google"><i
                                                    class="fab fa-google"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>
                    <div class="col-md-6">
                        <card type="profile" plain>
                            <div slot="raw-content" class="row">
                                <div class="col-md-5">
                                    <div class="card-image">
                                        <a href="#pablo">
                                            <img class="img img-raised rounded" src="img/emily.jpg"/>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="card-body">
                                        <h4 class="card-title">Mila Skylar</h4>
                                        <h6 class="category">Architect</h6>
                                        <p class="card-description">
                                            Love cures people - both the ones who give it and the ones who receive it...
                                        </p>
                                        <div class="card-footer">
                                            <a href="#pablo" class="btn btn-icon btn-neutral btn-linkedin"><i
                                                    class="fab fa-linkedin"></i></a>
                                            <a href="#pablo" class="btn btn-icon btn-neutral btn-facebook"><i
                                                    class="fab fa-facebook-square"></i></a>
                                            <a href="#pablo" class="btn btn-icon btn-neutral btn-dribbble"><i
                                                    class="fab fa-dribbble"></i></a>
                                            <a href="#pablo" class="btn btn-icon btn-neutral btn-google"><i
                                                    class="fab fa-google"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>
                    <div class="col-md-6">
                        <card type="profile" plain>
                            <div slot="raw-content" class="row">
                                <div class="col-md-5">
                                    <div class="card-image">
                                        <a href="#pablo">
                                            <img class="img img-raised rounded" src="img/james.jpg"/>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="card-body">
                                        <h4 class="card-title">Daniel Carter</h4>
                                        <h6 class="category">Aviation Inspector</h6>
                                        <p class="card-description">
                                            Keep your face always toward the sunshine - and shadows will fall behind
                                            you...
                                        </p>
                                        <div class="card-footer">
                                            <a href="#pablo" class="btn btn-icon btn-neutral btn-youtube"><i
                                                    class="fab fa-youtube"></i></a>
                                            <a href="#pablo" class="btn btn-icon btn-neutral btn-twitter"><i
                                                    class="fab fa-twitter"></i></a>
                                            <a href="#pablo" class="btn btn-icon btn-neutral btn-instagram"><i
                                                    class="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>
                    <div class="col-md-6">
                        <card type="profile" plain>
                            <div slot="raw-content" class="row">
                                <div class="col-md-5">
                                    <div class="card-image">
                                        <a href="#pablo">
                                            <img class="img img-raised rounded" src="img/michael.jpg"/>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="card-body">
                                        <h4 class="card-title">Dylan Wyatt</h4>
                                        <h6 class="category">Conservation Scientist</h6>
                                        <p class="card-description">
                                            There is only one corner of the universe you can be certain of improving,
                                            and that's your own self...
                                        </p>
                                        <div class="card-footer">
                                            <a href="#pablo" class="btn btn-icon btn-neutral btn-linkedin"><i
                                                    class="fab fa-linkedin"></i></a>
                                            <a href="#pablo" class="btn btn-icon btn-neutral btn-facebook"><i
                                                    class="fab fa-facebook-square"></i></a>
                                            <a href="#pablo" class="btn btn-icon btn-neutral btn-google"><i
                                                    class="fab fa-google"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>
                </div>
            </div>
        </div>
        <!--     *********    END TEAM 4      *********      -->
        <!--     *********    TEAM 5     *********      -->
        <div class="team-5 section-image" style="background-image: url('img/project9.jpg')">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">The Executive Team 5</h2>
                        <h4 class="description">This is the paragraph where you can write more details about your team.
                            Keep you user engaged by providing meaningful information.</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <card type="profile">
                            <img slot="avatar" class="img img-raised" src="img/olivia.jpg"/>

                            <h3 class="card-title">Natalie Paisley</h3>
                            <h6 class="category text-primary">Credit Analyst</h6>
                            <p class="card-description">
                                Very little is needed to make a happy life; it is all within yourself, in your way
                                of thinking...
                            </p>
                            <div class="card-footer">
                                <a href="#pablo" class="btn btn-icon btn-neutral btn-round"><i
                                        class="fab fa-linkedin"></i></a>
                                <a href="#pablo" class="btn btn-icon btn-neutral btn-round"><i
                                        class="fab fa-twitter"></i></a>
                                <a href="#pablo" class="btn btn-icon btn-neutral btn-round"><i
                                        class="fab fa-dribbble"></i></a>
                            </div>

                        </card>
                    </div>
                    <div class="col-md-4">
                        <card type="profile">
                            <img slot="avatar" class="img img-raised" src="img/james.jpg"/>

                            <h4 class="card-title">Anthony Jackson</h4>
                            <h6 class="category text-primary">Desktop Publisher</h6>
                            <p class="card-description">
                                Let us be grateful to people who make us happy, they are the charming gardeners who
                                make our souls blossom...
                            </p>
                            <div class="card-footer">
                                <a href="#pablo" class="btn btn-icon btn-neutral btn-round"><i
                                        class="fab fa-dribbble"></i></a>
                                <a href="#pablo" class="btn btn-icon btn-neutral btn-round"><i
                                        class="fab fa-twitter"></i></a>
                            </div>

                        </card>
                    </div>
                    <div class="col-md-4">
                        <card type="profile">
                            <img slot="avatar" class="img img-raised" src="img/emily.jpg"/>

                            <h4 class="card-title">Bella Audrey</h4>
                            <h6 class="category text-primary">Economist</h6>
                            <p class="card-description">
                                For it was not into my ear you whispered, but into my heart. It was not my lips you
                                kissed, but my soul...
                            </p>
                            <div class="card-footer">
                                <a href="#pablo" class="btn btn-icon btn-neutral btn-round"><i
                                        class="fab fa-dribbble"></i></a>
                                <a href="#pablo" class="btn btn-icon btn-neutral btn-round"><i
                                        class="fab fa-twitter"></i></a>
                                <a href="#pablo" class="btn btn-icon btn-neutral btn-round"><i
                                        class="fab fa-pinterest"></i></a>
                            </div>

                        </card>
                    </div>
                </div>
            </div>
        </div>
        <!--     *********    END TEAM 5      *********      -->
    </div>
</template>
<script>
  import { Card } from '@/components';

  export default {
    components: {
      Card
    }
  }
</script>
<style>
</style>
