import { render, staticRenderFns } from "./Testimonials.vue?vue&type=template&id=0e4a170d&scoped=true"
import script from "./Testimonials.vue?vue&type=script&lang=js"
export * from "./Testimonials.vue?vue&type=script&lang=js"
import style0 from "./Testimonials.vue?vue&type=style&index=0&id=0e4a170d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e4a170d",
  null
  
)

export default component.exports